import React from 'react'
import ProjectItem from '../ProjectItem/ProjectItem'

const ProjectList = ({ org }) => {
    return (
        <>
        {             
            org.projects && org.projects.map( project => {
                return <ProjectItem key={project.id} org={org} project={project} />
            })   
        }
        </>
  )
}

export default ProjectList
