import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'

const ProtectRoute = () => {

    const { user_id } = useSelector(state => state.auth)

    if (user_id) {
        return <Outlet />
    } else {
        return <Navigate to='/login' />
    }
}

export default ProtectRoute