import React from "react";
import RegisterInvitedUser from "../components/Invitation/RegisterInvitedUser";

const RegisterInvitedUserPage = () => {
    return(
        <>
        <RegisterInvitedUser/>
        </>
    )
}

export default RegisterInvitedUserPage;