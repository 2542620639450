// src/components/SubscriptionPlan.js
import React from 'react';
import { useState } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import { API_URL, AXIOS_CONFIG } from '../../utils'

import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';

const InitialSubscription = ({ planId, amount, description, selected, onClick  }) => {
  
    const StyledComponent = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(2),
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: selected ? '0px 4px 20px rgba(0, 0, 0, 0.2)' : 'none',
        border: selected ? '2px solid brown' : 'none',
        transition: 'box-shadow 0.3s, border 0.3s', 
    
      }));
    
    const selectInitialSubscription = async () => {
   
    };

    return (
        <StyledComponent onClick={onClick} selected={selected} >
     
            
            <CardContent>
                <Typography variant="h5" gutterBottom>{planId} </Typography>
                <Typography variant="body2" gutterBottom>{description}</Typography>
                <Typography variant="body1" gutterBottom>${amount} per month</Typography>
                
            </CardContent>
            
      
        </StyledComponent>
        
    );
};

export default InitialSubscription;