import React from "react";
import MemberDetails from "../components/AdminPanel/MemberDetails";

const MemberProfile = () => {
    return(
        <>
        <br></br>
        <h1>Member profile</h1>
         <MemberDetails/>
         <br></br>
        </>
    )
}

export default MemberProfile;