import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Grid } from '@mui/material'
import { API_URL } from '../utils'
import NFTItem from '../components/NFTItem/NFTItem'


const CarbonOffsetNFTs = () => {
    const [nfts, setNfts] = useState([]);

    useEffect(() => {
        axios.get(`${API_URL}/nfts/all/read/`)
            .then(res => {
                setNfts(res.data);
            })
            .catch((error) => {
                console.log(error.message);
            })
    }, [])


    return (
        <div className="py-5 bg page">
            <div className='container px-1 px-lg-0 px-md-0 px-sm-0' >
                <Grid container spacing={6}>
                    {
                        nfts && nfts.map(nft => {
                            return <NFTItem nft={nft} key={nft.id} />
                        })
                    }
                </Grid>
            </div>
        </div>
    )
}

export default CarbonOffsetNFTs
