import React from "react";
import { useEffect } from "react";
import { useParams } from 'react-router-dom'; 
import axios from 'axios'; 
import { API_URL, AXIOS_CONFIG } from '../../utils';
import swal from "sweetalert";

const AcceptInvitation = () =>{
    const {token, org_name}= useParams();
  
    
    const handleInvitation = async() => {
            try {
             const response = await axios.post(`${API_URL}/invitations/accept-invitation/${org_name}/`, {token}, {...AXIOS_CONFIG, withCredentials: false});
              const registrationUrl = `/register-invited/${token}`; 
               
              if(response.status == 200){
                swal({
                              title: "Success",
                              text: `Welcome on board!`,
                              icon: "success",
                              button: false,
                              timer: 1200,
                            });
                window.location.href = registrationUrl;
              }
              else if(response.status == 400 && response.data.message == "User has already been invited for this organisation"){
                swal({
                              title: "Already invited",
                              text: `This user already invited`,
                              icon: "error",
                              button: false,
                              timer: 1500,
                            });
                window.location.href = registrationUrl;
              }
    
              else{
                swal({
                              title: "Failed",
                              text: "Invitation invalid",
                              icon: "error",
                              button: false,
                              timer: 1300,
                            });

              }
            } catch (error) {
                swal({
                    title: "Invitation",
                    text: `Invitation already accepted or expired`,
                    icon: "error",
                    button: false,
                    timer: 1400,
                  });

              console.error('Error accepting invitation:', error);
              
            }
          
    }
    useEffect (() => {
       handleInvitation();
       
    }, [token]);
}

export default AcceptInvitation;