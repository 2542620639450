import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Grid, Typography, Box, TextField, Button } from "@mui/material";

import { API_URL } from "../../utils";

const ResetPassword = () => {

  const [step, setStep] = useState("email");
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const { register, control, errors, formState, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      new_password: "",
      confirm_password: "",
    },
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const [emailRes, setEmailRes] = useState(false);

  const onSubmit = (data) => {
    const object = {
      email: data.email,
    };

    setEmailRes(true);
    const body = JSON.stringify(object);
    axios
      .post(`${API_URL}/accounts/otp-send/`, body, config)
      .then((res) => {
        if (res.status === 200) {
          setEmailRes(false);
          setEmail(data.email);
          setStep("otp");
        } else if (res.status === 203) {
          setErrorMsg(res.data.message);
          swal({
            title: "Failed!",
            text: `${res.data.message}`,
            icon: "error",

            button: false,
            timer: 700,
          });
        }
      })
      .catch((error) => {
        setEmailRes(false);
        swal({
          title: `${error.response.data.message}`,
          text: `Failed!`,
          icon: "error",

          button: false,
          timer: 700,
        });
      });
  };

  const onError = (error) => console.log(error);

  const [otp, setOtp] = useState("");
  const [otp_res, set_otp_res] = useState(false);

  const otp_verify = async (e) => {
    e.preventDefault();

    try {
      set_otp_res(true);
      const { data } = await axios.post(
        `${API_URL}/accounts/otp-verify/`,
        { otp: otp, email: email },
        config
      );
      set_otp_res(false);
      setStep("update_password");
    } catch (error) {
      set_otp_res(false);
      swal({
        title: `${error.response.data.message}`,
        text: `Failed!`,
        icon: "error",

        button: false,
        timer: 700,
      });
    }
  };

  const [update_res, set_update_res] = useState(false);

  const update_password = (data) => {
    if (data.new_password === data.confirm_password) {
      const object = {
        email: email,
        password: data.new_password,
      };
      const body = JSON.stringify(object);
      set_update_res(true);
      axios
        .post(`${API_URL}/accounts/update-password/`, body, config)
        .then((res) => {
          set_update_res(false);
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: `${res.data.message}`,
              icon: "success",

              button: false,
              timer: 700,
            });
            navigate('/login')
          } else if (res.status === 203) {
            setErrorMsg(res.data.message);
            swal({
              title: "Failed!",
              text: `${res.data.message}`,
              icon: "error",

              button: false,
              timer: 700,
            });
          }
        })
        .catch((error) => {
          set_update_res(false);
          console.log(error.message);
          swal({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",

            button: false,
            timer: 700,
          });
        });
    } else {
      setErrorMsg("Passwords did not match!");
    }
  };

  return (
    <div className="py-5 page container container px-2 px-lg-0 px-md-0 px-sm-0 d-flex justify-content-center align-items-center">
      <div className="bg-white border col-12 col-lg-6 col-md-8 col-sm-10 col-xs-12 p-4">
        {step === "email" && (
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5" sx={{ mb: 3 }}>
                  Reset Password
                </Typography>

                <Box sx={{ maxWidth: "100%", mb: 2 }}>
                  <TextField
                    inputRef={register({
                      required: "Email is required.",
                      minLength: {
                        value: 6,
                        message: "Must have at least 6 characters",
                      },
                    })}
                    fullWidth
                    required
                    name="email"
                    label="Email"
                    variant="outlined"
                    //@ts-ignore
                    control={control}
                    onFocus={() => setErrorMsg("")}
                    error={Boolean(errors?.email)}
                    helperText={
                      errors?.email?.message
                        ? errors?.email?.message
                        : "Your Email Address"
                    }
                  />
                </Box>

                {
                  //@ts-ignore
                  errorMsg && (
                    <Typography variant="p" sx={{ color: "red", fontSize: 14 }}>
                      {errorMsg}
                    </Typography>
                  )
                }

                <Box sx={{ maxWidth: "100%", m: 1 }}>
                  <Button
                    className="buy-btn px-4"
                    variant="outlined"
                    type="submit"
                    disabled={emailRes}
                  >
                    {emailRes ? "loading..." : "Submit"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
        {step === "otp" && (
          <form onSubmit={otp_verify}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5" sx={{ mb: 1 }}>
                  OTP Verification
                </Typography>
                <Typography sx={{ mb: 1 }}>
                  Please enter the authentication code sent to your registered
                  email.
                </Typography>
                <Box sx={{ maxWidth: "100%", mb: 2 }}>
                  <TextField
                    fullWidth
                    required
                    name="otp"
                    label="OTP"
                    variant="outlined"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </Box>

                {
                  //@ts-ignore
                  errorMsg && (
                    <Typography variant="p" sx={{ color: "red", fontSize: 14 }}>
                      {errorMsg}
                    </Typography>
                  )
                }

                <Box sx={{ maxWidth: "100%" }}>
                  <Button
                    className="buy-btn px-3"
                    variant="outlined"
                    type="submit"
                    disabled={Boolean(otp_res)}
                  >
                    {otp_res ? "loading..." : "Submit"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
        {step === "update_password" && (
          <form onSubmit={handleSubmit(update_password, onError)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5" sx={{ mb: 3 }}>
                  Update password for{" "}
                  <strong>{localStorage.getItem("email")}</strong>
                </Typography>

                <Box sx={{ maxWidth: "100%", mb: 2 }}>
                  <TextField
                    inputRef={register({
                      required: "Password is required.",
                      minLength: {
                        value: 6,
                        message: "Must have at least 6 characters",
                      },
                    })}
                    fullWidth
                    required
                    type="password"
                    name="new_password"
                    label="New Password"
                    variant="outlined"
                    //@ts-ignore
                    control={control}
                    onFocus={() => setErrorMsg("")}
                    error={Boolean(errors?.email)}
                    helperText={
                      errors?.email?.message
                        ? errors.email?.message
                        : "Your Password"
                    }
                  />
                </Box>

                <Box sx={{ maxWidth: "100%", mb: 2 }}>
                  <TextField
                    inputRef={register({
                      required: "Confirm Password is required.",
                      minLength: {
                        value: 6,
                        message: "Must have at least 6 characters",
                      },
                    })}
                    fullWidth
                    required
                    type="password"
                    name="confirm_password"
                    label="Confirm Password"
                    variant="outlined"
                    //@ts-ignore
                    control={control}
                    onFocus={() => setErrorMsg("")}
                    error={Boolean(errors?.confirm_password)}
                    helperText={
                      errors?.confirm_password?.message
                        ? errors?.confirm_password?.message
                        : "Confirm Your Password"
                    }
                  />
                </Box>

                {
                  //@ts-ignore
                  errorMsg && (
                    <Typography variant="p" sx={{ color: "red", fontSize: 14 }}>
                      {errorMsg}
                    </Typography>
                  )
                }

                <Box sx={{ maxWidth: "100%", m: 1 }}>
                  <Button
                    className="buy-btn px-3"
                    variant="outlined"
                    type="submit"
                    disabled={
                      Boolean(Object.keys(formState.errors).length) ||
                      update_res
                    }
                  >
                    {update_res ? "Updating..." : "Update Password"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
