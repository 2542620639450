// src/components/SubscriptionPlan.js
import React from 'react';
import { useState } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import { API_URL, AXIOS_CONFIG } from '../../utils'


const SubscriptionPlan = ({ planId, amount, description, orgId }) => {
  const [isLoading, setIsLoading] = useState(false);
 
    
    const createSubscription = async () => {
        setIsLoading(true);
        const response = await axios.post(`${API_URL}/subscriptions/`,  { plan_id: planId, org_id: orgId});
        const approval_url = response.data.approval_url;
        if (response.status === 201) {
            //setPayPalPlanId(response.data.plan_id);
            if(approval_url){
                window.location.href = approval_url; 
            }
            console.log("Successfully Created Paypal Plan")
            console.log("=== APPROVE URL IS ", approval_url)
        } else {
            console.error('Failed to create PayPal plan');
        }
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" gutterBottom>{planId} Plan</Typography>
                <Typography variant="body2" gutterBottom>{description}</Typography>
                <Typography variant="body1" gutterBottom>${amount} per month</Typography>
               <Button onClick={createSubscription} variant="contained"
                  className="info-btn">{isLoading ? 'Loading...': 'Subscribe'}</Button>
                
                
            </CardContent>
        </Card>
    );
};

export default SubscriptionPlan;