import React from 'react'

const Privacy = () => {
    return (
        <div className="py-5 bg page">
            <div className='container px-3 px-lg-0 px-md-0 px-sm-0' >
                <h2 className='text-secondary pb-3'>Privacy Statement</h2>
                <h2 className='text-secondary pb-3'>Ownership</h2>
                <p className='text-secondary pb-4'>DCarbonX is a carbon credit marketplace that stores carbon credit certificates as NFTs on Algorand 
                    blockchain (hereinafter referred to as Platform) developed and managed by Nash SARL (hereinafter referred to as Nash fintechX), 
                    with address at 25 Rue Principale, Goeblange - 8358 and and email: <span className='text-primary'><u>contact@nashfintechx.com</u></span>
                </p>
                <p className='text-secondary pb-4'>The Platform allows registered organizations to list carbon credits. These carbon credits can be 
                    bought by corporates/ Individuals to lower their emissions in line with their climate strategy. The carbon credits, 
                    stored as a unique NFT, ensures complete transparency, traceability, and security of the credits, when traded in the 
                    primary and secondary market provided on the Platform.
                </p>
                <p className='text-secondary pb-4'>This Privacy Statement describes how Nash fintechX processes your information and explains the choices available to you with respect to your information.</p>
                <p className='text-secondary pb-4'>In compliance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council, 
                    of April 27, 2016 (hereinafter GDPR) we inform you that the personal data communicated will be incorporated into a database 
                    owned by Nash fintechX.
                </p>
                <h2 className='text-secondary pb-3'>Ownership</h2>
                <p className='text-secondary pb-4'>For any matter related to storage and processing of personal data, please contact at the following email address: <span className='text-primary'><u>contact@nashfintechx.com</u></span></p>
                <h2 className='text-secondary pb-3'>Personal information</h2>
                <p className='text-secondary pb-4'>The personal data refers to information that identifies our users and which is required to carry out the defined process on DCarbonX platform. 
                    This may also include information about how you use the Platform, where it is applicable for managing the Platform operations.
                </p>
                <p className='text-secondary pb-4'>This Privacy Statement is applicable to the personal data that we collect, use and process within the framework of our relationship with you as a client, potential client, or user of our Platform.</p>
                <h2 className='text-black pb-3'>Some general recommendations to ensure data safety for users as in case of any online activities:</h2>
                <div className='px-2 px-sm-4 pb-4'>
                    <ul>
                        <li className='text-secondary'>Don’t share registration data, username, and passwords.</li>
                        <li className='text-secondary'>Don’t share your login details to access the Platform or your device post login for the use of the Platform and its services.</li>
                        <li className='text-secondary'>Act with caution and protect yourself against Internet fraud and "Phishing".</li>
                    </ul>
                </div>
                <h2 className='text-secondary pb-3'>Privacy commitment</h2>
                <span className='text-secondary pb-3 h4 d-block'>Nash fintechX is committed to respecting your privacy and protecting your personal data.</span>
                <div className='px-2 px-sm-4 pb-4'>
                    <ul>
                        <li className='text-secondary'>Nash fintechX is committed to ensuring full transparency regarding the data it collects and how it is used.</li>
                        <li className='text-secondary'>Nash fintechX will use the data provided for the purposes described in the Privacy Statement, among which is to provide the services offered by the Platform.</li>
                        <li className='text-secondary'>This data could be used to carry out work aimed at getting to know the client or user better.</li>
                        <li className='text-secondary'>Nash fintechX will adopt the necessary measures to protect and keep safe all the information provided by its users and clients.</li>
                    </ul>
                </div>
                <span className='text-secondary pb-3 h4 d-block'>The personal data collected will be used for the following purposes:</span>
                <div className='px-2 px-sm-4 pb-4'>
                    <ul>
                        <li className='text-secondary'>To process all issues related to the provision of carbon credit services through the acquisition, transfer and NFT issuance of carbon credits on the Platform.</li>
                        <li className='text-secondary'>For the correct management of electronic transactions derived from the carbon credit services and NFT creation. </li>
                        <li className='text-secondary'>For communication between registered users with carbon credit needs and the developers of sustainability projects exposed on the Platform. </li>
                        <li className='text-secondary'>To send updates and service or operational communications about the contracted service. </li>

                        <li className='text-secondary'>To provide any additional services using the Platform to fulfil specific request of the developer or user.</li>
                        <li className='text-secondary'>To carry out analytical tasks and market studies. </li>
                        <li className='text-secondary'>To carry out direct marketing activities and keep the user informed about the new products and services that Nash fintechX may offer, adjusting to the user's preference criteria. </li>

                        <li className='text-secondary'>To improve our website, products, and services. </li>
                        <li className='text-secondary'>To be able to manage the transactions, operations, contracting of products and services that may take place. </li>
                        <li className='text-secondary'>To carry out the procedures derived from queries, complaints, claims or suggestions that Nash fintechX receives.</li>
                    </ul>
                </div>

                <h4 className='text-secondary pb-3 font-weight-normal d-block'>The personal data collected will be used for the following purposes:</h4>
                <h2 className='text-secondary pb-3'>COMMERCIAL CONTACT</h2>
                <p className='text-secondary pb-4'><strong>Data collected:</strong> Name, telephone, email and query.</p>

                <p className='text-secondary pb-4'><strong>Purpose:</strong> Respond to requests for information that the interested party has made by any means about Nash fintechX products and services, and establish and maintain business relationships.</p>
                <p className='text-secondary pb-4'><strong>Legitimation:</strong>Based on the provision of consent, Nash fintechX is entitled to process the data of the interested party provided through the contact request, and in accordance with the legitimate interest for the development and maintenance of commercial relations.</p>
                <p className='text-secondary pb-4'><strong>Conservation periods:</strong> The data will be kept until the moment in which the interested party requests the cancellation or deletion of their data and once deleted, the data will remain available to the public administration, judges and courts, for the legal deadlines established for attention and defense. of the possible responsibilities arising from the treatment.</p>

                <h2 className='text-secondary pb-3'>SERVICES</h2>

                <h4 className='text-secondary pb-3 font-weight-normal d-block'>Data provided for the provision of DCarbonX Platform services. The services will be identified at the time of data collection and registration.</h4>

                <p className='text-secondary pb-4'><strong>Data collected:</strong> Name, Surname, ID, contact address, address, email, contact telephone number, organizational details, sustainability project details (location, project description, credit pricing, verification registry, certification) and other data necessary according to the requested service, and which will be exclusively necessary for registration in the DCarbonX platform.</p>
                <p className='text-secondary pb-4'><strong>Purpose:</strong> The registration of interested parties for the provision of services and to establish and maintain commercial relationships.</p>
                <p className='text-secondary pb-4'><strong>Legitimation:</strong> The existing legal or contractual relationship necessary for the provision of the service and the legitimate interest for the development of commercial relationships</p>
                <p className='text-secondary pb-4'><strong>Conservation periods:</strong> The data will be kept for the time necessary for the purpose of the treatment or once the commercial relationship has ended, if applicable, and once it has ended, they will be kept available to the public administration, judges, and courts, for which legal deadlines established for the attention and defense of possible responsibilities arising from the treatment.</p>

                <h2 className='text-secondary pb-3'>EXERCISE OF RIGHTS</h2>

                <p className='text-secondary pb-4'>The user has recognized their rights to access, rectification, deletion, limitation, opposition, and portability of their data. In the same way, you are protected by the right to withdraw your consent for the treatment by Nash fintechX</p>

                <p className='text-secondary pb-4'>At any time, the Interested Party may exercise their rights by sending a communication, attaching a document proving their identity and expressly indicating the right they wish to exercise, to Nash fintechX at the address indicated above. </p>

                <h2 className='text-secondary pb-3'>TECHNICAL AND ORGANIZATIONAL SECURITY MEASURES</h2>

                <p className='text-secondary pb-4'>Nash fintechX has adopted the necessary technical and organizational measures to guarantee the security and integrity of the data, as well as to avoid its alteration, loss, treatment or unauthorized access.</p>

                <h2 className='text-secondary pb-3'>DATA PROVIDED</h2>

                <p className='text-secondary pb-4'>The data collected by the different channels enabled are those strictly necessary to meet your request, which the Interested Party communicates voluntarily. The refusal to provide the data classified as mandatory will mean the non-provision of the service requested. Likewise, data may be provided voluntarily so that the services can be optimally provided. Likewise, in those cases in which data from third parties is provided, the Interested Party undertakes to inform them of the content of this privacy statement.</p>

                <p className='text-secondary pb-4'>The Interested Party undertakes to notify Nash fintechX of any modification and rectification of their personal data as soon as possible so that the information contained in the Nash fintechX database is always updated.</p>

                <p className='text-secondary pb-4'>Based on the legitimate interest of Nash fintechX, the personal data of the Interested Party may be used for the establishment or maintenance of business relationships by any means, including electronically, on information of interest about Nash fintechX products and services.</p>
            </div>
        </div>
    )
}

export default Privacy