import Cookies from 'js-cookie';

let PRODUCTION = 'production'
let LOCAL = 'dev'

let MODE = LOCAL

let API_URL = '', APP_URL = ''

API_URL = 'https://backend.dcarbx.com'
const LOCAL_URL = 'http://127.0.0.1:8000'
const PRODUCTION_URL = 'https://backend.dcarbx.com'

const APP_URL_LOCAL = 'http://localhost:3000/'
const APP_URL_PRODUCTION = 'http://localhost:3000/'


if (MODE === PRODUCTION) {
    API_URL = PRODUCTION_URL
    APP_URL = APP_URL_PRODUCTION
} else {
    API_URL = LOCAL_URL
    APP_URL = APP_URL_LOCAL
}
API_URL = 'https://backend.dcarbx.com'
export { APP_URL, API_URL }


export const AXIOS_CONFIG = {
    headers: { 'Content-Type': 'application/json', }
}

// function getCookie(name) {
//     let cookieValue = null;
//     if (document.cookie && document.cookie !== '') {
//         const cookies = document.cookie.split(';');
//         for (let i = 0; i < cookies.length; i++) {
//             const cookie = cookies[i].trim();
//             if (cookie.substring(0, name.length + 1) === (name + '=')) {
//                 cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//                 break;
//             }
//         }
//     }
//     return cookieValue;
// }
export function getAxiosConfig({ jsonHeader, credentials } = {}) {
    const config = {};

    if (jsonHeader) {
        config.headers = { 'Content-Type': 'application/json', };
    }

    if (credentials) {
        const csrfToken =  Cookies.get('csrftoken');
        const accessToken = localStorage.getItem('access');

        config.headers = {
            ...config.headers,
            'X-CSRFToken': csrfToken,
            'Authorization': `Token ${accessToken}`
        };
        config.withCredentials = true;
    }
    return config;
}

const csrfToken = Cookies.get('csrftoken');
const accessToken = localStorage.getItem('access');

export const AXIOS_CONFIG_PROTECTED = {
    ...AXIOS_CONFIG,
        'Authorization': `Bearer ${accessToken}`,
    withCredentials: true
};

export const AXIOS_CONFIG_GET_REQUESTS_PROTECTED = {
    headers: {
        'X-CSRFToken': csrfToken,
        'Authorization': `Bearer ${accessToken}`
      },
    withCredentials: true

}