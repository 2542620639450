// Automatically generated with Reach 0.1.13 (88e48902)
/* eslint-disable */
export const _version = '0.1.13';
export const _versionHash = '0.1.13 (88e48902)';
export const _backendVersion = 27;

export function getExports(s) {
  const stdlib = s.reachStdlib;
  return {
    };
  };
export function _getEvents(s) {
  const stdlib = s.reachStdlib;
  return {
    };
  };
export function _getViews(s, viewlib) {
  const stdlib = s.reachStdlib;
  const ctc0 = stdlib.T_Address;
  const ctc1 = stdlib.T_UInt;
  const ctc2 = stdlib.T_Token;
  const ctc3 = stdlib.T_Bytes(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '32'));
  const ctc4 = stdlib.T_Bytes(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '8'));
  const ctc5 = stdlib.T_Bool;
  const ctc6 = stdlib.T_Tuple([ctc1, ctc1, ctc5]);
  const ctc7 = stdlib.T_Array(ctc6, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'));
  const ctc8 = stdlib.T_Struct([['name', ctc3], ['symbol', ctc4], ['decimals', ctc1], ['totalSupply', ctc1], ['price', ctc1], ['token', ctc2]]);
  const ctc9 = stdlib.T_Tuple([ctc1, ctc1]);
  
  const _balance = async (i, svs, args) => {
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v804] = svs;
      stdlib.assert(false, 'illegal view')
      }
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838] = svs;
      return (await ((async () => {
        
        
        return v828;}))(...args));
      }
    
    stdlib.assert(false, 'illegal view')
    };
  const _halted = async (i, svs, args) => {
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v804] = svs;
      stdlib.assert(false, 'illegal view')
      }
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838] = svs;
      return (await ((async () => {
        
        
        return v826;}))(...args));
      }
    
    stdlib.assert(false, 'illegal view')
    };
  const _meta = async (i, svs, args) => {
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v804] = svs;
      stdlib.assert(false, 'illegal view')
      }
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838] = svs;
      return (await ((async () => {
        
        const v822 = {
          decimals: v797,
          name: v798,
          price: v796,
          symbol: v799,
          token: v795,
          totalSupply: v794
          };
        
        return v822;}))(...args));
      }
    
    stdlib.assert(false, 'illegal view')
    };
  const _owner = async (i, svs, args) => {
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v804] = svs;
      stdlib.assert(false, 'illegal view')
      }
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838] = svs;
      return (await ((async () => {
        
        
        return v793;}))(...args));
      }
    
    stdlib.assert(false, 'illegal view')
    };
  const _token = async (i, svs, args) => {
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v804] = svs;
      stdlib.assert(false, 'illegal view')
      }
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838] = svs;
      return (await ((async () => {
        
        
        return v795;}))(...args));
      }
    
    stdlib.assert(false, 'illegal view')
    };
  const _tracker = async (i, svs, args) => {
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v804] = svs;
      stdlib.assert(false, 'illegal view')
      }
    if (stdlib.eq(i, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4'))) {
      const [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838] = svs;
      return (await ((async () => {
        
        const v844 = v837[stdlib.checkedBigNumberify('./index.rsh:121:35:application', stdlib.UInt_max, '0')];
        const v845 = v844[stdlib.checkedBigNumberify('./index.rsh:121:35:application', stdlib.UInt_max, '0')];
        const v846 = [v845, v828];
        
        return v846;}))(...args));
      }
    
    stdlib.assert(false, 'illegal view')
    };
  return {
    infos: {
      balance: {
        decode: _balance,
        dom: [],
        rng: ctc1
        },
      halted: {
        decode: _halted,
        dom: [],
        rng: ctc5
        },
      meta: {
        decode: _meta,
        dom: [],
        rng: ctc8
        },
      owner: {
        decode: _owner,
        dom: [],
        rng: ctc0
        },
      token: {
        decode: _token,
        dom: [],
        rng: ctc2
        },
      tracker: {
        decode: _tracker,
        dom: [],
        rng: ctc9
        }
      },
    views: {
      1: [ctc0, ctc1, ctc2, ctc1, ctc1, ctc3, ctc4, ctc7],
      4: [ctc0, ctc1, ctc2, ctc1, ctc1, ctc3, ctc4, ctc5, ctc1, ctc1, ctc7, ctc1]
      }
    };
  
  };
export function _getMaps(s) {
  const stdlib = s.reachStdlib;
  const ctc0 = stdlib.T_Tuple([]);
  return {
    mapDataTy: ctc0
    };
  };
export async function Deployer(ctcTop, interact) {
  if (typeof(ctcTop) !== 'object' || ctcTop._initialize === undefined) {
    return Promise.reject(new Error(`The backend for Deployer expects to receive a contract as its first argument.`));}
  if (typeof(interact) !== 'object') {
    return Promise.reject(new Error(`The backend for Deployer expects to receive an interact object as its second argument.`));}
  const ctc = ctcTop._initialize();
  const stdlib = ctc.stdlib;
  const ctc0 = stdlib.T_UInt;
  const ctc1 = stdlib.T_Bytes(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '32'));
  const ctc2 = stdlib.T_Bytes(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '8'));
  const ctc3 = stdlib.T_Object({
    decimals: ctc0,
    name: ctc1,
    price: ctc0,
    symbol: ctc2,
    totalSupply: ctc0
    });
  const ctc4 = stdlib.T_Token;
  const ctc5 = stdlib.T_Null;
  const ctc6 = stdlib.T_Tuple([ctc0]);
  const ctc7 = stdlib.T_Tuple([]);
  const ctc8 = stdlib.T_Data({
    PrimaryBuy0_159: ctc6,
    halt0_159: ctc7,
    stopContract0_159: ctc7
    });
  const ctc9 = stdlib.T_Bool;
  const ctc10 = stdlib.T_Address;
  const ctc11 = stdlib.T_Tuple([ctc0, ctc0, ctc9]);
  const ctc12 = stdlib.T_Array(ctc11, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'));
  
  
  const v770 = [stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '0'), stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '0'), false];
  const v771 = [v770];
  const v775 = stdlib.protect(ctc3, interact.meta, 'for Deployer\'s interact field meta');
  const v776 = v775.decimals;
  const v777 = v775.name;
  const v778 = v775.price;
  const v779 = v775.symbol;
  const v780 = v775.totalSupply;
  const v781 = stdlib.protect(ctc4, interact.token, 'for Deployer\'s interact field token');
  
  const v789 = stdlib.gt(v780, stdlib.checkedBigNumberify('./index.rsh:79:25:decimal', stdlib.UInt_max, '0'));
  stdlib.assert(v789, {
    at: 'reach standard library:57:5:application',
    fs: ['at ./index.rsh:79:10:application call to "check" (defined at: reach standard library:49:32:function exp)'],
    msg: 'Supply must be greater than 0',
    who: 'Deployer'
    });
  const v791 = stdlib.lt(v778, stdlib.UInt_max);
  stdlib.assert(v791, {
    at: 'reach standard library:57:5:application',
    fs: ['at ./index.rsh:80:10:application call to "check" (defined at: reach standard library:49:32:function exp)'],
    msg: 'Price Too Large',
    who: 'Deployer'
    });
  
  const txn1 = await (ctc.sendrecv({
    args: [v780, v781, v778, v776, v777, v779],
    evt_cnt: 6,
    funcNum: 0,
    lct: stdlib.checkedBigNumberify('./index.rsh:71:5:dot', stdlib.UInt_max, '0'),
    onlyIf: true,
    out_tys: [ctc0, ctc4, ctc0, ctc0, ctc1, ctc2],
    pay: [stdlib.checkedBigNumberify('./index.rsh:71:5:decimal', stdlib.UInt_max, '0'), []],
    sim_p: (async (txn1) => {
      const sim_r = { txns: [], mapRefs: [], maps: [] };
      let sim_txn_ctr = stdlib.UInt_max;
      const getSimTokCtr = () => { sim_txn_ctr = sim_txn_ctr.sub(1); return sim_txn_ctr; };
      
      
      const {data: [v794, v795, v796, v797, v798, v799], secs: v801, time: v800, didSend: v107, from: v793 } = txn1;
      
      const v802 = v771[stdlib.checkedBigNumberify('./index.rsh:71:5:dot', stdlib.UInt_max, '0')];
      const v803 = stdlib.Array_set(v802, '0', stdlib.checkedBigNumberify('./index.rsh:71:5:dot', stdlib.UInt_max, '0'));
      const v804 = stdlib.Array_set(v771, stdlib.checkedBigNumberify('./index.rsh:71:5:dot', stdlib.UInt_max, '0'), v803);
      sim_r.txns.push({
        amt: stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '0'),
        kind: 'init',
        tok: v795
        });
      ;
      const v810 = await ctc.getContractInfo();
      sim_r.isHalt = false;
      
      return sim_r;
      }),
    soloSend: true,
    timeoutAt: undefined /* mto */,
    tys: [ctc0, ctc4, ctc0, ctc0, ctc1, ctc2],
    waitIfNotPresent: false
    }));
  const {data: [v794, v795, v796, v797, v798, v799], secs: v801, time: v800, didSend: v107, from: v793 } = txn1;
  const v802 = v771[stdlib.checkedBigNumberify('./index.rsh:71:5:dot', stdlib.UInt_max, '0')];
  const v803 = stdlib.Array_set(v802, '0', stdlib.checkedBigNumberify('./index.rsh:71:5:dot', stdlib.UInt_max, '0'));
  const v804 = stdlib.Array_set(v771, stdlib.checkedBigNumberify('./index.rsh:71:5:dot', stdlib.UInt_max, '0'), v803);
  const v806 = stdlib.gt(v794, stdlib.checkedBigNumberify('./index.rsh:79:25:decimal', stdlib.UInt_max, '0'));
  stdlib.assert(v806, {
    at: 'reach standard library:57:5:application',
    fs: ['at ./index.rsh:79:10:application call to "check" (defined at: reach standard library:49:32:function exp)'],
    msg: 'Supply must be greater than 0',
    who: 'Deployer'
    });
  const v808 = stdlib.lt(v796, stdlib.UInt_max);
  stdlib.assert(v808, {
    at: 'reach standard library:57:5:application',
    fs: ['at ./index.rsh:80:10:application call to "check" (defined at: reach standard library:49:32:function exp)'],
    msg: 'Price Too Large',
    who: 'Deployer'
    });
  ;
  ;
  const v810 = await ctc.getContractInfo();
  const txn2 = await (ctc.sendrecv({
    args: [v793, v794, v795, v796, v797, v798, v799, v804],
    evt_cnt: 0,
    funcNum: 1,
    lct: v800,
    onlyIf: true,
    out_tys: [],
    pay: [stdlib.checkedBigNumberify('./index.rsh:88:5:dot', stdlib.UInt_max, '0'), [[v794, v795]]],
    sim_p: (async (txn2) => {
      const sim_r = { txns: [], mapRefs: [], maps: [] };
      let sim_txn_ctr = stdlib.UInt_max;
      const getSimTokCtr = () => { sim_txn_ctr = sim_txn_ctr.sub(1); return sim_txn_ctr; };
      
      
      const {data: [], secs: v813, time: v812, didSend: v115, from: v811 } = txn2;
      
      ;
      const v814 = v804[stdlib.checkedBigNumberify('./index.rsh:88:5:dot', stdlib.UInt_max, '0')];
      const v815 = v814[stdlib.checkedBigNumberify('./index.rsh:88:5:dot', stdlib.UInt_max, '0')];
      const v816 = stdlib.add(v815, v794);
      const v818 = stdlib.Array_set(v814, '0', v816);
      const v819 = stdlib.Array_set(v804, stdlib.checkedBigNumberify('./index.rsh:88:5:dot', stdlib.UInt_max, '0'), v818);
      sim_r.txns.push({
        amt: v794,
        kind: 'to',
        tok: v795
        });
      
      const v823 = v819[stdlib.checkedBigNumberify('./index.rsh:109:30:application', stdlib.UInt_max, '0')];
      const v824 = v823[stdlib.checkedBigNumberify('./index.rsh:109:30:application', stdlib.UInt_max, '0')];
      const v825 = true;
      const v826 = false;
      const v828 = v824;
      const v830 = stdlib.checkedBigNumberify('./index.rsh:109:42:decimal', stdlib.UInt_max, '0');
      const v831 = v812;
      const v837 = v819;
      const v838 = stdlib.checkedBigNumberify('./index.rsh:65:9:after expr stmt semicolon', stdlib.UInt_max, '0');
      
      if (await (async () => {
        
        return v825;})()) {
        sim_r.isHalt = false;
        }
      else {
        const v1189 = v837[stdlib.checkedBigNumberify('./index.rsh:190:21:application', stdlib.UInt_max, '0')];
        const v1190 = v1189[stdlib.checkedBigNumberify('./index.rsh:190:21:application', stdlib.UInt_max, '0')];
        sim_r.txns.push({
          kind: 'from',
          to: v793,
          tok: v795
          });
        sim_r.txns.push({
          kind: 'from',
          to: v793,
          tok: undefined /* Nothing */
          });
        sim_r.txns.push({
          kind: 'halt',
          tok: v795
          })
        sim_r.txns.push({
          kind: 'halt',
          tok: undefined /* Nothing */
          })
        sim_r.isHalt = true;
        }
      return sim_r;
      }),
    soloSend: true,
    timeoutAt: undefined /* mto */,
    tys: [ctc10, ctc0, ctc4, ctc0, ctc0, ctc1, ctc2, ctc12],
    waitIfNotPresent: false
    }));
  const {data: [], secs: v813, time: v812, didSend: v115, from: v811 } = txn2;
  ;
  const v814 = v804[stdlib.checkedBigNumberify('./index.rsh:88:5:dot', stdlib.UInt_max, '0')];
  const v815 = v814[stdlib.checkedBigNumberify('./index.rsh:88:5:dot', stdlib.UInt_max, '0')];
  const v816 = stdlib.add(v815, v794);
  const v818 = stdlib.Array_set(v814, '0', v816);
  const v819 = stdlib.Array_set(v804, stdlib.checkedBigNumberify('./index.rsh:88:5:dot', stdlib.UInt_max, '0'), v818);
  ;
  const v820 = stdlib.addressEq(v793, v811);
  stdlib.assert(v820, {
    at: './index.rsh:88:5:dot',
    fs: [],
    msg: 'sender correct',
    who: 'Deployer'
    });
  stdlib.protect(ctc5, await interact.launched(v810), {
    at: './index.rsh:89:22:application',
    fs: ['at ./index.rsh:89:22:application call to [unknown function] (defined at: ./index.rsh:89:22:function exp)', 'at ./index.rsh:89:22:application call to "liftedInteract" (defined at: ./index.rsh:89:22:application)'],
    msg: 'launched',
    who: 'Deployer'
    });
  
  const v823 = v819[stdlib.checkedBigNumberify('./index.rsh:109:30:application', stdlib.UInt_max, '0')];
  const v824 = v823[stdlib.checkedBigNumberify('./index.rsh:109:30:application', stdlib.UInt_max, '0')];
  let v825 = true;
  let v826 = false;
  let v828 = v824;
  let v830 = stdlib.checkedBigNumberify('./index.rsh:109:42:decimal', stdlib.UInt_max, '0');
  let v831 = v812;
  let v837 = v819;
  let v838 = stdlib.checkedBigNumberify('./index.rsh:65:9:after expr stmt semicolon', stdlib.UInt_max, '0');
  
  let txn3 = txn2;
  while (await (async () => {
    
    return v825;})()) {
    const txn4 = await (ctc.recv({
      didSend: false,
      evt_cnt: 1,
      funcNum: 3,
      out_tys: [ctc8],
      timeoutAt: undefined /* mto */,
      waitIfNotPresent: false
      }));
    const {data: [v921], secs: v923, time: v922, didSend: v616, from: v920 } = txn4;
    switch (v921[0]) {
      case 'PrimaryBuy0_159': {
        const v924 = v921[1];
        undefined /* setApiDetails */;
        const v929 = v924[stdlib.checkedBigNumberify('./index.rsh:126:9:spread', stdlib.UInt_max, '0')];
        const v930 = stdlib.safeMul(v796, v929);
        const v961 = stdlib.add(v838, v930);
        ;
        const v962 = v837[stdlib.checkedBigNumberify('./index.rsh:109:21:dot', stdlib.UInt_max, '0')];
        const v963 = v962[stdlib.checkedBigNumberify('./index.rsh:109:21:dot', stdlib.UInt_max, '0')];
        ;
        const v972 = stdlib.le(v929, v963);
        stdlib.assert(v972, {
          at: 'reach standard library:57:5:application',
          fs: ['at ./index.rsh:136:14:application call to "check" (defined at: reach standard library:49:32:function exp)', 'at ./index.rsh:135:27:application call to [unknown function] (defined at: ./index.rsh:135:27:function exp)'],
          msg: null,
          who: 'Deployer'
          });
        const v979 = stdlib.sub(v963, v929);
        const v981 = stdlib.Array_set(v962, '0', v979);
        const v982 = stdlib.Array_set(v837, stdlib.checkedBigNumberify('./index.rsh:137:43:application', stdlib.UInt_max, '0'), v981);
        ;
        await txn4.getOutput('PrimaryBuy', 'v929', ctc0, v929);
        const v989 = stdlib.safeSub(v828, v929);
        const v990 = stdlib.safeAdd(v830, v929);
        const cv825 = true;
        const cv826 = v826;
        const cv828 = v989;
        const cv830 = v990;
        const cv831 = v922;
        const cv837 = v982;
        const cv838 = v961;
        
        v825 = cv825;
        v826 = cv826;
        v828 = cv828;
        v830 = cv830;
        v831 = cv831;
        v837 = cv837;
        v838 = cv838;
        
        txn3 = txn4;
        continue;
        break;
        }
      case 'halt0_159': {
        const v1011 = v921[1];
        undefined /* setApiDetails */;
        const v1025 = stdlib.addressEq(v920, v793);
        stdlib.assert(v1025, {
          at: 'reach standard library:57:5:application',
          fs: ['at ./index.rsh:150:12:application call to "check" (defined at: reach standard library:49:32:function exp)', 'at ./index.rsh:149:22:application call to [unknown function] (defined at: ./index.rsh:149:22:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:149:22:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:109:21:function exp)', 'at ./index.rsh:125:14:application call to [unknown function] (defined at: ./index.rsh:125:14:function exp)'],
          msg: 'You are not Owner',
          who: 'Deployer'
          });
        ;
        ;
        const v1082 = null;
        await txn4.getOutput('halt', 'v1082', ctc5, v1082);
        const v1088 = v826 ? false : true;
        const cv825 = true;
        const cv826 = v1088;
        const cv828 = v828;
        const cv830 = v830;
        const cv831 = v922;
        const cv837 = v837;
        const cv838 = v838;
        
        v825 = cv825;
        v826 = cv826;
        v828 = cv828;
        v830 = cv830;
        v831 = cv831;
        v837 = cv837;
        v838 = cv838;
        
        txn3 = txn4;
        continue;
        break;
        }
      case 'stopContract0_159': {
        const v1098 = v921[1];
        undefined /* setApiDetails */;
        ;
        ;
        const v1178 = true;
        await txn4.getOutput('stopContract', 'v1178', ctc9, v1178);
        const cv825 = false;
        const cv826 = v826;
        const cv828 = v828;
        const cv830 = v830;
        const cv831 = v922;
        const cv837 = v837;
        const cv838 = v838;
        
        v825 = cv825;
        v826 = cv826;
        v828 = cv828;
        v830 = cv830;
        v831 = cv831;
        v837 = cv837;
        v838 = cv838;
        
        txn3 = txn4;
        continue;
        break;
        }
      }
    
    }
  const v1189 = v837[stdlib.checkedBigNumberify('./index.rsh:190:21:application', stdlib.UInt_max, '0')];
  const v1190 = v1189[stdlib.checkedBigNumberify('./index.rsh:190:21:application', stdlib.UInt_max, '0')];
  ;
  ;
  return;
  
  
  
  
  };
export async function _PrimaryBuy4(ctcTop, interact) {
  if (typeof(ctcTop) !== 'object' || ctcTop._initialize === undefined) {
    return Promise.reject(new Error(`The backend for _PrimaryBuy4 expects to receive a contract as its first argument.`));}
  if (typeof(interact) !== 'object') {
    return Promise.reject(new Error(`The backend for _PrimaryBuy4 expects to receive an interact object as its second argument.`));}
  const ctc = ctcTop._initialize();
  const stdlib = ctc.stdlib;
  const ctc0 = stdlib.T_Address;
  const ctc1 = stdlib.T_UInt;
  const ctc2 = stdlib.T_Token;
  const ctc3 = stdlib.T_Bytes(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '32'));
  const ctc4 = stdlib.T_Bytes(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '8'));
  const ctc5 = stdlib.T_Bool;
  const ctc6 = stdlib.T_Tuple([ctc1, ctc1, ctc5]);
  const ctc7 = stdlib.T_Array(ctc6, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'));
  const ctc8 = stdlib.T_Tuple([ctc1]);
  const ctc9 = stdlib.T_Tuple([]);
  const ctc10 = stdlib.T_Data({
    PrimaryBuy0_159: ctc8,
    halt0_159: ctc9,
    stopContract0_159: ctc9
    });
  const ctc11 = stdlib.T_Null;
  
  
  const [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838] = await ctc.getState(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4'), [ctc0, ctc1, ctc2, ctc1, ctc1, ctc3, ctc4, ctc5, ctc1, ctc1, ctc7, ctc1]);
  const v850 = stdlib.protect(ctc8, await interact.in(), {
    at: './index.rsh:1:23:application',
    fs: ['at ./index.rsh:129:24:application call to [unknown function] (defined at: ./index.rsh:129:24:function exp)', 'at ./index.rsh:109:21:application call to "runPrimaryBuy0_159" (defined at: ./index.rsh:126:9:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:109:21:function exp)'],
    msg: 'in',
    who: 'PrimaryBuy'
    });
  const v851 = v850[stdlib.checkedBigNumberify('./index.rsh:1:23:application', stdlib.UInt_max, '0')];
  const v854 = v837[stdlib.checkedBigNumberify('./index.rsh:130:41:application', stdlib.UInt_max, '0')];
  const v855 = v854[stdlib.checkedBigNumberify('./index.rsh:130:41:application', stdlib.UInt_max, '0')];
  const v856 = stdlib.le(v851, v855);
  stdlib.assert(v856, {
    at: './index.rsh:130:15:application',
    fs: ['at ./index.rsh:129:24:application call to [unknown function] (defined at: ./index.rsh:129:24:function exp)', 'at ./index.rsh:129:24:application call to [unknown function] (defined at: ./index.rsh:129:24:function exp)', 'at ./index.rsh:109:21:application call to "runPrimaryBuy0_159" (defined at: ./index.rsh:126:9:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:109:21:function exp)'],
    msg: 'balance is less',
    who: 'PrimaryBuy'
    });
  const v857 = stdlib.gt(v851, stdlib.checkedBigNumberify('./index.rsh:131:32:decimal', stdlib.UInt_max, '0'));
  stdlib.assert(v857, {
    at: 'reach standard library:57:5:application',
    fs: ['at ./index.rsh:131:14:application call to "check" (defined at: reach standard library:49:32:function exp)', 'at ./index.rsh:129:24:application call to [unknown function] (defined at: ./index.rsh:129:24:function exp)', 'at ./index.rsh:129:24:application call to [unknown function] (defined at: ./index.rsh:129:24:function exp)', 'at ./index.rsh:109:21:application call to "runPrimaryBuy0_159" (defined at: ./index.rsh:126:9:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:109:21:function exp)'],
    msg: 'can\'t be less than 0',
    who: 'PrimaryBuy'
    });
  const v859 = v826 ? false : true;
  stdlib.assert(v859, {
    at: 'reach standard library:57:5:application',
    fs: ['at ./index.rsh:132:14:application call to "check" (defined at: reach standard library:49:32:function exp)', 'at ./index.rsh:129:24:application call to [unknown function] (defined at: ./index.rsh:129:24:function exp)', 'at ./index.rsh:129:24:application call to [unknown function] (defined at: ./index.rsh:129:24:function exp)', 'at ./index.rsh:109:21:application call to "runPrimaryBuy0_159" (defined at: ./index.rsh:126:9:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:109:21:function exp)'],
    msg: 'Code has been halted',
    who: 'PrimaryBuy'
    });
  const v864 = ['PrimaryBuy0_159', v850];
  
  const v888 = stdlib.safeMul(v796, v851);
  
  const txn1 = await (ctc.sendrecv({
    args: [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838, v864],
    evt_cnt: 1,
    funcNum: 3,
    lct: stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '0'),
    onlyIf: true,
    out_tys: [ctc10],
    pay: [v888, [[stdlib.checkedBigNumberify('./index.rsh:134:53:decimal', stdlib.UInt_max, '0'), v795]]],
    sim_p: (async (txn1) => {
      const sim_r = { txns: [], mapRefs: [], maps: [] };
      let sim_txn_ctr = stdlib.UInt_max;
      const getSimTokCtr = () => { sim_txn_ctr = sim_txn_ctr.sub(1); return sim_txn_ctr; };
      
      
      const {data: [v921], secs: v923, time: v922, didSend: v616, from: v920 } = txn1;
      
      switch (v921[0]) {
        case 'PrimaryBuy0_159': {
          const v924 = v921[1];
          sim_r.txns.push({
            kind: 'api',
            who: "PrimaryBuy"
            });
          const v929 = v924[stdlib.checkedBigNumberify('./index.rsh:126:9:spread', stdlib.UInt_max, '0')];
          const v930 = stdlib.safeMul(v796, v929);
          const v961 = stdlib.add(v838, v930);
          sim_r.txns.push({
            amt: v930,
            kind: 'to',
            tok: undefined /* Nothing */
            });
          const v962 = v837[stdlib.checkedBigNumberify('./index.rsh:109:21:dot', stdlib.UInt_max, '0')];
          const v963 = v962[stdlib.checkedBigNumberify('./index.rsh:109:21:dot', stdlib.UInt_max, '0')];
          ;
          const v979 = stdlib.sub(v963, v929);
          const v981 = stdlib.Array_set(v962, '0', v979);
          const v982 = stdlib.Array_set(v837, stdlib.checkedBigNumberify('./index.rsh:137:43:application', stdlib.UInt_max, '0'), v981);
          sim_r.txns.push({
            kind: 'from',
            to: v920,
            tok: v795
            });
          const v983 = await txn1.getOutput('PrimaryBuy', 'v929', ctc1, v929);
          
          const v989 = stdlib.safeSub(v828, v929);
          const v990 = stdlib.safeAdd(v830, v929);
          const v1955 = v826;
          const v1956 = v989;
          const v1957 = v990;
          const v1959 = v982;
          const v1960 = v961;
          sim_r.isHalt = false;
          
          break;
          }
        case 'halt0_159': {
          const v1011 = v921[1];
          
          break;
          }
        case 'stopContract0_159': {
          const v1098 = v921[1];
          
          break;
          }
        }
      return sim_r;
      }),
    soloSend: false,
    timeoutAt: undefined /* mto */,
    tys: [ctc0, ctc1, ctc2, ctc1, ctc1, ctc3, ctc4, ctc5, ctc1, ctc1, ctc7, ctc1, ctc10],
    waitIfNotPresent: false
    }));
  const {data: [v921], secs: v923, time: v922, didSend: v616, from: v920 } = txn1;
  switch (v921[0]) {
    case 'PrimaryBuy0_159': {
      const v924 = v921[1];
      undefined /* setApiDetails */;
      const v929 = v924[stdlib.checkedBigNumberify('./index.rsh:126:9:spread', stdlib.UInt_max, '0')];
      const v930 = stdlib.safeMul(v796, v929);
      const v961 = stdlib.add(v838, v930);
      ;
      const v962 = v837[stdlib.checkedBigNumberify('./index.rsh:109:21:dot', stdlib.UInt_max, '0')];
      const v963 = v962[stdlib.checkedBigNumberify('./index.rsh:109:21:dot', stdlib.UInt_max, '0')];
      ;
      const v972 = stdlib.le(v929, v963);
      stdlib.assert(v972, {
        at: 'reach standard library:57:5:application',
        fs: ['at ./index.rsh:136:14:application call to "check" (defined at: reach standard library:49:32:function exp)', 'at ./index.rsh:135:27:application call to [unknown function] (defined at: ./index.rsh:135:27:function exp)'],
        msg: null,
        who: 'PrimaryBuy'
        });
      const v979 = stdlib.sub(v963, v929);
      const v981 = stdlib.Array_set(v962, '0', v979);
      const v982 = stdlib.Array_set(v837, stdlib.checkedBigNumberify('./index.rsh:137:43:application', stdlib.UInt_max, '0'), v981);
      ;
      const v983 = await txn1.getOutput('PrimaryBuy', 'v929', ctc1, v929);
      if (v616) {
        stdlib.protect(ctc11, await interact.out(v924, v983), {
          at: './index.rsh:127:7:application',
          fs: ['at ./index.rsh:127:7:application call to [unknown function] (defined at: ./index.rsh:127:7:function exp)', 'at ./index.rsh:138:10:application call to "k" (defined at: ./index.rsh:135:27:function exp)', 'at ./index.rsh:135:27:application call to [unknown function] (defined at: ./index.rsh:135:27:function exp)'],
          msg: 'out',
          who: 'PrimaryBuy'
          });
        }
      else {
        }
      
      const v989 = stdlib.safeSub(v828, v929);
      const v990 = stdlib.safeAdd(v830, v929);
      const v1955 = v826;
      const v1956 = v989;
      const v1957 = v990;
      const v1959 = v982;
      const v1960 = v961;
      return;
      
      break;
      }
    case 'halt0_159': {
      const v1011 = v921[1];
      return;
      break;
      }
    case 'stopContract0_159': {
      const v1098 = v921[1];
      return;
      break;
      }
    }
  
  
  };
export async function _halt4(ctcTop, interact) {
  if (typeof(ctcTop) !== 'object' || ctcTop._initialize === undefined) {
    return Promise.reject(new Error(`The backend for _halt4 expects to receive a contract as its first argument.`));}
  if (typeof(interact) !== 'object') {
    return Promise.reject(new Error(`The backend for _halt4 expects to receive an interact object as its second argument.`));}
  const ctc = ctcTop._initialize();
  const stdlib = ctc.stdlib;
  const ctc0 = stdlib.T_Address;
  const ctc1 = stdlib.T_UInt;
  const ctc2 = stdlib.T_Token;
  const ctc3 = stdlib.T_Bytes(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '32'));
  const ctc4 = stdlib.T_Bytes(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '8'));
  const ctc5 = stdlib.T_Bool;
  const ctc6 = stdlib.T_Tuple([ctc1, ctc1, ctc5]);
  const ctc7 = stdlib.T_Array(ctc6, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'));
  const ctc8 = stdlib.T_Tuple([]);
  const ctc9 = stdlib.T_Tuple([ctc1]);
  const ctc10 = stdlib.T_Data({
    PrimaryBuy0_159: ctc9,
    halt0_159: ctc8,
    stopContract0_159: ctc8
    });
  const ctc11 = stdlib.T_Null;
  
  
  const [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838] = await ctc.getState(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4'), [ctc0, ctc1, ctc2, ctc1, ctc1, ctc3, ctc4, ctc5, ctc1, ctc1, ctc7, ctc1]);
  const v876 = ctc.selfAddress();
  const v878 = stdlib.protect(ctc8, await interact.in(), {
    at: './index.rsh:1:23:application',
    fs: ['at ./index.rsh:149:22:application call to [unknown function] (defined at: ./index.rsh:149:22:function exp)', 'at ./index.rsh:109:21:application call to "runhalt0_159" (defined at: ./index.rsh:149:10:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:109:21:function exp)'],
    msg: 'in',
    who: 'halt'
    });
  const v879 = stdlib.addressEq(v876, v793);
  stdlib.assert(v879, {
    at: 'reach standard library:57:5:application',
    fs: ['at ./index.rsh:150:12:application call to "check" (defined at: reach standard library:49:32:function exp)', 'at ./index.rsh:149:22:application call to [unknown function] (defined at: ./index.rsh:149:22:function exp)', 'at ./index.rsh:109:21:application call to "runhalt0_159" (defined at: ./index.rsh:149:10:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:109:21:function exp)'],
    msg: 'You are not Owner',
    who: 'halt'
    });
  const v884 = ['halt0_159', v878];
  
  const txn1 = await (ctc.sendrecv({
    args: [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838, v884],
    evt_cnt: 1,
    funcNum: 3,
    lct: stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '0'),
    onlyIf: true,
    out_tys: [ctc10],
    pay: [stdlib.checkedBigNumberify('./index.rsh:153:10:decimal', stdlib.UInt_max, '0'), [[stdlib.checkedBigNumberify('./index.rsh:153:14:decimal', stdlib.UInt_max, '0'), v795]]],
    sim_p: (async (txn1) => {
      const sim_r = { txns: [], mapRefs: [], maps: [] };
      let sim_txn_ctr = stdlib.UInt_max;
      const getSimTokCtr = () => { sim_txn_ctr = sim_txn_ctr.sub(1); return sim_txn_ctr; };
      
      
      const {data: [v921], secs: v923, time: v922, didSend: v616, from: v920 } = txn1;
      
      switch (v921[0]) {
        case 'PrimaryBuy0_159': {
          const v924 = v921[1];
          
          break;
          }
        case 'halt0_159': {
          const v1011 = v921[1];
          sim_r.txns.push({
            kind: 'api',
            who: "halt"
            });
          ;
          ;
          const v1082 = null;
          const v1083 = await txn1.getOutput('halt', 'v1082', ctc11, v1082);
          
          const v1088 = v826 ? false : true;
          const v1991 = v1088;
          const v1992 = v828;
          const v1993 = v830;
          const v1995 = v837;
          const v1996 = v838;
          sim_r.isHalt = false;
          
          break;
          }
        case 'stopContract0_159': {
          const v1098 = v921[1];
          
          break;
          }
        }
      return sim_r;
      }),
    soloSend: false,
    timeoutAt: undefined /* mto */,
    tys: [ctc0, ctc1, ctc2, ctc1, ctc1, ctc3, ctc4, ctc5, ctc1, ctc1, ctc7, ctc1, ctc10],
    waitIfNotPresent: false
    }));
  const {data: [v921], secs: v923, time: v922, didSend: v616, from: v920 } = txn1;
  switch (v921[0]) {
    case 'PrimaryBuy0_159': {
      const v924 = v921[1];
      return;
      break;
      }
    case 'halt0_159': {
      const v1011 = v921[1];
      undefined /* setApiDetails */;
      const v1025 = stdlib.addressEq(v920, v793);
      stdlib.assert(v1025, {
        at: 'reach standard library:57:5:application',
        fs: ['at ./index.rsh:150:12:application call to "check" (defined at: reach standard library:49:32:function exp)', 'at ./index.rsh:149:22:application call to [unknown function] (defined at: ./index.rsh:149:22:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:149:22:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:109:21:function exp)', 'at ./index.rsh:125:14:application call to [unknown function] (defined at: ./index.rsh:125:14:function exp)'],
        msg: 'You are not Owner',
        who: 'halt'
        });
      ;
      ;
      const v1082 = null;
      const v1083 = await txn1.getOutput('halt', 'v1082', ctc11, v1082);
      if (v616) {
        stdlib.protect(ctc11, await interact.out(v1011, v1083), {
          at: './index.rsh:149:11:application',
          fs: ['at ./index.rsh:149:11:application call to [unknown function] (defined at: ./index.rsh:149:11:function exp)', 'at ./index.rsh:155:12:application call to "k" (defined at: ./index.rsh:154:13:function exp)', 'at ./index.rsh:154:13:application call to [unknown function] (defined at: ./index.rsh:154:13:function exp)'],
          msg: 'out',
          who: 'halt'
          });
        }
      else {
        }
      
      const v1088 = v826 ? false : true;
      const v1991 = v1088;
      const v1992 = v828;
      const v1993 = v830;
      const v1995 = v837;
      const v1996 = v838;
      return;
      
      break;
      }
    case 'stopContract0_159': {
      const v1098 = v921[1];
      return;
      break;
      }
    }
  
  
  };
export async function _stopContract4(ctcTop, interact) {
  if (typeof(ctcTop) !== 'object' || ctcTop._initialize === undefined) {
    return Promise.reject(new Error(`The backend for _stopContract4 expects to receive a contract as its first argument.`));}
  if (typeof(interact) !== 'object') {
    return Promise.reject(new Error(`The backend for _stopContract4 expects to receive an interact object as its second argument.`));}
  const ctc = ctcTop._initialize();
  const stdlib = ctc.stdlib;
  const ctc0 = stdlib.T_Address;
  const ctc1 = stdlib.T_UInt;
  const ctc2 = stdlib.T_Token;
  const ctc3 = stdlib.T_Bytes(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '32'));
  const ctc4 = stdlib.T_Bytes(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '8'));
  const ctc5 = stdlib.T_Bool;
  const ctc6 = stdlib.T_Tuple([ctc1, ctc1, ctc5]);
  const ctc7 = stdlib.T_Array(ctc6, stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '1'));
  const ctc8 = stdlib.T_Tuple([]);
  const ctc9 = stdlib.T_Tuple([ctc1]);
  const ctc10 = stdlib.T_Data({
    PrimaryBuy0_159: ctc9,
    halt0_159: ctc8,
    stopContract0_159: ctc8
    });
  const ctc11 = stdlib.T_Null;
  
  
  const [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838] = await ctc.getState(stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4'), [ctc0, ctc1, ctc2, ctc1, ctc1, ctc3, ctc4, ctc5, ctc1, ctc1, ctc7, ctc1]);
  const v866 = ctc.selfAddress();
  const v868 = stdlib.protect(ctc8, await interact.in(), {
    at: './index.rsh:1:23:application',
    fs: ['at ./index.rsh:170:10:application call to [unknown function] (defined at: ./index.rsh:170:10:function exp)', 'at ./index.rsh:109:21:application call to "runstopContract0_159" (defined at: ./index.rsh:167:9:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:109:21:function exp)'],
    msg: 'in',
    who: 'stopContract'
    });
  const v870 = stdlib.addressEq(v866, v793);
  stdlib.assert(v870, {
    at: 'reach standard library:57:5:application',
    fs: ['at ./index.rsh:171:14:application call to "check" (defined at: reach standard library:49:32:function exp)', 'at ./index.rsh:170:10:application call to [unknown function] (defined at: ./index.rsh:170:10:function exp)', 'at ./index.rsh:170:10:application call to [unknown function] (defined at: ./index.rsh:170:10:function exp)', 'at ./index.rsh:109:21:application call to "runstopContract0_159" (defined at: ./index.rsh:167:9:function exp)', 'at ./index.rsh:109:21:application call to [unknown function] (defined at: ./index.rsh:109:21:function exp)'],
    msg: 'You don\'t have sufficient permission to end the contract',
    who: 'stopContract'
    });
  const v874 = ['stopContract0_159', v868];
  
  const txn1 = await (ctc.sendrecv({
    args: [v793, v794, v795, v796, v797, v798, v799, v826, v828, v830, v837, v838, v874],
    evt_cnt: 1,
    funcNum: 3,
    lct: stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '0'),
    onlyIf: true,
    out_tys: [ctc10],
    pay: [stdlib.checkedBigNumberify('./index.rsh:176:14:decimal', stdlib.UInt_max, '0'), [[stdlib.checkedBigNumberify('./index.rsh:176:18:decimal', stdlib.UInt_max, '0'), v795]]],
    sim_p: (async (txn1) => {
      const sim_r = { txns: [], mapRefs: [], maps: [] };
      let sim_txn_ctr = stdlib.UInt_max;
      const getSimTokCtr = () => { sim_txn_ctr = sim_txn_ctr.sub(1); return sim_txn_ctr; };
      
      
      const {data: [v921], secs: v923, time: v922, didSend: v616, from: v920 } = txn1;
      
      switch (v921[0]) {
        case 'PrimaryBuy0_159': {
          const v924 = v921[1];
          
          break;
          }
        case 'halt0_159': {
          const v1011 = v921[1];
          
          break;
          }
        case 'stopContract0_159': {
          const v1098 = v921[1];
          sim_r.txns.push({
            kind: 'api',
            who: "stopContract"
            });
          ;
          ;
          const v1178 = true;
          const v1179 = await txn1.getOutput('stopContract', 'v1178', ctc5, v1178);
          
          const v2033 = v837[stdlib.checkedBigNumberify('./index.rsh:190:21:application', stdlib.UInt_max, '0')];
          const v2034 = v2033[stdlib.checkedBigNumberify('./index.rsh:190:21:application', stdlib.UInt_max, '0')];
          sim_r.txns.push({
            kind: 'from',
            to: v793,
            tok: v795
            });
          sim_r.txns.push({
            kind: 'from',
            to: v793,
            tok: undefined /* Nothing */
            });
          sim_r.txns.push({
            kind: 'halt',
            tok: v795
            })
          sim_r.txns.push({
            kind: 'halt',
            tok: undefined /* Nothing */
            })
          sim_r.isHalt = true;
          
          break;
          }
        }
      return sim_r;
      }),
    soloSend: false,
    timeoutAt: undefined /* mto */,
    tys: [ctc0, ctc1, ctc2, ctc1, ctc1, ctc3, ctc4, ctc5, ctc1, ctc1, ctc7, ctc1, ctc10],
    waitIfNotPresent: false
    }));
  const {data: [v921], secs: v923, time: v922, didSend: v616, from: v920 } = txn1;
  switch (v921[0]) {
    case 'PrimaryBuy0_159': {
      const v924 = v921[1];
      return;
      break;
      }
    case 'halt0_159': {
      const v1011 = v921[1];
      return;
      break;
      }
    case 'stopContract0_159': {
      const v1098 = v921[1];
      undefined /* setApiDetails */;
      ;
      ;
      const v1178 = true;
      const v1179 = await txn1.getOutput('stopContract', 'v1178', ctc5, v1178);
      if (v616) {
        stdlib.protect(ctc11, await interact.out(v1098, v1179), {
          at: './index.rsh:168:7:application',
          fs: ['at ./index.rsh:168:7:application call to [unknown function] (defined at: ./index.rsh:168:7:function exp)', 'at ./index.rsh:178:10:application call to "k" (defined at: ./index.rsh:177:11:function exp)', 'at ./index.rsh:177:11:application call to [unknown function] (defined at: ./index.rsh:177:11:function exp)'],
          msg: 'out',
          who: 'stopContract'
          });
        }
      else {
        }
      
      const v2033 = v837[stdlib.checkedBigNumberify('./index.rsh:190:21:application', stdlib.UInt_max, '0')];
      const v2034 = v2033[stdlib.checkedBigNumberify('./index.rsh:190:21:application', stdlib.UInt_max, '0')];
      ;
      ;
      return;
      
      break;
      }
    }
  
  
  };
export async function PrimaryBuy(ctcTop, interact) {
  if (typeof(ctcTop) !== 'object' || ctcTop._initialize === undefined) {
    return Promise.reject(new Error(`The backend for PrimaryBuy expects to receive a contract as its first argument.`));}
  if (typeof(interact) !== 'object') {
    return Promise.reject(new Error(`The backend for PrimaryBuy expects to receive an interact object as its second argument.`));}
  const ctc = ctcTop._initialize();
  const stdlib = ctc.stdlib;
  const step = await ctc.getCurrentStep()
  if (step == 4) {return _PrimaryBuy4(ctcTop, interact);}
  throw stdlib.apiStateMismatchError({ _stateSourceMap }, [stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4')], stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, step))
  };
export async function halt(ctcTop, interact) {
  if (typeof(ctcTop) !== 'object' || ctcTop._initialize === undefined) {
    return Promise.reject(new Error(`The backend for halt expects to receive a contract as its first argument.`));}
  if (typeof(interact) !== 'object') {
    return Promise.reject(new Error(`The backend for halt expects to receive an interact object as its second argument.`));}
  const ctc = ctcTop._initialize();
  const stdlib = ctc.stdlib;
  const step = await ctc.getCurrentStep()
  if (step == 4) {return _halt4(ctcTop, interact);}
  throw stdlib.apiStateMismatchError({ _stateSourceMap }, [stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4')], stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, step))
  };
export async function stopContract(ctcTop, interact) {
  if (typeof(ctcTop) !== 'object' || ctcTop._initialize === undefined) {
    return Promise.reject(new Error(`The backend for stopContract expects to receive a contract as its first argument.`));}
  if (typeof(interact) !== 'object') {
    return Promise.reject(new Error(`The backend for stopContract expects to receive an interact object as its second argument.`));}
  const ctc = ctcTop._initialize();
  const stdlib = ctc.stdlib;
  const step = await ctc.getCurrentStep()
  if (step == 4) {return _stopContract4(ctcTop, interact);}
  throw stdlib.apiStateMismatchError({ _stateSourceMap }, [stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, '4')], stdlib.checkedBigNumberify('<builtin>', stdlib.UInt_max, step))
  };
const _ALGO = {
  ABI: {
    impure: [`PrimaryBuy(uint64)uint64`, `_reachp_0((uint64,uint64,uint64,uint64,uint64,byte[32],byte[8]))void`, `_reachp_1((uint64))void`, `_reachp_3((uint64,(byte,byte[8])))void`, `halt()void`, `stopContract()byte`],
    pure: [`balance()uint64`, `halted()byte`, `meta()(byte[32],byte[8],uint64,uint64,uint64,uint64)`, `owner()address`, `token()uint64`, `tracker()(uint64,uint64)`],
    sigs: [`PrimaryBuy(uint64)uint64`, `_reachp_0((uint64,uint64,uint64,uint64,uint64,byte[32],byte[8]))void`, `_reachp_1((uint64))void`, `_reachp_3((uint64,(byte,byte[8])))void`, `balance()uint64`, `halt()void`, `halted()byte`, `meta()(byte[32],byte[8],uint64,uint64,uint64,uint64)`, `owner()address`, `stopContract()byte`, `token()uint64`, `tracker()(uint64,uint64)`]
    },
  GlobalNumByteSlice: 3,
  GlobalNumUint: 0,
  LocalNumByteSlice: 0,
  LocalNumUint: 0,
  appApproval: `CCAJAAEECCCgjQYoMDgmAwABAAEBMRhBBA4oZEkiWzUBJVs1AilkKmRQggwEAsmHpwQz5e4hBDdHyqUEO8ouOQQ8yyqlBET4SyIES2fRDQR8WwKJBH477JkEjZy34wSxu7CoBN/ZIyg2GgCODAQqBDADmAQ2A8cDygQzA8QAAQN9BC0DjQA2GgEXNQslryk0CxZQUDULJDQBEkSIBDk0CyJbNQw0C1cICTUNgARU52KsNAwWUDQNULA0DIgEazQNIlWNAwPfA+IDiEL/uDQNI1s1CzQYNAsLSTUciARrNA9XABFJNQ0iWzUMNAs0DA5ENAs0GTEAiAQsgAgAAAAAAAADoTQLFlCwNAsWNQQjNBI0Cwk0ETQLCDIGNA80DTQMNAsJFlwAXAA0DjQcCDUONQ81EDURNRI1FDQUQQMXNBs0GhZQNBkWUDQYFlA0FxZQNBZQNBVQNBMWUQcIUDQSFlA0ERZQNA9QNA4WUCQyBjUCNQEpSwFXAH9nKkxXfxNnKDQBFjQCFlBnMRkiEkSIA6w0A0AACoAEFR98dTQEULAjQzEANBsSRCg1C4AIAAAAAAAABDo0C1CwNAs1BCM0ExQyBjUQNRM1FEL/bSM1C4AIAAAAAAAABJo0CxZRBwhQsDQLFlEHCDUEIjIGNRA1FEL/RjQBJBJEiALcNBIWNQQxGSISREL/joARAAAAAAAAAAABAAAAAAAAAAA1C0L+djQBJBJEiAKvNBMWUQcINQRC/800ASQSRIgCnDQWNBVQNBcWUDQaFlA0GBZQNBkWUDUEQv+rNAEkEkSIAno0GzUEQv+cgBEAAAAAAAAAAAIAAAAAAAAAADULQv4aNAEkEkSIAlM0GRY1BEL/dDQBJBJEiAJDNA9XABFXAAg0EhZQNQRC/1sxADUbNAsiWzUMNAslWzUaNAuBEFs1GTQLgRhbNRg0CyEEWzUXNAtXKCA1FjQLV0gINRWABGfw9p00DBZQNBoWUDQZFlA0GBZQNBcWUDQWUDQVULA0DIgCK4ERr0k1C0lXABElr1wAXAA1DTQaIg1ENBiB////////////AQxEIQWIAg4iNBkyCogB9DQbNBoWUDQZFlA0GBZQNBcWUDQWUDQVUDQNUIEZr1AjMgZC/hkjNAESRElXACA1G0khBFs1GkkhBls1GUkhB1s1GEkhCFs1F0lXQCA1FklXYAg1FVdoETUNNAsXNQyABNUVGRQ0DBZQsDQMiAGKNA1XABE1DDQNNAxJIls0GggWXABcADULNBo0GTEWNAAjCEk1AAlHAzgUMgoSRDgQJBJEOBFPAhJEOBISRDQbMQASRCMiNAtXABEiWyIyBjQLIjUONQ81EDURNRI1EzUUQv03iAEcIQWIAS02GgE1C0L+k4gBDDYaATULQv85iAEBNhoBNQtC/G4iMTQSRIEDMTUSRCIxNhJEIjE3EkSIAOGBkgGvIiJC/SZC/adC/blC/c5C/Xc0D1cAESJbNBk0G4gAwjQONBuIAMkiNBkyCjIJiADxMRmBBRJEiADAMgpgMgp4CUk1BjIKiACnQv0CIrIBI7IQsgeyCLOJIrIBJLIQshSyEbISs4lC/CFC/PNC/YFC/aBC/axC/cFC/c5IiUwJSTUGMgmIAGmJCUlB/+5JNQaIAHOJSVcAIDUbSSEEWzUaSSEGWzUZSSEHWzUYSSEIWzUXSVdAIDUWSVdgCDUVSVdoARc1E0mBaVs1EkmBcVs1EUlXeRE1D4GKAVs1DokjNQOJsUL/ckkiEkw0AhIRRImxQv9YNAYINQaJNAY0B0oPQf96Qv+CMRY0ACMISTUACUcCOAcyChJEOBAjEkQ4CBJEibGyFUL/MA==`,
  appApprovalMap: {
    0: `2`,
    1: `2`,
    10: `2`,
    100: `21`,
    1000: `563`,
    1001: `564`,
    1002: `565`,
    1003: `566`,
    1004: `567`,
    1005: `567`,
    1006: `568`,
    1007: `568`,
    1008: `569`,
    1009: `570`,
    101: `21`,
    1010: `570`,
    1011: `571`,
    1012: `571`,
    1013: `572`,
    1014: `572`,
    1015: `573`,
    1016: `573`,
    1017: `574`,
    1018: `574`,
    1019: `575`,
    102: `21`,
    1020: `575`,
    1021: `576`,
    1022: `576`,
    1023: `577`,
    1024: `577`,
    1025: `577`,
    1026: `579`,
    1027: `579`,
    1028: `579`,
    1029: `580`,
    103: `21`,
    1030: `580`,
    1031: `581`,
    1032: `581`,
    1033: `581`,
    1034: `582`,
    1035: `582`,
    1036: `582`,
    1037: `583`,
    1038: `583`,
    1039: `584`,
    104: `22`,
    1040: `584`,
    1041: `584`,
    1042: `586`,
    1043: `586`,
    1044: `586`,
    1045: `587`,
    1046: `587`,
    1047: `587`,
    1048: `588`,
    1049: `588`,
    105: `22`,
    1050: `589`,
    1051: `589`,
    1052: `589`,
    1053: `591`,
    1054: `591`,
    1055: `591`,
    1056: `592`,
    1057: `592`,
    1058: `592`,
    1059: `593`,
    106: `22`,
    1060: `593`,
    1061: `594`,
    1062: `594`,
    1063: `594`,
    1064: `596`,
    1065: `597`,
    1066: `597`,
    1067: `598`,
    1068: `599`,
    1069: `600`,
    107: `23`,
    1070: `600`,
    1071: `601`,
    1072: `601`,
    1073: `602`,
    1074: `603`,
    1075: `604`,
    1076: `605`,
    1077: `605`,
    1078: `606`,
    1079: `607`,
    108: `23`,
    1080: `608`,
    1081: `609`,
    1082: `609`,
    1083: `610`,
    1084: `611`,
    1085: `612`,
    1086: `612`,
    1087: `612`,
    1088: `613`,
    1089: `613`,
    109: `23`,
    1090: `613`,
    1091: `614`,
    1092: `615`,
    1093: `616`,
    1094: `617`,
    1095: `617`,
    1096: `617`,
    1097: `619`,
    1098: `619`,
    1099: `619`,
    11: `2`,
    110: `23`,
    1100: `621`,
    1101: `621`,
    1102: `621`,
    1103: `623`,
    1104: `623`,
    1105: `623`,
    1106: `625`,
    1107: `625`,
    1108: `625`,
    1109: `627`,
    111: `23`,
    1110: `627`,
    1111: `628`,
    1112: `628`,
    1113: `628`,
    1114: `629`,
    1115: `630`,
    1116: `632`,
    1117: `632`,
    1118: `634`,
    1119: `634`,
    112: `23`,
    1120: `635`,
    1121: `635`,
    1122: `635`,
    1123: `636`,
    1124: `636`,
    1125: `637`,
    1126: `637`,
    1127: `638`,
    1128: `638`,
    1129: `638`,
    113: `23`,
    1130: `640`,
    1131: `641`,
    1132: `641`,
    1133: `642`,
    1134: `642`,
    1135: `643`,
    1136: `643`,
    1137: `644`,
    1138: `644`,
    1139: `644`,
    114: `23`,
    1140: `646`,
    1141: `646`,
    1142: `647`,
    1143: `647`,
    1144: `648`,
    1145: `649`,
    1146: `651`,
    1147: `651`,
    1148: `651`,
    1149: `653`,
    115: `23`,
    1150: `653`,
    1151: `654`,
    1152: `655`,
    1153: `655`,
    1154: `656`,
    1155: `657`,
    1156: `659`,
    1157: `660`,
    1158: `660`,
    1159: `661`,
    116: `23`,
    1160: `661`,
    1161: `662`,
    1162: `662`,
    1163: `662`,
    1164: `663`,
    1165: `663`,
    1166: `663`,
    1167: `665`,
    1168: `666`,
    1169: `666`,
    117: `23`,
    1170: `667`,
    1171: `668`,
    1172: `668`,
    1173: `669`,
    1174: `669`,
    1175: `670`,
    1176: `670`,
    1177: `671`,
    1178: `672`,
    1179: `674`,
    118: `23`,
    1180: `675`,
    1181: `675`,
    1182: `676`,
    1183: `677`,
    1184: `677`,
    1185: `678`,
    1186: `678`,
    1187: `679`,
    1188: `679`,
    1189: `680`,
    119: `23`,
    1190: `680`,
    1191: `681`,
    1192: `682`,
    1193: `684`,
    1194: `684`,
    1195: `684`,
    1196: `686`,
    1197: `686`,
    1198: `686`,
    1199: `688`,
    12: `2`,
    120: `23`,
    1200: `688`,
    1201: `688`,
    1202: `690`,
    1203: `690`,
    1204: `690`,
    1205: `692`,
    1206: `692`,
    1207: `692`,
    1208: `694`,
    1209: `694`,
    121: `23`,
    1210: `694`,
    1211: `696`,
    1212: `696`,
    1213: `696`,
    1214: `698`,
    1215: `699`,
    1216: `701`,
    1217: `702`,
    1218: `703`,
    1219: `704`,
    122: `23`,
    1220: `704`,
    1221: `705`,
    1222: `705`,
    1223: `706`,
    1224: `706`,
    1225: `706`,
    1226: `707`,
    1227: `709`,
    1228: `710`,
    1229: `711`,
    123: `23`,
    1230: `711`,
    1231: `711`,
    1232: `712`,
    1233: `713`,
    1234: `713`,
    1235: `714`,
    1236: `714`,
    1237: `714`,
    1238: `715`,
    1239: `717`,
    124: `23`,
    1240: `718`,
    1241: `718`,
    1242: `718`,
    1243: `719`,
    1244: `719`,
    1245: `720`,
    1246: `721`,
    1247: `721`,
    1248: `722`,
    1249: `723`,
    125: `23`,
    1250: `723`,
    1251: `724`,
    1252: `725`,
    1253: `725`,
    1254: `726`,
    1255: `727`,
    1256: `727`,
    1257: `728`,
    1258: `729`,
    1259: `729`,
    126: `23`,
    1260: `730`,
    1261: `731`,
    1262: `731`,
    1263: `732`,
    1264: `733`,
    1265: `733`,
    1266: `734`,
    1267: `735`,
    1268: `735`,
    1269: `736`,
    127: `23`,
    1270: `737`,
    1271: `737`,
    1272: `737`,
    1273: `738`,
    1274: `738`,
    1275: `739`,
    1276: `740`,
    1277: `740`,
    1278: `740`,
    1279: `741`,
    128: `23`,
    1280: `741`,
    1281: `742`,
    1282: `743`,
    1283: `743`,
    1284: `743`,
    1285: `744`,
    1286: `745`,
    1287: `745`,
    1288: `746`,
    1289: `747`,
    129: `23`,
    1290: `747`,
    1291: `748`,
    1292: `749`,
    1293: `749`,
    1294: `750`,
    1295: `751`,
    1296: `751`,
    1297: `752`,
    1298: `753`,
    1299: `753`,
    13: `2`,
    130: `23`,
    1300: `754`,
    1301: `755`,
    1302: `755`,
    1303: `755`,
    1304: `756`,
    1305: `756`,
    1306: `757`,
    1307: `757`,
    1308: `757`,
    1309: `758`,
    131: `23`,
    1310: `759`,
    1311: `759`,
    1312: `760`,
    1313: `762`,
    1314: `763`,
    1315: `763`,
    1316: `764`,
    1317: `766`,
    1318: `767`,
    1319: `767`,
    132: `23`,
    1320: `767`,
    1321: `769`,
    1322: `770`,
    1323: `771`,
    1324: `772`,
    1325: `773`,
    1326: `773`,
    1327: `774`,
    1328: `775`,
    1329: `776`,
    133: `25`,
    1330: `777`,
    1331: `779`,
    1332: `780`,
    1333: `780`,
    1334: `780`,
    1335: `782`,
    1336: `782`,
    1337: `783`,
    1338: `784`,
    1339: `784`,
    134: `27`,
    1340: `785`,
    1341: `787`,
    1342: `787`,
    1343: `788`,
    1344: `788`,
    1345: `789`,
    1346: `790`,
    1347: `791`,
    1348: `791`,
    1349: `791`,
    135: `27`,
    1350: `792`,
    1351: `792`,
    1352: `792`,
    1353: `795`,
    1354: `795`,
    1355: `796`,
    1356: `796`,
    1357: `797`,
    1358: `798`,
    1359: `799`,
    136: `27`,
    1360: `800`,
    1361: `800`,
    1362: `801`,
    1363: `802`,
    1364: `802`,
    1365: `803`,
    1366: `803`,
    1367: `804`,
    1368: `804`,
    1369: `805`,
    137: `28`,
    1370: `806`,
    1371: `807`,
    1372: `807`,
    1373: `808`,
    1374: `809`,
    1375: `810`,
    1376: `811`,
    1377: `811`,
    1378: `812`,
    1379: `813`,
    138: `29`,
    1380: `814`,
    1381: `816`,
    1382: `817`,
    1383: `817`,
    1384: `818`,
    139: `29`,
    14: `2`,
    140: `31`,
    141: `32`,
    142: `33`,
    143: `34`,
    144: `34`,
    145: `35`,
    146: `36`,
    147: `37`,
    148: `38`,
    149: `38`,
    15: `2`,
    150: `40`,
    151: `41`,
    152: `41`,
    153: `42`,
    154: `43`,
    155: `44`,
    156: `44`,
    157: `44`,
    158: `45`,
    159: `45`,
    16: `2`,
    160: `46`,
    161: `47`,
    162: `48`,
    163: `48`,
    164: `49`,
    165: `49`,
    166: `50`,
    167: `50`,
    168: `50`,
    169: `51`,
    17: `2`,
    170: `51`,
    171: `52`,
    172: `52`,
    173: `52`,
    174: `52`,
    175: `52`,
    176: `52`,
    177: `53`,
    178: `53`,
    179: `54`,
    18: `2`,
    180: `55`,
    181: `56`,
    182: `56`,
    183: `57`,
    184: `58`,
    185: `60`,
    186: `60`,
    187: `61`,
    188: `61`,
    189: `61`,
    19: `2`,
    190: `62`,
    191: `62`,
    192: `63`,
    193: `64`,
    194: `65`,
    195: `65`,
    196: `65`,
    197: `65`,
    198: `65`,
    199: `65`,
    2: `2`,
    20: `2`,
    200: `65`,
    201: `65`,
    202: `66`,
    203: `66`,
    204: `66`,
    205: `68`,
    206: `68`,
    207: `69`,
    208: `70`,
    209: `71`,
    21: `4`,
    210: `71`,
    211: `72`,
    212: `72`,
    213: `73`,
    214: `73`,
    215: `74`,
    216: `75`,
    217: `76`,
    218: `76`,
    219: `77`,
    22: `4`,
    220: `77`,
    221: `77`,
    222: `80`,
    223: `80`,
    224: `81`,
    225: `81`,
    226: `81`,
    227: `82`,
    228: `83`,
    229: `83`,
    23: `5`,
    230: `84`,
    231: `85`,
    232: `86`,
    233: `86`,
    234: `87`,
    235: `87`,
    236: `88`,
    237: `88`,
    238: `89`,
    239: `90`,
    24: `5`,
    240: `95`,
    241: `95`,
    242: `97`,
    243: `97`,
    244: `99`,
    245: `99`,
    246: `100`,
    247: `100`,
    248: `100`,
    249: `101`,
    25: `5`,
    250: `101`,
    251: `101`,
    252: `101`,
    253: `101`,
    254: `101`,
    255: `101`,
    256: `101`,
    257: `101`,
    258: `101`,
    259: `102`,
    26: `6`,
    260: `102`,
    261: `103`,
    262: `104`,
    263: `105`,
    264: `106`,
    265: `106`,
    266: `107`,
    267: `108`,
    268: `108`,
    269: `109`,
    27: `7`,
    270: `110`,
    271: `110`,
    272: `111`,
    273: `111`,
    274: `112`,
    275: `113`,
    276: `113`,
    277: `114`,
    278: `114`,
    279: `115`,
    28: `8`,
    280: `116`,
    281: `116`,
    282: `117`,
    283: `117`,
    284: `118`,
    285: `118`,
    286: `119`,
    287: `119`,
    288: `120`,
    289: `120`,
    29: `9`,
    290: `121`,
    291: `122`,
    292: `123`,
    293: `123`,
    294: `124`,
    295: `124`,
    296: `125`,
    297: `125`,
    298: `126`,
    299: `126`,
    3: `2`,
    30: `10`,
    300: `127`,
    301: `128`,
    302: `128`,
    303: `129`,
    304: `129`,
    305: `130`,
    306: `130`,
    307: `131`,
    308: `131`,
    309: `132`,
    31: `11`,
    310: `132`,
    311: `133`,
    312: `133`,
    313: `135`,
    314: `135`,
    315: `136`,
    316: `136`,
    317: `136`,
    318: `138`,
    319: `138`,
    32: `11`,
    320: `139`,
    321: `139`,
    322: `140`,
    323: `141`,
    324: `142`,
    325: `142`,
    326: `143`,
    327: `144`,
    328: `145`,
    329: `145`,
    33: `12`,
    330: `146`,
    331: `147`,
    332: `148`,
    333: `148`,
    334: `149`,
    335: `150`,
    336: `151`,
    337: `151`,
    338: `152`,
    339: `153`,
    34: `13`,
    340: `153`,
    341: `154`,
    342: `155`,
    343: `155`,
    344: `156`,
    345: `157`,
    346: `157`,
    347: `157`,
    348: `158`,
    349: `159`,
    35: `14`,
    350: `159`,
    351: `160`,
    352: `161`,
    353: `162`,
    354: `162`,
    355: `163`,
    356: `164`,
    357: `165`,
    358: `165`,
    359: `166`,
    36: `14`,
    360: `167`,
    361: `167`,
    362: `168`,
    363: `169`,
    364: `170`,
    365: `171`,
    366: `171`,
    367: `173`,
    368: `173`,
    369: `174`,
    37: `15`,
    370: `174`,
    371: `175`,
    372: `176`,
    373: `176`,
    374: `177`,
    375: `177`,
    376: `177`,
    377: `178`,
    378: `179`,
    379: `180`,
    38: `16`,
    380: `181`,
    381: `181`,
    382: `181`,
    383: `182`,
    384: `183`,
    385: `184`,
    386: `184`,
    387: `185`,
    388: `186`,
    389: `186`,
    39: `17`,
    390: `187`,
    391: `188`,
    392: `189`,
    393: `190`,
    394: `190`,
    395: `191`,
    396: `192`,
    397: `193`,
    398: `195`,
    399: `195`,
    4: `2`,
    40: `18`,
    400: `195`,
    401: `197`,
    402: `197`,
    403: `198`,
    404: `198`,
    405: `198`,
    406: `200`,
    407: `200`,
    408: `200`,
    409: `200`,
    41: `19`,
    410: `200`,
    411: `200`,
    412: `201`,
    413: `201`,
    414: `202`,
    415: `203`,
    416: `205`,
    417: `206`,
    418: `208`,
    419: `208`,
    42: `21`,
    420: `209`,
    421: `209`,
    422: `210`,
    423: `211`,
    424: `219`,
    425: `220`,
    426: `220`,
    427: `221`,
    428: `221`,
    429: `221`,
    43: `21`,
    430: `221`,
    431: `221`,
    432: `221`,
    433: `221`,
    434: `221`,
    435: `221`,
    436: `221`,
    437: `222`,
    438: `222`,
    439: `223`,
    44: `21`,
    440: `224`,
    441: `225`,
    442: `225`,
    443: `226`,
    444: `226`,
    445: `227`,
    446: `228`,
    447: `228`,
    448: `229`,
    449: `230`,
    45: `21`,
    450: `230`,
    451: `231`,
    452: `231`,
    453: `232`,
    454: `232`,
    455: `233`,
    456: `233`,
    457: `234`,
    458: `234`,
    459: `234`,
    46: `21`,
    460: `236`,
    461: `237`,
    462: `237`,
    463: `238`,
    464: `238`,
    465: `238`,
    466: `238`,
    467: `238`,
    468: `238`,
    469: `238`,
    47: `21`,
    470: `238`,
    471: `238`,
    472: `238`,
    473: `239`,
    474: `239`,
    475: `240`,
    476: `241`,
    477: `241`,
    478: `241`,
    479: `242`,
    48: `21`,
    480: `243`,
    481: `244`,
    482: `244`,
    483: `245`,
    484: `246`,
    485: `246`,
    486: `246`,
    487: `247`,
    488: `247`,
    489: `248`,
    49: `21`,
    490: `249`,
    491: `249`,
    492: `250`,
    493: `250`,
    494: `251`,
    495: `251`,
    496: `252`,
    497: `252`,
    498: `252`,
    499: `254`,
    5: `2`,
    50: `21`,
    500: `254`,
    501: `255`,
    502: `256`,
    503: `257`,
    504: `260`,
    505: `260`,
    506: `260`,
    507: `261`,
    508: `261`,
    509: `262`,
    51: `21`,
    510: `263`,
    511: `263`,
    512: `265`,
    513: `265`,
    514: `266`,
    515: `267`,
    516: `268`,
    517: `270`,
    518: `270`,
    519: `270`,
    52: `21`,
    520: `272`,
    521: `272`,
    522: `272`,
    523: `272`,
    524: `272`,
    525: `272`,
    526: `272`,
    527: `272`,
    528: `272`,
    529: `272`,
    53: `21`,
    530: `272`,
    531: `272`,
    532: `272`,
    533: `272`,
    534: `272`,
    535: `272`,
    536: `272`,
    537: `272`,
    538: `272`,
    539: `273`,
    54: `21`,
    540: `273`,
    541: `274`,
    542: `274`,
    543: `274`,
    544: `276`,
    545: `276`,
    546: `277`,
    547: `278`,
    548: `279`,
    549: `282`,
    55: `21`,
    550: `282`,
    551: `282`,
    552: `283`,
    553: `283`,
    554: `284`,
    555: `285`,
    556: `285`,
    557: `285`,
    558: `286`,
    559: `286`,
    56: `21`,
    560: `287`,
    561: `287`,
    562: `287`,
    563: `289`,
    564: `289`,
    565: `290`,
    566: `291`,
    567: `292`,
    568: `295`,
    569: `295`,
    57: `21`,
    570: `295`,
    571: `296`,
    572: `296`,
    573: `297`,
    574: `297`,
    575: `298`,
    576: `299`,
    577: `299`,
    578: `300`,
    579: `301`,
    58: `21`,
    580: `302`,
    581: `302`,
    582: `303`,
    583: `304`,
    584: `305`,
    585: `305`,
    586: `306`,
    587: `307`,
    588: `308`,
    589: `308`,
    59: `21`,
    590: `309`,
    591: `310`,
    592: `311`,
    593: `311`,
    594: `312`,
    595: `312`,
    596: `312`,
    597: `314`,
    598: `314`,
    599: `315`,
    6: `2`,
    60: `21`,
    600: `316`,
    601: `317`,
    602: `320`,
    603: `320`,
    604: `320`,
    605: `321`,
    606: `321`,
    607: `322`,
    608: `322`,
    609: `323`,
    61: `21`,
    610: `323`,
    611: `323`,
    612: `325`,
    613: `325`,
    614: `325`,
    615: `325`,
    616: `325`,
    617: `325`,
    618: `325`,
    619: `325`,
    62: `21`,
    620: `325`,
    621: `325`,
    622: `325`,
    623: `325`,
    624: `325`,
    625: `325`,
    626: `325`,
    627: `325`,
    628: `325`,
    629: `325`,
    63: `21`,
    630: `325`,
    631: `326`,
    632: `326`,
    633: `327`,
    634: `327`,
    635: `327`,
    636: `329`,
    637: `329`,
    638: `330`,
    639: `331`,
    64: `21`,
    640: `332`,
    641: `335`,
    642: `335`,
    643: `335`,
    644: `336`,
    645: `336`,
    646: `337`,
    647: `338`,
    648: `338`,
    649: `339`,
    65: `21`,
    650: `339`,
    651: `339`,
    652: `341`,
    653: `341`,
    654: `342`,
    655: `343`,
    656: `344`,
    657: `347`,
    658: `347`,
    659: `347`,
    66: `21`,
    660: `348`,
    661: `348`,
    662: `349`,
    663: `349`,
    664: `349`,
    665: `350`,
    666: `350`,
    667: `350`,
    668: `351`,
    669: `351`,
    67: `21`,
    670: `352`,
    671: `353`,
    672: `354`,
    673: `354`,
    674: `355`,
    675: `355`,
    676: `355`,
    677: `357`,
    678: `357`,
    679: `358`,
    68: `21`,
    680: `358`,
    681: `359`,
    682: `359`,
    683: `360`,
    684: `361`,
    685: `362`,
    686: `362`,
    687: `363`,
    688: `363`,
    689: `364`,
    69: `21`,
    690: `365`,
    691: `366`,
    692: `366`,
    693: `367`,
    694: `367`,
    695: `368`,
    696: `368`,
    697: `369`,
    698: `370`,
    699: `370`,
    7: `2`,
    70: `21`,
    700: `371`,
    701: `371`,
    702: `372`,
    703: `372`,
    704: `373`,
    705: `374`,
    706: `374`,
    707: `375`,
    708: `375`,
    709: `376`,
    71: `21`,
    710: `376`,
    711: `377`,
    712: `378`,
    713: `378`,
    714: `379`,
    715: `379`,
    716: `380`,
    717: `380`,
    718: `380`,
    719: `381`,
    72: `21`,
    720: `381`,
    721: `382`,
    722: `382`,
    723: `383`,
    724: `383`,
    725: `383`,
    726: `384`,
    727: `384`,
    728: `385`,
    729: `385`,
    73: `21`,
    730: `385`,
    731: `385`,
    732: `385`,
    733: `385`,
    734: `386`,
    735: `386`,
    736: `387`,
    737: `388`,
    738: `389`,
    739: `389`,
    74: `21`,
    740: `390`,
    741: `391`,
    742: `392`,
    743: `392`,
    744: `393`,
    745: `394`,
    746: `395`,
    747: `395`,
    748: `396`,
    749: `397`,
    75: `21`,
    750: `398`,
    751: `398`,
    752: `399`,
    753: `400`,
    754: `401`,
    755: `401`,
    756: `402`,
    757: `403`,
    758: `403`,
    759: `404`,
    76: `21`,
    760: `405`,
    761: `407`,
    762: `407`,
    763: `408`,
    764: `408`,
    765: `408`,
    766: `409`,
    767: `409`,
    768: `410`,
    769: `411`,
    77: `21`,
    770: `412`,
    771: `412`,
    772: `413`,
    773: `414`,
    774: `414`,
    775: `414`,
    776: `415`,
    777: `416`,
    778: `417`,
    779: `417`,
    78: `21`,
    780: `418`,
    781: `418`,
    782: `419`,
    783: `419`,
    784: `420`,
    785: `420`,
    786: `421`,
    787: `422`,
    788: `423`,
    789: `427`,
    79: `21`,
    790: `427`,
    791: `428`,
    792: `428`,
    793: `428`,
    794: `428`,
    795: `428`,
    796: `428`,
    797: `428`,
    798: `428`,
    799: `428`,
    8: `2`,
    80: `21`,
    800: `428`,
    801: `428`,
    802: `429`,
    803: `430`,
    804: `434`,
    805: `434`,
    806: `435`,
    807: `435`,
    808: `435`,
    809: `436`,
    81: `21`,
    810: `438`,
    811: `438`,
    812: `439`,
    813: `439`,
    814: `440`,
    815: `440`,
    816: `440`,
    817: `442`,
    818: `442`,
    819: `443`,
    82: `21`,
    820: `443`,
    821: `444`,
    822: `445`,
    823: `446`,
    824: `446`,
    825: `447`,
    826: `448`,
    827: `449`,
    828: `449`,
    829: `450`,
    83: `21`,
    830: `451`,
    831: `452`,
    832: `452`,
    833: `453`,
    834: `454`,
    835: `455`,
    836: `455`,
    837: `456`,
    838: `457`,
    839: `457`,
    84: `21`,
    840: `458`,
    841: `459`,
    842: `459`,
    843: `460`,
    844: `461`,
    845: `461`,
    846: `462`,
    847: `463`,
    848: `464`,
    849: `465`,
    85: `21`,
    850: `465`,
    851: `466`,
    852: `466`,
    853: `466`,
    854: `468`,
    855: `469`,
    856: `469`,
    857: `470`,
    858: `471`,
    859: `473`,
    86: `21`,
    860: `474`,
    861: `474`,
    862: `474`,
    863: `475`,
    864: `475`,
    865: `476`,
    866: `477`,
    867: `477`,
    868: `478`,
    869: `479`,
    87: `21`,
    870: `479`,
    871: `480`,
    872: `481`,
    873: `481`,
    874: `482`,
    875: `483`,
    876: `483`,
    877: `484`,
    878: `485`,
    879: `485`,
    88: `21`,
    880: `486`,
    881: `487`,
    882: `487`,
    883: `488`,
    884: `489`,
    885: `489`,
    886: `490`,
    887: `491`,
    888: `491`,
    889: `492`,
    89: `21`,
    890: `493`,
    891: `493`,
    892: `493`,
    893: `494`,
    894: `494`,
    895: `495`,
    896: `496`,
    897: `496`,
    898: `496`,
    899: `497`,
    9: `2`,
    90: `21`,
    900: `497`,
    901: `498`,
    902: `498`,
    903: `498`,
    904: `499`,
    905: `499`,
    906: `500`,
    907: `500`,
    908: `501`,
    909: `502`,
    91: `21`,
    910: `502`,
    911: `503`,
    912: `503`,
    913: `503`,
    914: `503`,
    915: `503`,
    916: `503`,
    917: `504`,
    918: `504`,
    919: `505`,
    92: `21`,
    920: `506`,
    921: `507`,
    922: `509`,
    923: `509`,
    924: `510`,
    925: `510`,
    926: `510`,
    927: `511`,
    928: `511`,
    929: `512`,
    93: `21`,
    930: `512`,
    931: `512`,
    932: `513`,
    933: `513`,
    934: `514`,
    935: `514`,
    936: `515`,
    937: `515`,
    938: `516`,
    939: `517`,
    94: `21`,
    940: `518`,
    941: `519`,
    942: `519`,
    943: `520`,
    944: `521`,
    945: `522`,
    946: `522`,
    947: `523`,
    948: `523`,
    949: `524`,
    95: `21`,
    950: `524`,
    951: `525`,
    952: `525`,
    953: `526`,
    954: `526`,
    955: `529`,
    956: `529`,
    957: `530`,
    958: `530`,
    959: `531`,
    96: `21`,
    960: `532`,
    961: `533`,
    962: `534`,
    963: `534`,
    964: `535`,
    965: `536`,
    966: `536`,
    967: `537`,
    968: `537`,
    969: `538`,
    97: `21`,
    970: `538`,
    971: `539`,
    972: `540`,
    973: `541`,
    974: `541`,
    975: `542`,
    976: `543`,
    977: `544`,
    978: `545`,
    979: `545`,
    98: `21`,
    980: `546`,
    981: `546`,
    982: `547`,
    983: `548`,
    984: `549`,
    985: `549`,
    986: `550`,
    987: `551`,
    988: `554`,
    989: `554`,
    99: `21`,
    990: `555`,
    991: `555`,
    992: `556`,
    993: `557`,
    994: `560`,
    995: `561`,
    996: `562`,
    997: `562`,
    998: `563`,
    999: `563`
    },
  appClear: `CA==`,
  appClearMap: {
    },
  companionInfo: null,
  extraPages: 0,
  stateKeys: 2,
  stateSize: 146,
  unsupported: [],
  version: 13,
  warnings: []
  };
export const _stateSourceMap = {
  1: {
    at: './index.rsh:85:11:after expr stmt semicolon',
    fs: [],
    msg: null,
    who: 'Module'
    },
  3: {
    at: './index.rsh:192:11:after expr stmt semicolon',
    fs: [],
    msg: null,
    who: 'Module'
    },
  4: {
    at: './index.rsh:109:21:after expr stmt semicolon',
    fs: [],
    msg: null,
    who: 'Module'
    }
  };
export const _Connectors = {
  ALGO: _ALGO
  };
export const _Participants = {
  "Deployer": Deployer,
  "PrimaryBuy": PrimaryBuy,
  "halt": halt,
  "stopContract": stopContract
  };
export const _APIs = {
  PrimaryBuy: PrimaryBuy,
  halt: halt,
  stopContract: stopContract
  };
