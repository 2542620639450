import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import ThunkMiddleware from "redux-thunk";

import { authReducer } from './reducer/authReducer'
import { otherReducer } from './reducer/otherReducer'

const rootReducer = combineReducers({
    auth: authReducer,
    others: otherReducer
});

const middleware = [ThunkMiddleware];

const store = createStore(rootReducer, compose(applyMiddleware(...middleware),
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
));

export default store;