import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAtom } from "jotai";
import { Grid } from '@mui/material'
import { walletAtom } from '../utils/wallet'
import { API_URL, getAxiosConfig } from '../utils'
import SecondaryItem from '../components/SecondaryItem/SecondaryItem';
import Loading from '../components/Loading';


const SecondaryMarket = () => {

    const navigate = useNavigate();
    const [wallet, setWallet] = useAtom(walletAtom);
    const [nfts, setNfts] = useState([]);

    const [loader, set_loader] = useState(false)

    const get_nft = async () => {

        set_loader(true)
        const conf  = getAxiosConfig({credentials: false, jsonHeader: false})
        axios.get(`${API_URL}/nfts/secondary-market/`, {...conf})
            .then(res => {
                setNfts(res.data);
                console.log(res.data)
                set_loader(false)
                // console.log("nfts:", res.data);
            })
            .catch((error) => {
                set_loader(false)
                console.log(error.message);
            })

    }

    useEffect(() => {
        get_nft()
    }, [])

    useEffect(() => {

    }, [])
    return (
        <div className="py-5 page bg">
            <div className='container px-3 px-lg-0 px-md-0 px-sm-0' >
                {
                    loader ? <Loading /> : <Grid container spacing={4}>
                        {
                            nfts && nfts.map(nft => {
                                return <SecondaryItem key={nft.id} nft={nft} get_nft={get_nft} />
                            })
                        }
                    </Grid>
                }

            </div>
        </div>
    )
}

export default SecondaryMarket
