import React, { useEffect, useState } from 'react'
import AnimatedText from 'react-animated-text-content';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './top.css'
import InitialPlansPage from '../../pages/InitialPlansPage';


const Top = () => {
  const navigate = useNavigate();
  const [again, setAgain] = useState('')
  const [inter, setInter] = useState('')
  const [state, setState] = useState(1)
  const loader = () => {
    const interval = setInterval(() => {
      setState(prevCount => prevCount + 1);
    }, 2000)
    setInter(interval)
    setAgain('')
  }
  useEffect(() => {
    loader()
    return () => clearInterval(inter);
  }, [again])
  useEffect(() => {
    if (state === 14) {
      clearInterval(inter)
      setTimeout(() => {
        const a = document.getElementById('banner')
        a.style.left = '-100%'
        setState(1)
        loader()
      }, 5000)
    }
  }, [state])

  const startOrgSubscription = () => {

      navigate('/initial-plans');
 
     
  }
  return (
    <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh'}}>
      
           {!localStorage.getItem('user_id')?<Grid container spacing={4} justifyContent="center" sx={{
        height: '430px', // Set your desired height
        marginTop: '2%',
        minWidth: '800px', // Set your desired margin from the top
      }}>
           <InitialPlansPage></InitialPlansPage>
                {/* <Grid item xs={12} sm={1} md={1}>
                <Button variant="contained"
                  className="info-btn" style={{
                    padding: '6em 2.5em',
                    fontSize: '1.7em'
                  }} onClick={startOrgSubscription}>Subscribe</Button>
                 
                </Grid> */}
                </Grid>: 
                 <AnimatedText
                 type="chars"
                 animation={{
                   x: '10px',
                   y: '10px',
                   scale: 1.5,
                   ease: 'ease-in-out',
                 }}
                 animationType="blocks"
                 interval={0.1}
                 duration={.1}
                 tag="div"
               >
                DCarbonX
                 
               </AnimatedText>
                }
      
      {/* {
        state === 1 && <div className='text1'>
          <AnimatedText
            type="chars"
            animation={{
              x: '50px',
              y: '20px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="bounce"
            interval={0.1}
            duration={.2}
            tag="div"
          >
            Carbon markets
          </AnimatedText>
        </div>
      }
      {
        state === 2 && <div className='text2'>
          <AnimatedText
            type="chars"
            animation={{
              x: '50px',
              y: '20px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="lights"
            interval={0.2}
            duration={.1}
            tag="div"
          >
            Opacity
          </AnimatedText>
        </div>
      }
      {
        state === 3 && <div className='text3'>
          <AnimatedText
            type="chars"
            animation={{
              x: '10px',
              y: '20px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="bounce"
            interval={0.1}
            duration={.2}
            tag="div"
          >
            Complexity
          </AnimatedText>
        </div>
      }
      {
        state === 4 && <div className='text4'>
          <AnimatedText
            type="chars"
            animation={{
              x: '10px',
              y: '10px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="wave"
            interval={0.2}
            duration={.2}
            tag="div"
          >
            Fraud
          </AnimatedText>
        </div>
      }
      {
        state === 5 && <div className='text5'>
          <AnimatedText
            type="chars"
            animation={{
              x: '10px',
              y: '10px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="lights"
            interval={0.1}
            duration={.1}
            tag="div"
          >
            Greenwashing
          </AnimatedText>
        </div>
      }
      {
        state === 6 && <div className='text6'>
          <AnimatedText
            type="chars"
            animation={{
              x: '10px',
              y: '10px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="bounce"
            interval={0.1}
            duration={.1}
            tag="div"
          >
            Duplication
          </AnimatedText>
        </div>
      }

      {
        state === 7 && <div className='text1'>
          <AnimatedText
            type="chars"
            animation={{
              x: '10px',
              y: '10px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="wave"
            interval={0.1}
            duration={.1}
            tag="div"
          >
            Blockchain
          </AnimatedText>
        </div>
      }
      {
        state === 8 && <div className='text2'>
          <AnimatedText
            type="chars"
            animation={{
              x: '10px',
              y: '10px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="rifle"
            interval={0.1}
            duration={.1}
            tag="div"
          >
            Transparency
          </AnimatedText>
        </div>
      }
      {
        state === 9 && <div className='text3'>
          <AnimatedText
            type="chars"
            animation={{
              x: '10px',
              y: '10px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="bounce"
            interval={0.1}
            duration={.1}
            tag="div"
          >
            Verifiability
          </AnimatedText>
        </div>
      }
      {
        state === 10 && <div className='text44'>
          <AnimatedText
            type="chars"
            animation={{
              x: '10px',
              y: '10px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="float"
            interval={0.1}
            duration={.1}
            tag="div"
          >
            Traceability
          </AnimatedText>
        </div>
      }
      {
        state === 11 && <div className='text5'>
          <AnimatedText
            type="chars"
            animation={{
              x: '10px',
              y: '10px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="diagonal"
            interval={0.1}
            duration={.1}
            tag="div"
          >
            Permanency
          </AnimatedText>
        </div>
      }
      {
        state === 12 && <div className='text6'>
          <AnimatedText
            type="chars"
            animation={{
              x: '10px',
              y: '10px',
              scale: 1.5,
              ease: 'ease-in-out',
            }}
            animationType="blocks"
            interval={0.1}
            duration={.1}
            tag="div"
          >
            Disintermediation
          </AnimatedText>
        </div>
      }
      <div id='banner' style={{ left: `${state > 12 && '0%'}` }} className='banner'>
        {
          (state > 12 && state < 14) ? <div className='text'>
            <AnimatedText
              type="chars"
              animation={{
                x: '10px',
                y: '10px',
                scale: 1.5,
                ease: 'ease-in-out',
              }}
              animationType="blocks"
              interval={0.1}
              duration={.1}
              tag="div"
            >
              DCarbonX
            </AnimatedText>
          </div> : <div className='text'>
            <AnimatedText
              type='chars'
              interval={0.04}
              duration={0.8}
              animation={{
                y: '100px',
                ease: 'ease',
              }}
              animationType='bounce'
            >
              Blockchain-based Carbon Market
            </AnimatedText>
          </div>
        }
        {
          // state > 13 && <div className='text'>
          //   <AnimatedText
          //     type='chars'
          //     interval={0.04}
          //     duration={0.8}
          //     animation={{
          //       y: '100px',
          //       ease: 'ease',
          //     }}
          //     animationType='bounce'
          //   >
          //     Blockchain-based Carbon Market
          //   </AnimatedText>
          // </div>
        }
      </div> */}
    </div>
  )
}

export default Top