import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Grid, Button } from "@mui/material";
import { Input, Label, FormGroup, Spinner } from "reactstrap";
import swal from "sweetalert";
import { useAtom } from "jotai";
import { loadStdlib } from "@reach-sh/stdlib";
import { PeraWalletConnect } from "@perawallet/connect";

import {
  BNtoN,
  contract_Abstraction,
  secondary_contract_Abstraction,
} from "../../utils/functions";
import {
  halt,
  halt_secondary,
  stopContract,
  stopContract_secondary,
} from "../../utils/functions/interact";
import { walletAtom } from "../../utils/wallet";
// import { views } from '../../utils/functions/views';
import { API_URL, AXIOS_CONFIG } from "../../utils";
import "./my-nft-item.css";
import { indexer, reach } from "../../utils/functions/arc69.ts";
import { networkConfig } from "../../config/network";
import MakePeraConnect from "../../utils/helper";
import { contract_views } from "../../utils/functions/contract_views";

/**
 * @component
 * @param {Object} props
 * @param {nft} props.nft - The NFT item
 * @typedef nft
 * @property {number} id - The id of the NFT
 * @property {string} file - The file path of the NFT
 * @property {string} certificate_owner - The owner of the certificate
 * @property {string} certificate_owner_email - The email of the certificate owner
 * @property {string} description - The description of the NFT
 * @property {number} carbon_credits - The total number of carbon credits in this NFT
 * @property {number} total - The total number of NFTs of this kind
 * @property {number} occupied - The number of NFTs that are currently occupied
 * @property {number} sold - The number of NFTs that have been sold
 * @property {number} price - The price of this NFT in microAlgos
 * @property {number} decimals - The number of decimals of the price
 * @property {string} asset_name - The name of the asset
 * @property {number} asset_id - The id of the asset
 * @property {number} contract_id - The id of the contract
 * @property {string} unit_name - The name of the unit
 * @property {string} asset_url - The URL of the asset metadata
 * @property {string} tx_id - The transaction id of the NFT
 * @property {string} address - The Algorand address of the NFT
 * @property {string} hash - The IPFS hash of the asset metadata
 * @property {string} asset_metadata - The asset metadata in string format
 * @property {string|null} asset_metadata_hash - The IPFS hash of the asset metadata
 * @property {boolean|null} default_frozen - Whether the asset is frozen by default
 * @property {string} freeze - The Algorand address that can freeze/unfreeze the asset
 * @property {string} manager - The Algorand address that manages the asset
 * @property {string|null} reserve - The Algorand address that can reserve/revoke the asset
 * @property {SuggestedParams|null} suggested_params - The suggested transaction params
 * @property {string} file_url - The URL of the NFT file
 * @property {string} file_mimetype - The mime type of the NFT file
 * @property {string} state - The state of the NFT in the contract (New, Primary Market, Halt Primary, Halt Secondary)
 * @property {boolean} already_in_primary - Whether the NFT is already listed in the primary market
 * @property {number} grand_parent - The id of the grand parent NFT of this NFT (the NFT that this NFT was created from)
 * @property {number} parent - The id of the parent NFT of this NFT (the NFT that this NFT was created from)
 * @property {number} organisation - The id of the organisation that created this NFT
 * @property {string} org_name - The name of the organisation that created this NFT
 */

const MyNFTItem = ({
  nft,
  nfts
  // query
}) => {
  /**
   * @description Possible states of an NFT:
   * @property {string} New - The NFT is just created and not listed in any market
   * @property {string} Primary Market - The NFT is listed in the primary market and can be bought
   * @property {string} Halt Primary - The NFT is listed in the primary market but cannot be bought
   * @property {string} Halt Secondary - The NFT is listed in the secondary market but cannot be bought
   */
  const state = nft.state;
  const navigate = useNavigate();
  const [wallet, setWallet] = useAtom(walletAtom);
  const [formData, setFormData] = useState({
    price: 0,
    quantity: nft?.total - nft?.occupied,
  });
  const [toggleForm, setToggleForm] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(null);

  const [views, setViews] = useState({});

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const _indexer = await indexer();
      const reach = loadStdlib("ALGO");
      reach.setWalletFallback(
        reach.walletFallback({
          providerEnv: networkConfig.network,
          WalletConnect: MakePeraConnect(PeraWalletConnect),
        })
      );

      const accountInfo = await _indexer.lookupAccountAssets(nft.address).do();
      const assets = accountInfo.assets;
      const info = assets?.find((asset) => {
        return asset?.["asset-id"] === nft.asset_id;
      });

      if (!wallet?.networkAccount?.addr) {
        await reach.getDefaultAccount().then((res) => setWallet((_) => res));
      }
      if (!wallet?.networkAccount?.addr) return;

      const meta = await wallet.tokenMetadata(nft.asset_id);
      const amt = info.amount;
      if (isMounted) setBalance(BNtoN(amt));
    };
    if (
      ["New", "Primary Market", "Halt Primary", "Halt Secondary"]?.includes(
        nft.state
      )
    ) {
      //fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [nft.address, nft.asset_id, wallet?.networkAccount?.addr]);

  useEffect(() => {
    if (balance) {
      setFormData((prev) => ({ ...prev, quantity: +balance }));
    }
  }, [balance]);
  const getExtension = (filename) => {
    return filename?.split(".").pop();
  };
  let grand_id = nft.id;
  if (nft.grand_parent !== null) {
    grand_id = nft.grand_parent;
  }

  const putOnSalePrimary = () => {
   
    const token = {
      price: formData.price * 1000000,
      tokenId: nft?.asset_id,
      name: nft?.asset_name + "@arc69",
      symbol: nft?.id.toString(),
      decimals: parseInt(nft?.decimals),
      supply: formData?.quantity,
    };

    contract_Abstraction(wallet, token).then((response) => {
      console.log("res:", response);
      if (response.info) {
        const object = {
          user: localStorage.getItem("user_id"),
          state: "Primary Market",
          price: formData.price * 1000000,
          contract_id: response.info._hex,
          supply: formData?.quantity,
        };
        const body = JSON.stringify(object);

        axios
          .patch(`${API_URL}/nfts/${nft.id}/primary/`, body, AXIOS_CONFIG)
          .then((res) => {
            if (res.status === 500) {
              swal({
                title: "Success!",
                text: `Successfully deployed contract`,
                icon: "success",
                button: false,
                timer: 1200,
              });
              setTimeout(() => {
                navigate(`/primary-market`);
              }, 500);
            }
          })
          .catch((error) => {
            console.log(error.response.data)
            console.log(error.message);
            swal({
              title: "Failed!",
              text: `${error.message}`,
              icon: "error",
              button: false,
              timer: 1200,
            });
          });
      } else {
        swal({
          title: "Failed!",
          text: `The NFT is failed to put on Primary market`,
          icon: "error",
          button: false,
          timer: 1200,
        });
      }
    });
    setLoading(false);
  };

  const putOnSaleSecondary = () => {
    console.log("nft:", nft);
    const token = {
      name: nft?.asset_name + "@arc69",
      symbol: nft?.id.toString(),
      decimals: parseInt(nft?.decimals),
      supply: formData.quantity,
      price: formData.price * 1000000,
      tokenId: nft?.asset_id,
      Parent: nft?.contract_id,
    };
    console.log(wallet, token);

    secondary_contract_Abstraction(wallet, token).then((response) => {
      console.log("res:", response);
      if (response.info) {
        const object = {
          user: localStorage.getItem("user_id"),
          state: "Secondary Market",
          price: formData.price * 1000000,
          contract_id: response.info._hex,
          occupied: formData.quantity
        };
        const body = JSON.stringify(object);
        console.log({ object });

        axios
          .patch(`${API_URL}/nfts/${nft.id}/secondary/`, body, AXIOS_CONFIG)
          .then((res) => {
            if (res.status === 500) {
              swal({
                title: "Success!",
                text: `Successfully deployed contract`,
                icon: "success",
                button: false,
                timer: 1200,
              });
              setTimeout(() => {
                navigate(`/secondary-market`);
              }, 500);
            }
          })
          .catch((error) => {
            console.log(error.message);
            swal({
              title: "Failed!",
              text: `${error.message}`,
              icon: "error",
              button: false,
              timer: 1200,
            });
          });
      } else {
        swal({
          title: "Failed!",
          text: `The NFT is failed to put on Secondary market`,
          icon: "error",
          button: false,
          timer: 1200,
        });
      }
    });
    setLoading(false);
  };

  const putOnSale = () => {
    console.log("state:", nft.state);
    setLoading(true);
    setBtnClicked(true);
    console.log("btnClicked: ", btnClicked);
    if (formData.price.includes(".") || formData.price < 1) {
      swal({
        title: "Wrong Input Format!",
        text: `Price of one unit should be a positive integer`,
        icon: "info",
        button: false,
        timer: 1200,
      });
      setLoading(false);
      return;
    }
    if (Object.keys(wallet).length === 0) {
      swal({
        title: "Missing Wallet!",
        text: `Please connect your wallet and try again`,
        icon: "info",
        button: false,
        timer: 1200,
      });
      setLoading(false);
      return;
    }

    if (nft.already_in_primary == false || nft.state === "Halt Primary") {
      putOnSalePrimary();
    } else if (
      nft.state === "Primary Market" ||
      nft.state === "Halt Secondary" ||
      nft.already_in_primary == true
    ) {
      putOnSaleSecondary();
    }
    setBtnClicked(false);
    setLoading(true);
    console.log("btnClicked aft: ", btnClicked);
  };

  const haltNFT = () => {
    console.log("state:", nft.state);
    if (nft.state === "Primary Market") {
      const obj = {
        wallet: wallet,
        info: nft.contract_id,
      };
      halt(obj).then((response) => {
        if (response === `Successfully halted  tokens from sale`) {
          const object = {
            user: localStorage.getItem("user_id"),
            state: "Halt Primary",
          };
          const body = JSON.stringify(object);

          axios
            .patch(`${API_URL}/nfts/${nft.id}/halt/`, body, AXIOS_CONFIG)
            .then((res) => {
              if (res.status === 500) {
                swal({
                  title: "Success!",
                  text: `Successfully halted  tokens from sale`,
                  icon: "success",
                  button: false,
                  timer: 1200,
                });
                setTimeout(() => {
                  navigate(`/my-nfts`);
                }, 500);
              }
            })
            .catch((error) => {
              console.log(error.message);
              swal({
                title: "Failed!",
                text: `Failed to halt contract`,
                icon: "error",
                button: false,
                timer: 1200,
              });
            });
        } else {
          swal({
            title: "Failed!",
            text: `Failed to halt contract`,
            icon: "error",
            button: false,
            timer: 1200,
          });
        }
      });
    } else if (nft.state === "Secondary Market") {
      const obj = {
        wallet: wallet,
        info: nft.contract_id,
      };
      halt_secondary(obj).then((response) => {
        if (response === `Successfully halted  tokens from sale`) {
          const object = {
            user: localStorage.getItem("user_id"),
            state: "Halt Secondary",
          };
          const body = JSON.stringify(object);

          axios
            .patch(`${API_URL}/nfts/${nft.id}/halt/`, body, AXIOS_CONFIG)
            .then((res) => {
              if (res.status === 500) {
                swal({
                  title: "Success!",
                  text: `Successfully halted  tokens from sale`,
                  icon: "success",
                  button: false,
                  timer: 1200,
                });
                setTimeout(() => {
                  navigate(`/my-nfts`);
                }, 500);
              }
            })
            .catch((error) => {
              console.log(error.message);
              swal({
                title: "Failed!",
                text: `Failed to halt contract`,
                icon: "error",
                button: false,
                timer: 1200,
              });
            });
        } else {
          swal({
            title: "Failed!",
            text: `Failed to halt contract`,
            icon: "error",
            button: false,
            timer: 1200,
          });
        }
      });
    }
  };

  const stopNFT = () => {
    console.log("state:", nft.state);
    if (nft.state === "Primary Market") {
      stopContract(wallet, nft.contract_id).then((response) => {
        console.log("response:", response);
        if (response === "Successfully deleted contract") {
          const object = {
            user: localStorage.getItem("user_id"),
            state: "Stop Contract",
          };
          const body = JSON.stringify(object);

          axios
            .patch(`${API_URL}/nfts/${nft.id}/update/`, body, AXIOS_CONFIG)
            .then((res) => {
              if (res.status === 500) {
                swal({
                  title: "Success!",
                  text: `Successfully deleted contract`,
                  icon: "success",
                  button: false,
                  timer: 1200,
                });
                setTimeout(() => {
                  navigate(`/my-nfts`);
                }, 500);
              }
            })
            .catch((error) => {
              swal({
                title: "Failed!",
                text: `Failed to delete contract`,
                icon: "error",
                button: false,
                timer: 1200,
              });
            });
        } else {
          swal({
            title: "Failed!",
            text: `Failed to delete contract`,
            icon: "error",
            button: false,
            timer: 1200,
          });
        }
      });
    } else if (nft.state === "Secondary Market") {
      stopContract_secondary(wallet, nft.contract_id).then((response) => {
        console.log("response:", response);
        if (response === "Successfully deleted contract") {
          const object = {
            user: localStorage.getItem("user_id"),
            state: "Stop Contract Secondary",
          };
          const body = JSON.stringify(object);

          axios
            .patch(`${API_URL}/nfts/${nft.id}/update/`, body, AXIOS_CONFIG)
            .then((res) => {
              if (res.status === 200) {
                swal({
                  title: "Success!",
                  text: `Successfully deleted contract`,
                  icon: "success",
                  button: false,
                  timer: 1200,
                });
                setTimeout(() => {
                  navigate(`/my-nfts`);
                }, 500);
              }
            })
            .catch((error) => {
              console.log(error.message);
              swal({
                title: "Failed!",
                text: `Failed to delete contract`,
                icon: "error",
                button: false,
                timer: 1200,
              });
            });
        } else {
          swal({
            title: "Failed!",
            text: `Failed to delete contract`,
            icon: "error",
            button: false,
            timer: 1200,
          });
        }
      });
    }
  };
  const unit_calculate = (n) => {

    if (n.grand_parent) {
      const temp_nft = nfts.find(nt => nt.id === n.grand_parent)
      if (temp_nft) {
        return temp_nft.carbon_credits / temp_nft.total
      } else {
        const temp_parent_nft = nfts.find(nt => nt.id === n.parent)
        if (temp_parent_nft) {
          return temp_parent_nft.carbon_credits / temp_parent_nft.total
        } else {
          return n.carbon_credits / n.total
        }
      }
    } else {
      return n.carbon_credits / n.total
    }
  }

  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <Paper
        className="detail-page-link my-nft-item ml-0"
        sx={{ p: 2 }}
        style={{ boxShadow: "none", border: "1px solid #d9dbdd" }}
      >
        <p>{nft.state}</p>
        <span
          onClick={() => navigate(`/market-history/${grand_id}/`)}
          sx={{ cursor: "pointer" }}
        >
          {getExtension(nft.file)?.toLowerCase() === "pdf" && (
            <iframe src={nft.asset_url} width="100%" height="220px"></iframe>
          )}
          {getExtension(nft.file)?.toLowerCase() !== "pdf" && (
            <img
              src={nft.asset_url}
              alt="Thumbnail"
              height={220}
              width="100%"
              className="my-1"
            />
          )}
        </span>
        <Typography sx={{ marginTop: '8px' }}>
          DCarbonX ID: <b>{nft.nft_id}</b>
        </Typography>
        <Typography>
          Certificate owner: <b>{nft.org_name}</b>
        </Typography>
        <Typography sx={{ my: 1 }}>
          Carbon Credits:{" "}
          <b>
            {nft.carbon_credits} {nft.unit_name}
          </b>
        </Typography>
        <Typography sx={{ my: 1 }}>
          Total Supply of Units:{" "}
          <b>
            {nft.total}
          </b>
        </Typography>
        <Typography sx={{ fontSize: "16px", marginTop: "0px" }}>
          1 Unit:{" "}
          <strong>
            {/* {nft.total ? nft.carbon_credits / nft.total : ""} */}
            {unit_calculate(nft)} {nft.unit_name}
          </strong>
        </Typography>
        {["Primary Market", "Halt Primary", "Halt Secondary"].includes(
          nft.state
        ) ? (
          <div className="nft-item mb-3">
            Available Units:{" "}
            <span style={{ fontWeight: "bold" }}>{nft.total - nft.occupied}</span>{" "}
          </div>
        ) : (
          <div className="nft-item mb-3">
            Available Units:{" "}
            <span style={{ fontWeight: "bold" }}>{nft.total - nft.occupied}</span>{" "}
          </div>
        )}
        {["New", "Primary Market", "Halt Primary", "Halt Secondary"].includes(
          nft.state
        ) && (
            <div className="nft-item">
              {!toggleForm && (
                <Button
                  className="info-btn mb-2"
                  onClick={() => setToggleForm(!toggleForm)}
                  variant="contained"
                >
                  Sell
                </Button>
              )}
              {toggleForm && (
                <div>
                  <FormGroup>
                    <Typography sx={{ fontSize: "16px", marginTop: "15px" }}>
                      Price of one unit of the NFT
                    </Typography>
                    <Input
                      name="price"
                      type="number"
                      required
                      min={1}
                      value={formData.price}
                      onChange={(e) => {
                        setFormData({ ...formData, price: e.target.value });
                      }}
                    />
                    <Typography sx={{ fontSize: "16px", marginTop: "15px" }}>
                      Quantity of units to sell
                    </Typography>
                    <Input
                      name="price"
                      type="number"
                      required
                      min={1}
                      max={nft.total - nft.occupied}
                      value={formData.quantity}
                      onChange={(e) => {
                        // if (e.target.valueAsNumber > +balance) {
                        //   // console.log({
                        //   //   first: e.target.valueAsNumber,
                        //   //   q: formData.quantity,
                        //   // });
                        //   setFormData({
                        //     ...formData,
                        //     quantity: +balance,
                        //   });
                        //   return;
                        // }
                        // setFormData({
                        //   ...formData,
                        //   quantity: e.target.valueAsNumber,
                        // });
                        setFormData({
                          ...formData,
                          quantity: parseInt(e.target.value),
                        });
                      }}
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-between">
                    <Button
                      className={`info-btn ${loading === true ? "active-buy-btn" : ""
                        }`}
                      onClick={() => putOnSale(nft)}
                      variant="contained"
                    >
                      Sell
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}

        {["Primary Market", "Secondary Market"].includes(nft.state) && (
          <div className="d-flex justify-content-between">
            <Button
              className="buy-btn mt-1"
              onClick={haltNFT}
              variant="contained"
            >
              Stop Sale
            </Button>
            <Button
              className="buy-btn mt-1"
              onClick={stopNFT}
              variant="contained"
            >
              Delete Contract
            </Button>
          </div>
        )}
      </Paper>
    </Grid>
  );
};

export default MyNFTItem;
