/**
 * @module contract_views
 * @description Retrieves data from contract views
 */
import * as backend from "../contracts/build/index.main.mjs";
import * as backend_v2 from "../contracts/v2/build/index.main.mjs";
import * as backend2 from "../contracts/build/index.marketplace.mjs";
import * as backend2_v2 from "../contracts/v2/build/index.marketplace.mjs";

import { networkConfig } from "../../config/network";
import { loadStdlib } from "@reach-sh/stdlib";
const versionObject = {
  v1: {
    true: backend,
    false: backend2,
  },
  v2: {
    true: backend_v2,
    false: backend2_v2,
  },
};
/**
 * Retrieves data from contract views
 * @param {object} params Object with parameters
 * @param {BigNumber} params.info Contract info
 * @param {boolean} [params.first=false] If true, will use first backend, otherwise second
 * @property {function} meta Retrieves meta data from the contract
 */
export const contract_views = async ({ info, first = false }) => {
  const reach = loadStdlib("ALGO");
  reach.setProviderByName(networkConfig.network);
  const backend = versionObject["v2"][`${first}`];
  const ctc = await reach.contract(backend, info);
  /**
   * Retrieves meta data from the contract
   * @return {Promise<[string, {decimals: BigNumber, name: string, price: BigNumber, symbol: string, token: BigNumber, totalSupply: BigNumber}]>}
   */
  const meta = async () => await ctc.v.meta();
  /**
   * Retrieves Balance data from the contract
   * @return {Promise<[string,[BigNumber]]>}
   */
  const balance = async () => await ctc.v.balance();
  const token = async () => await ctc.v.token();
  const owner = async () => await ctc.v.owner();
  const tracker = async () => await ctc.v.tracker();
  const halted = async () => await ctc.v.halted();

  return {
    meta,
    token,
    owner,
    tracker,
    balance,
    halted,
  };
};
