import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from '@mui/material';
import { API_URL, AXIOS_CONFIG_PROTECTED } from '../utils';
import { useSelector } from 'react-redux';
import algo from "../assets/algo.png";

const Dashboard = () => {

    // const [url, setUrl] = useState(`https://testnet.algoexplorer.io/tx/`)
    const { org_id } = useSelector(state => state.auth)
    const [organisations, setOrganisations] = useState([])
    const [nfts, setNfts] = useState([])
    const [projects, setProjects] = useState({})
    const [carbonOffsets, setCarbonOffsets] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalTonnes, setTotalTonnes] = useState(0)
    const [totalCnetFees, setTotalCnetFees] = useState(0)

    const [nft_create_history, set_nft_create_history] = useState([])
    const [nft_buy_history_data, set_nft_buy_history_data] = useState([])
    const [nft_sell_history_data, set_nft_sell_history_data] = useState([])

    const user_id = localStorage.getItem('user_id');
    let navigate = useNavigate();

    const getOrgs = () => {
        axios.get(`${API_URL}/organisations/dashboard/`, AXIOS_CONFIG_PROTECTED)
            .then(res => {
                console.log(res.data)
                setOrganisations(res.data)
            })
    }

    const get_dashboard_data = async () => {

        try {
            const { data } = await axios.get(`${API_URL}/organisations/dashboard/data/${org_id}/`, AXIOS_CONFIG_PROTECTED)
            set_nft_create_history(data.nft_create_history)
            set_nft_buy_history_data(data.nft_buy_history_data)
            set_nft_sell_history_data(data.nft_sell_history_data)
            console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        //getCarbonOffsets();
        if (org_id) {
            get_dashboard_data()
        }

        //getOrgs();
    }, [org_id])


    const calculate_carbon_credits = (info) => {
        console.log(info)
        if (info.main_carbon_credits) {
            return (info.main_carbon_credits / info.main_total) * info.unit
        } else {
            return (info.carbon_credits / info.total) * info.unit
        }
    }

    const approveOrganisation = id => {
        axios.get(`${API_URL}/organisations/approve/${id}/`, AXIOS_CONFIG_PROTECTED)
            .then(res => {
                if (res.data.status === 'success') {
                    swal({
                        title: "Organisattion Approve",
                        text: "Organisation Approved Successfully",
                        icon: "success",
                        button: false,
                        timer: 1300,
                    });
                    getOrgs();
                }
            })
    }

    const getCarbonOffsets = () => {
        axios.get(`${API_URL}/organisations/${user_id}/dashboard/`, AXIOS_CONFIG_PROTECTED)
            .then(res => {
                console.log(res.data);
                setCarbonOffsets(res.data.data)
                setTotalAmount(res.data.total_amount);
                setTotalTonnes(res.data.total_tonnes);
                setTotalCnetFees(res.data.cnet_fees);
                setNfts(res.data.nfts)
                setProjects(res.data.projects)
            })
    }

    return (
        <div className='bg py-4'>
            <div className='container px-4 px-lg-0 px-md-0 px-sm-0' >
                {/* <div className="row px-2">
                    {
                        localStorage.getItem('role') === 'Admin' ? (
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Organisation name</TableCell>
                                            <TableCell align="right">Legal status</TableCell>
                                            <TableCell align="right">Country</TableCell>
                                            <TableCell align="right">Email</TableCell>
                                            <TableCell align="right">Linked in</TableCell>
                                            <TableCell align="right">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {organisations.map((row) => (
                                            <TableRow
                                                key={row.organisation_name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.organisation_name}
                                                </TableCell>
                                                <TableCell align="right">{row.legal_status}</TableCell>
                                                <TableCell align="right">{row.organisation_country}</TableCell>
                                                <TableCell align="right">{row.email}</TableCell>
                                                <TableCell align="right">{row.linked_in}</TableCell>
                                                <TableCell align="right">
                                                    <Button variant="outlined" color="success" size="small" onClick={() => approveOrganisation(row.id)}>Approve</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : ('')
                    }
                </div> */}

                <div className="row mb-2 mt-4">

                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md-4 mt-sm-4 mt-xs-4 mt-4 mt-lg-0">
                        <TableContainer component={Paper} style={{ height: '500px', overflowY: 'scroll' }}>
                            <Table sx={{ minWidth: 650, height: 400 }} aria-label="simple table">
                                <TableHead sx={{ color: 'white', backgroundColor: '#7B3F00' }}>
                                    <TableRow>
                                        {/* <TableCell sx={{ color: 'white' }}>Certificate owner</TableCell> */}
                                        <TableCell align="center" sx={{ color: 'white' }}>No </TableCell>
                                        <TableCell align="center" sx={{ color: 'white' }}>⁠DCarbonX ID</TableCell>
                                        <TableCell align="center" sx={{ color: 'white' }}>Bought/ Sold Unit</TableCell>
                                        <TableCell align="center" sx={{ color: 'white' }}>Price</TableCell>
                                        <TableCell align="center" sx={{ color: 'white' }}>Fee Type</TableCell>
                                        <TableCell align="center" sx={{ color: 'white' }}>Issued/ Bought/ Sold CO2 Credits</TableCell>
                                        <TableCell align="center" sx={{ color: 'white' }}>DCarbonX Fee</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        nft_create_history.map((l, i) => <TableRow key={i} className='text-success' >
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{i + 1}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{l.dcarbonx_id}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong></strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong></strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>NFT Issued</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <strong>{l.carbon_credits}</strong>
                                                    <span>{l.unit_name}</span>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img className="m-1" src={algo} alt="thumb" width={16} />
                                                    <strong>{l.fee}</strong>
                                                </div>
                                            </TableCell>
                                        </TableRow>)
                                    }

                                    {
                                        nft_buy_history_data.map((l, i) => <TableRow key={i} className='text-success' >
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{nft_create_history.length + i + 1}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{l.dcarbonx_id}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{l.unit}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img className="m-1" src={algo} alt="thumb" width={16} />
                                                    <strong>{l.price / 1000000}</strong>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>NFT Buy</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <strong>{calculate_carbon_credits(l)}</strong>
                                                    <span>{l.unit_name}</span>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img className="m-1" src={algo} alt="thumb" width={16} />
                                                    <strong>{l.fee}</strong>
                                                </div>
                                            </TableCell>
                                        </TableRow>)
                                    }

                                    {
                                        nft_sell_history_data.map((l, i) => <TableRow key={i} className='text-success' >
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{nft_create_history.length + nft_buy_history_data.length + i + 1}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{l.dcarbonx_id}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>{l.unit}</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img className="m-1" src={algo} alt="thumb" width={16} />
                                                    <strong>{l.price / 1000000}</strong>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}><strong>NFT Sell</strong></TableCell>
                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <strong>{calculate_carbon_credits(l)}</strong>
                                                    <span>{l.unit_name}</span>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" sx={{ color: '#7B3F00' }}>
                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                    <img className="m-1" src={algo} alt="thumb" width={16} />
                                                    <strong>{l.fee}</strong>
                                                </div>
                                            </TableCell>
                                        </TableRow>)
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
