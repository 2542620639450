import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import CheckoutForm from "./CheckoutForm";
import { Grid, Paper, Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../utils';

const stripePromise = loadStripe('pk_live_51MCmXzKaVYOFl2vPkwY99shFgOQDpfjCG5pWzJ0Wi0LTlXHrmWrYyZxjUqKw7hOW6NfFPtADgveJA1eZBAX2m9Bv00NW3571pa');


const Payment = () => {
    const params = useLocation();
    const carbon_offset =  params.state.carbon_offset;
    let { id } = carbon_offset
    localStorage.setItem("carbon_offset", id);

    const [clientsecret, setClientSecret] = useState('')

    useEffect(() => {
        fetch(`${API_URL}/organisations/create-checkout-session/`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(id),
        })
        .then((data) => data.json())
        .then((res) => {
            console.log('response:', res)
            setClientSecret(res.clientSecret);
            window.location.replace(res.url);
        });

    }, [])
    
    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret: clientsecret
    };


    return (
        <div className="container page mt-3">

            <Grid container spacing={3}>
                <Grid item md={6}>
                    <Paper sx={{ p : 3 }}>
                        {/* <Typography variant='h4'>{  carbon_offset.name }</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant='h5'>{ carbon_offset.tonnes } Tonnes </Typography>
                            <Typography variant='h5'>{ carbon_offset.total_amount } Euro</Typography>
                        </Box> */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Box>
                    </Paper>
                </Grid>

            </Grid>
            
        </div>
  )
}

export default Payment