import React from 'react'

const Refresh = () => {
    console.log("Refresh")
    return (
        <div>
            Refresh
        
        </div>
  )
}

export default Refresh
