import React from "react";
import InvitedUserLogin from "../components/Invitation/InvitedUserLogin";

const InvitedUserLoginPage = () => {
    return(
        <>
        <InvitedUserLogin/>
        </>
    )
}
export default InvitedUserLoginPage;