import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

const InviteUserPopUp = ({handleClose, handleInvite, setInvitedUser, open, newUser}) => {
    
    return(
        <>
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invite New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="User Email"
            type="text"
            fullWidth
            variant="standard"
            value={newUser}
            onChange={setInvitedUser}
            sx={{
                '& .MuiInputLabel-root': { color: '#7B3F00' }, // Label color
                '& .MuiInput-underline:before': { borderBottomColor: '#7B3F00' }, // Normal state
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: '#7B3F00' }, // Hover state
                '& .MuiInput-underline:after': { borderBottomColor: '#7B3F00' }, // Focused state
                '& .MuiInputBase-input': { color: 'black' } // Text color
              }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className='info-btn'>
            Cancel
          </Button>
          <Button onClick={handleInvite} className='info-btn'>
            Invite
          </Button>
        </DialogActions>
      </Dialog>
        </>
    )
}

export default InviteUserPopUp;