import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Grid, Button } from '@mui/material'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { API_URL, getAxiosConfig } from '../utils'
import MyNFTItem from '../components/MyNFTItem/MyNFTItem';
import { useSelector } from 'react-redux';


const MyNFTs = () => {

    const [nfts, setNfts] = useState([]);
    //const user_id = localStorage.getItem("user_id")
    const { user_id } = useSelector(state => state.auth)
    const [query, setQuery] = useState("All");

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const tenantId = localStorage.getItem("org_name");

    useEffect(() => {
        
        if (user_id) {
            axios.get(`${API_URL}/nfts/${user_id}/my-nfts/${tenantId}/`, {...getAxiosConfig({credentials: true})})
                .then(res => {
                    setNfts(res.data);
                })
                .catch((error) => {
                    console.log(error.message);
                })
        }
    }, [user_id])


    return (
        <div className="py-2 page bg my-nfts">
            <div className='container px-lg-0 px-md-0 px-sm-0' >

                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle className=" mt-3 mb-4"
                        style={{ textAlign: "left", width: '17%', backgroundColor: '#A0522D' }} caret>
                        {query ? query : "All"}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            value=''
                            onClick={() => setQuery("All")}
                        > All
                        </DropdownItem>
                        <DropdownItem
                            value=''
                            onClick={() => setQuery("New")}
                        > NFTs not on Sale
                        </DropdownItem>
                        <DropdownItem
                            value=''
                            onClick={() => setQuery("Primary Market")}
                        > NFTs in the Primary Market
                        </DropdownItem>
                        <DropdownItem
                            value=''
                            onClick={() => setQuery("Secondary Market")}
                        > NFTs in the Secondary Market
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <div className="row mb-3">
                    <Grid container spacing={3}>
                        {
                            nfts.length == 0 && <p className='text-danger ml-2'>There are no NFTs to show in your account.</p>
                        }
                    </Grid>
                </div>

                <div className='mb-4'>
                    <div className='row'>
                        {
                            nfts && query === "All" && nfts.map(n => {
                                return <div key={n.id} className='col-lg-4 col-md-6 col-xs-12 mb-4'>
                                    <MyNFTItem nft={n} nfts={nfts} query={query} />
                                </div>
                            })
                        }
                    </div>
                    <div className='row'>
                        {
                            nfts && query !== "All" && nfts.filter(nft => nft.state === query).map(n => {
                                return <div key={n.id} className='col-lg-4 col-md-6 col-xs-12 mb-4'>
                                    <MyNFTItem nft={n} nfts={nfts} query={query} />
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyNFTs
