import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import QRCode from "qrcode";
import { useAtom } from "jotai";
import { Paper, Typography, Grid, Button, Box } from "@mui/material";
import { walletAtom } from "../../utils/wallet";
import { API_URL, CONFIG } from "../../utils";
import "./market_history.css";
import { networkConfig } from "../../config/network";

const MarketHistory = () => {
  const navigate = useNavigate();
  let params = useParams();
  const grand_id = params.id;
  const [wallet, setWallet] = useAtom(walletAtom);
  const [toggleForm, setToggleForm] = useState(false);
  const [nft, setNft] = useState({});
  const [nfts, setNfts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [formData, setFormData] = useState({
    unit: 1,
    total_price: 0,
  });
  const [url, setUrl] = useState(
    `${networkConfig?.explorer?.asset}${nft?.asset_id}`
  );
  const [qr, setQr] = useState("");

  useEffect(() => {
    QRCode.toDataURL(
      url,
      {
        width: 200,
        margin: 2,
        color: {
          dark: "#7B3F00",
          light: "#E3BC9A40",
        },
      },
      (err, url) => {
        if (err) return console.error(err);
        setQr(url);
      }
    );
  }, [Object.keys(nft).length]);

  useEffect(() => {
    axios
      .get(`${API_URL}/nfts/${grand_id}/history/`)
      .then((res) => {
        setNfts(res.data);
        setNft(res.data[0]);
        setUrl(`${networkConfig?.explorer?.asset}${res.data[0].asset_id}`);
        setLoaded(true);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const getExtension = (filename) => {
    return filename?.split(".").pop();
  };

  return (
    <div className="bg market-history">
      <div className="container py-4">
        <div className="row">
          <div className="col-12 col-md-6">
            <Paper
              className="detail-page-link"
              sx={{ p: 2 }}
              style={{ boxShadow: "none", border: "1px solid #d9dbdd" }}
            >
              {loaded == true && (
                <div>
                  <span>
                    {getExtension(nft.file).toLowerCase() === "pdf" && (
                      <iframe
                        src={nft.asset_url}
                        width="100%"
                        height="260px"
                      ></iframe>
                    )}
                    {getExtension(nft.file).toLowerCase() !== "pdf" && (
                      <img
                        src={nft.asset_url}
                        alt="Thumbnail"
                        height={260}
                        width="100%"
                        className="my-1"
                      />
                    )}
                  </span>

                  <div>
                    {/* <Typography>Certificate Owner: <strong>{nft.owner}</strong></Typography> */}
                    <Typography sx={{ my: 1 }}>
                      Carbon Credits in NFT:{" "}
                      <strong>
                        {nft.carbon_credits} {nft.unit_name}
                      </strong>
                    </Typography>
                    <Typography sx={{ fontSize: "16px", marginTop: "0px" }}>
                      1 Unit:{" "}
                      <strong>
                        {nft.carbon_credits / nft.total} {nft.unit_name}
                      </strong>
                    </Typography>
                    <Typography
                      sx={{ cursor: "pointer", mb: 1 }}
                      onClick={() => window.open(url, "_blank")}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Transactions on the Blockchain:{" "}
                      </span>
                      <span
                        style={{ wordBreak: "break-all", color: "#335383FF" }}
                      >
                        {networkConfig?.explorer?.asset}{nft.asset_id}
                      </span>
                    </Typography>
                    {nfts.length && (
                      <div>
                        <Typography
                          sx={{
                            marginTop: "10px",
                            padding: "5px",
                            backgroundColor: "#7B3F00",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        >
                          Transactions - Primary Market
                        </Typography>

                        {nfts
                          .filter(
                            (nft) =>
                              nft.parent === null && nft.grand_parent === null
                          )
                          .map((n) => {
                            return (
                              <Typography className="d-block" key={n.id}>
                                <b>{n.owner}</b> created a fractionalised NFT
                                with {n.total} units.
                              </Typography>
                            );
                          })}
                        {nfts
                          .filter(
                            (nft) =>
                              nft.parent === nft.grand_parent &&
                              nft.parent !== null
                          )
                          .map((n) => {
                            return (
                              <Typography className="d-block" key={n.id}>
                                <b>{n.owner}</b> bought {n.total} unit
                                {n.total == 1 ? "" : "s"} from{" "}
                                <b>
                                  {n.org_name}
                                  {n.org_name.slice(-1) !== "." ? "." : ""}{" "}
                                </b>
                              </Typography>
                            );
                          })}
                      </div>
                    )}

                    {nfts.length > 1 && (
                      <div>
                        <Typography
                          sx={{
                            marginTop: "10px",
                            padding: "5px",
                            backgroundColor: "#7B3F00",
                            color: "white",
                            paddingLeft: "10px",
                          }}
                        >
                          Transactions - Secondary Market
                        </Typography>

                        {nfts
                          .filter((nft) => nft.parent !== nft.grand_parent)
                          .map((n) => {
                            return (
                              <Typography className="d-block" key={n.id}>
                                <b>{n.owner}</b> bought {n.total} unit
                                {n.total == 1 ? "" : "s"} from{" "}
                                <b>
                                  {n.org_name}
                                  {n.org_name.slice(-1) !== "." ? "." : ""}{" "}
                                </b>
                              </Typography>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Paper>
          </div>
          <div className="col-12 col-md-6">
            <div className="mt-md-0 mt-3">
              <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
                {qr && (
                  <a
                    className="btn btn-sm"
                    href={qr}
                    download="qrcode.png"
                    title="Download QRCode"
                  >
                    <img src={qr} />
                  </a>
                )}
              </Box>

              <Typography>
                <b>Issuing Organisation:</b> {nft.owner}
              </Typography>
              <Typography sx={{ wordBreak: "break-word" }}>
                <b>Description:</b> {nft.description}
              </Typography>
              <Typography sx={{ wordBreak: "break-word" }}>
                <b>Asset Url:</b> {nft.asset_url}
              </Typography>
              {/* <Typography sx={{
                                fontSize: '20px', marginTop: '15px',
                                display: 'inline', borderRadius: '5px', fontWeight: '800'
                            }}>
                                Metadata
                            </Typography> */}
              {/* <p className='mt-2' style={{ wordBreak: 'break-all', fontSize: 20 }}>{nft.asset_metadata}</p> */}
              <a
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                  nft.asset_metadata
                )}`}
                download="metadata.json"
              >
                <Button variant="contained" className="info-btn my-3">
                  Download Metadata
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketHistory;
