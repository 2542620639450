import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Label, FormGroup } from "reactstrap";
import { API_URL } from "../../utils";

const FormPartFour = ({ accounts, connectWallet, formData, setFormData }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const toggle1 = () => setDropdownOpen1((prev) => !prev);

  const [orgs, setOrgs] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [project, setProject] = useState({});
  const options = [
    { id: 1, value: "tCO2eq" },
    { id: 2, value: "MtC02eq" },
    { id: 3, value: "GtC02eq" },
  ];
  const [dateError, setDateError] = useState("");

  useEffect(() => {
    getCarbonOffsets();
  }, []);

  const getCarbonOffsets = () => {
    // Simulate a successful response without making an API call
    const mockResponse = [
      { id: 1, name: "Organisation 1" },
      { id: 2, name: "Organisation 2" },
      { id: 3, name: "Organisation 3" },
    ];
    // console.log('orgs_projects: ', mockResponse);
    setOrgs(mockResponse);
    // Uncomment below line to see the simulated data in the console
    console.log("Simulated API Response: ", mockResponse);
  };

  return (
    <div>
      <FormGroup>
        <Label for="co2offsetId">CO<sub>2</sub> Credits</Label>
        <Input
          id="co2offsetId"
          required
          name="co2Offset"
          type="number"
          value={formData.co2Offset}
          min={1}
          onChange={(e) => {
            setFormData({ ...formData, co2Offset: e.target.value });
          }}
        />
      </FormGroup>

      <Dropdown isOpen={dropdownOpen1} toggle={toggle1}>
        <DropdownToggle
          className="w-100 mb-2"
          style={{ textAlign: "left", backgroundColor: '#A0522D' }}
          caret
        >
          {formData.unitName ? formData.unitName : "Unit Name"}
        </DropdownToggle>
        <DropdownMenu>
          {options.map((option, id) => (
            <div key={option.id.toString()}>
              <DropdownItem
                value={option.value}
                onClick={(e) => {
                  setFormData({
                    ...formData,
                    unitName: e.currentTarget.textContent,
                  });
                }}
              >
                {option.value}
              </DropdownItem>
              {id !== options.length - 1 && <DropdownItem divider />}
            </div>
          ))}
        </DropdownMenu>
        {formData.unitName.length > 8 && (
          <p className="text-danger mt-1" style={{ fontSize: "14px" }}>
            Unit Name must be less than 8 character
          </p>
        )}
      </Dropdown>

      {accounts.length ? (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className="w-100 py-2" caret
            style={{ textAlign: "left", backgroundColor: '#A0522D' }}
          >
            {formData.address ? formData.address.slice(0, 30) + "..." : "Confirm Wallet"}
          </DropdownToggle>
          <DropdownMenu>
            {accounts.map((account, id) => (
              <DropdownItem
                key={id.toString()}
                value={account.address}
                onClick={(e) => {
                  setFormData({
                    ...formData,
                    address: e.currentTarget.textContent,
                  });
                  localStorage.setItem("wallet-address", e.currentTarget.textContent)
                }}
              >
                {account.address}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      ) : (
        <>
          <p style={{ fontSize: "15px" }}>
            Once you have created your wallet, you need to buy sufficient Algo
            cryptocurrency to create an NFT. The price of minting an NFT on
            Algorand is negligible at 0.001 Algo.
          </p>
          <div className="w-100">
            <ConnectWalletButton connectWallet={connectWallet} />
          </div>
        </>
      )}
    </div>
  );
};

const ConnectWalletButton = ({ connectWallet }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="relative" style={{ backgroundColor: '#7b3f00', borderRadius: '0px' }}>
      <button
        className="btn w-100 p-2 nav-link" caret
        type="button"
        onClick={() => {
          console.log({ open });
          setOpen(!open);
        }}
        style={{ fontSize: '15px', fontWeight: 550, color: 'white', border: 'none' }}
      >
        Connect Wallet
      </button>
      <div
        style={{
          display: open ? "flex" : "none",
          flexDirection: "column",
          gap: "0.1rem",
          position: "absolute",
          top: "95%",
          width: "100%",
          bottom: 40,
          left: "40%",
          backgroundColor: 'inherit'
        }}
        className={`absolute flex-col gap-0`}
      >
        <button
          type="button"
          className="btn w-100 p-2 nav-link bg-light"
          onClick={() => {
            connectWallet("pera");
            setOpen((prev => !prev));
          }}
          style={{ fontSize: '15px', zIndex: '1000', color: 'black' }}
        >
          Pera
        </button>
      </div>
    </div>
  );
};

export default FormPartFour;
