import React from 'react'
import NftForm from '../components/Form/Form'


const CreateNFT = () => {
    return <NftForm />

}

export default CreateNFT
