import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import { API_URL, AXIOS_CONFIG } from '../../utils'

const Return = () => {

    const navigate = useNavigate()
    
    useEffect(()=> {
        connectAccountWithOrganisation()
    }, [])

    const connectAccountWithOrganisation = () => {

        const user_id = localStorage.getItem("user_id")
        const acc_no = localStorage.getItem("acc_no")

        axios.get(`${API_URL}/organisations/connect-organisation-stripe/${user_id}/${acc_no}/`, AXIOS_CONFIG)
        .then(res => {
            if(res.status === 200) {
                swal({
                    title: "Success!",
                    text: `${res.data.message}`,
                    icon: "success",
                    button: false,
                    timer: 700,
                });
            }
            localStorage.removeItem("acc_no")
            navigate(`/`)
        })
        .catch((error) => {
            console.log(error.message);
        })

    }


    return (
        <div>
            Return
        </div>
  )
}

export default Return
