import { z } from "zod";
// import { reach } from "@hooks/reach/connectWallet";
import { loadStdlib } from "@reach-sh/stdlib";
//@ts-ignore
import { BigNumber, TokenMetadata } from "@reach-sh/stdlib/shared_impl";
import { Indexer } from "algosdk";
import { networkConfig } from "../../config/network";
export type wallet = {
  contract(...arg: any): any;
  tokenMetadata(...arg: any): Promise<TokenMetadata>;
  getAddress(): Promise<string>;
  balanceOf(...arg: any): Promise<{ _hex: string; _isBigNumber: true }>;
  networkAccount: {
    addr: string;
    sk: Uint8Array;
  };
  tokenAccepted: (tok: BigNumber | number) => Promise<boolean>;
  tokenAccept: (tok: BigNumber) => Promise<any>;
};
export const reach = loadStdlib("ALGO");
reach.setProviderByName(networkConfig?.network);

export const indexer = async () =>
  (await reach.getProvider()).indexer as Indexer;
const ARC_69 = async (
  acc: wallet,
  prop: {
    name: string;
    symbol: string;
    image_url: string;
    supply?: number;
    decimals?: number;
    metadata: z.infer<typeof arc69_Object>;
  }
) => {
  const { name, symbol, metadata, image_url, supply = 1, decimals } = prop;
  const _name = z.string().parse(name);
  const _symbol = z.string().parse(symbol);
  const _data = arc69_Object.parse(metadata);
  const _supply = z.number().min(1).parse(supply);
  const _decimals = z.number().min(1).parse(decimals);

  const tok = await reach.launchToken(acc, _name, _symbol, {
    supply: _supply,
    decimals: _decimals,
    url: image_url,
    // ! This hardcodes the manageer
    // manager: "UMLE6PX357QBQTV5WGW2IJ2HX5QG5GYMSORYV72TCFCDPIABY5VPTCWQTM",

    manager: acc.networkAccount.addr,
    freeze: acc.networkAccount.addr,
    note: new TextEncoder().encode(JSON.stringify(_data)),
  });
  console.log("Token deployed to", reach.bigNumberToNumber(tok?.id));
  return tok.id as BigNumber;
};
const zStr = z.string();
const arc69_Object = z
  .object({
    standard: z
      .string({
        required_error: "You must specify the ARC Type eg ARC69 ARC003 etc",
      })
      .min(4)
      .default("ARC69")
      .describe("The current ARC standard"),
    description: z
      .string({
        invalid_type_error: "This should be a string for the description ",
      })
      .describe("Should contain info about the Asset")
      .nullable(),
    external_url: zStr
      .describe("This should contain a link to an external website")
      .nullable(),
    media_url: zStr
      .describe("This should contain a link to the file to be displayed")
      .nullable(),

    mime_type: zStr.nullable(),

    properties: z.object({}).passthrough(),
  })
  .partial({
    mime_type: true,
    description: true,
    external_url: true,
    media_url: true,
  });
const inputShape = arc69_Object.shape;
// type inputKeys = keyof typeof inputShape;
// type shapeType = typeof arc69_Object._type;

export { inputShape, arc69_Object };
export default ARC_69;
