import { z } from "zod";
// import { reach } from "@hooks/reach/connectWallet";
import { loadStdlib } from "@reach-sh/stdlib";
export const reach = loadStdlib("ALGO");
export const indexer = async () => (await reach.getProvider()).indexer;
const ARC_69 = async (acc, prop) => {
  const { name, symbol, metadata, image_url, supply = 1, decimals } = prop;
  const _name = z.string().parse(name);
  const _symbol = z.string().parse(symbol);
  const _data = arc69_Object.parse(metadata);
  const _supply = z.number().min(1).parse(supply);
  const _decimals = z.number().min(1).parse(decimals);
  const tok = await reach.launchToken(acc, _name, _symbol, {
    supply: _supply,
    decimals: _decimals,
    url: image_url,
    // ! This hardcodes the manageer
    // manager: "UMLE6PX357QBQTV5WGW2IJ2HX5QG5GYMSORYV72TCFCDPIABY5VPTCWQTM",
    manager: acc.networkAccount.addr,
    freeze: acc.networkAccount.addr,
    note: new TextEncoder().encode(JSON.stringify(_data)),
  });
  console.log("Token deployed to", reach.bigNumberToNumber(tok?.id));
  return tok.id;
};
const zStr = z.string();
const arc69_Object = z
  .object({
    standard: z
      .string({
        required_error: "You must specify the ARC Type eg ARC69 ARC003 etc",
      })
      .min(4)
      .default("ARC69")
      .describe("The current ARC standard"),
    description: z
      .string({
        invalid_type_error: "This should be a string for the description ",
      })
      .describe("Should contain info about the Asset")
      .nullable(),
    external_url: zStr
      .describe("This should contain a link to an external website")
      .nullable(),
    media_url: zStr
      .describe("This should contain a link to the file to be displayed")
      .nullable(),
    mime_type: zStr.nullable(),
    properties: z.object({}).passthrough(),
  })
  .partial({
    mime_type: true,
    description: true,
    external_url: true,
    media_url: true,
  });
const inputShape = arc69_Object.shape;
export { inputShape, arc69_Object };
export default ARC_69;
