import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Button, Typography, Box, TextField } from '@mui/material'
import { API_URL, AXIOS_CONFIG_PROTECTED, AXIOS_CONFIG_GET_REQUESTS_PROTECTED } from '../../utils'
import { projectCategory } from '../data';
import Ticker from 'react-ticker'
import './style.css'
import { useDispatch } from 'react-redux';

const ProjectDetail = () => {
    const dispatch = useDispatch()
    const [project, setProject] = useState({})
    const [organisation, setOrganisation] = useState({})
    const [total, setTotal] = useState(0)
    const [category, setCategory] = useState(null);
    const [checkErrorMsg, setCheckErrorMessage] = useState(null)
    let navigate = useNavigate()

    const [formData, setFormData] = useState({
        project: 0,
        name: '',
        email: '',
        tonnes: 0,
        total_amount: 0,
    })

    const onSubmit = e => {
        e.preventDefault();

        axios.post(`${API_URL}/organisations/carbon-offset/`, formData, AXIOS_CONFIG_PROTECTED)
            .then(res => {
                console.log('project details res: ', res.data);
                navigate('/payment', {
                    state: {
                        'carbon_offset': res.data
                    }
                });
            })
            .catch((error) => {
                console.log(error.message);
            })
    }

    let { id } = useParams()

    useEffect(() => {
        axios.get(`${API_URL}/organisations/projects/${id}/`, AXIOS_CONFIG_GET_REQUESTS_PROTECTED)
            .then(res => {
                // console.log('r.data:', res.data)
                setProject(res.data);
                setFormData({ ...formData, project: res.data.id })
                setOrganisation(res.data?.organisation)
                setCategory(projectCategory[res.data.category]);
            })
            .catch((error) => {
                console.log(error.message);
            })
    }, [])

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const checkboxChange = e => {
        if (e.target.checked === false) {
            setCheckErrorMessage("Please accept our terms of sale for carbon offsets")
        }
        else {
            setCheckErrorMessage(false)
        }
    }

    return (
        <>
            {
                <div className='w-100 pt-4'>
                    <Ticker offset='100' mode='await'>
                        {({ index }) => (
                            <p className='m-0 scroll_text'>Sample data has been used in this page. Please do not pay to offset your emissions.</p>
                        )}
                    </Ticker>
                </div>
            }
            <div className="pb-4 bg-white page">
                <div className='pro_container' >
                    {
                        project &&

                        <>

                            <div className='pro_name py-3'>
                                <h3>{project.project_name}</h3>
                            </div>
                            <div className='price_cate'>
                                <div className='pro_price'>
                                    <h4 style={{ fontWeight: 'bold' }}>Carbon offset</h4>
                                    <h4 className='clone'>:</h4>
                                    <h5 className='pt-1 px-0 px-md-1'>€{project.price_per_offset}/tCO2eq</h5>
                                </div>
                                <div className='pro_cate'>
                                    <h4 style={{ fontWeight: 'bold' }}>Category</h4>
                                    <h4 className='clone'>:</h4>
                                    <h5 className='pt-1 px-0 px-md-1'>{category}</h5>
                                </div>
                                <div className='pro_cate'>
                                    <h4 style={{ fontWeight: 'bold' }}>Available tonnes</h4>
                                    <h4 className='clone'>:</h4>
                                    <h5 className='pt-1 px-0 px-md-1'>{project.available_tonnes}</h5>
                                </div>
                                <div className='pro_cate'>
                                    <h4 style={{ fontWeight: 'bold' }}>Vintage</h4>
                                    <h4 className='clone'>:</h4>
                                    <h5 className='pt-1 px-0 px-md-1'>{project.vintage}</h5>
                                </div>
                            </div>
                            <div className='form_image mb-5'>
                                <div className='pro_image'>
                                    <img src={project.image} className='rounded' alt="" />
                                </div>
                                <div className='pro_form rounded'>
                                    <div className='px-lg-3 w-100'>
                                        <Typography variant='h5' sx={{ textAlign: 'center', mb: 2, color: '#565555' }}>Lower your emissions</Typography>
                                        <form onSubmit={onSubmit} >

                                            <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                                <TextField
                                                    fullWidth
                                                    hidden
                                                    name='project'
                                                    value={project.id}
                                                />
                                            </Box>

                                            <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                                <TextField
                                                    fullWidth
                                                    required
                                                    name='name'
                                                    label="Your name"
                                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                    helperText="Enter your full name"
                                                />
                                            </Box>

                                            <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                                <TextField
                                                    fullWidth
                                                    required
                                                    name='email'
                                                    label="Your contact email"
                                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                    helperText="Enter your contact email"
                                                />
                                            </Box>

                                            <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                                <TextField
                                                    fullWidth
                                                    required
                                                    type={'number'}
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1
                                                        }
                                                    }}
                                                    max={project.offset}
                                                    name='tonnes'
                                                    value={formData.tonnes}
                                                    label={<span>Tonnes of carbon dioxide-equivalent (CO<sub>2</sub>e)</span>}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, tonnes: e.target.value, total_amount: (Math.round(e.target.value * project.price_per_offset * 100) / 100).toFixed(2) })
                                                    }}
                                                    helperText={<span>How many tonnes of CO<sub>2</sub> equivalent do you want to offset?</span>}
                                                />
                                            </Box>

                                            <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                                <TextField
                                                    fullWidth
                                                    disabled
                                                    focused={total !== 0}
                                                    type={'number'}
                                                    name='total_amount'
                                                    value={formData?.total_amount}
                                                    label="Calculate and display the price"
                                                    helperText="Net amount to pay (in EURO)"
                                                />
                                            </Box>

                                            <Box>
                                                <div >
                                                    <input className="form-check-input" type="checkbox" name="checked" onChange={checkboxChange} />
                                                    <span style={{ marginLeft: "10px", marginBottom: "15px", fontSize: '14px', color: '#00000099' }}>I have read and agree to the <span onClick={() => dispatch({ type: 'REFUND_TRUE' })} style={{ cursor: 'pointer' }} className='text-primary'>Refund Policy</span></span>
                                                    <br />
                                                </div>
                                                {
                                                    checkErrorMsg && <Typography variant='p' sx={{ color: 'red', fontSize: 14, my: 1, display: 'block' }}>{checkErrorMsg}</Typography>
                                                }
                                            </Box>

                                            <Box sx={{ maxWidth: '100%', mb: 2, mt: 2, display: 'flex', justifyContent: 'space-between' }} >

                                                <Button
                                                    variant="contained"
                                                    type='submit'
                                                    sx={{ color: 'white', backgroundColor: '#C4A484' }}
                                                    disabled={Boolean(checkErrorMsg?.length || checkErrorMsg === null)}
                                                >Proceed to pay</Button>

                                            </Box>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='row flex-column-reverse flex-lg-row'>
                                <div className='col-12 col-lg-7'>
                                    <div className="d-flex flex-column flex-md-row  align-items-between gap-2 pb-2 pb-lg-0">
                                        <div className='d-flex flex-column w-100 gap-1 py-1 px-1'>
                                            <h5 className='title1 m-0'>Country</h5>
                                            <p className='value1'>{project?.country}</p>
                                        </div>
                                        <div className='d-flex flex-column w-100 gap-1 py-1 px-1'>
                                            <h5 className='title1 m-0'>Verification Standard</h5>
                                            {
                                                project.varification_standard === "Other" ?
                                                    <p className='value1'>{project.other}</p> :
                                                    <p className='value1'>{project.varification_standard}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className='pt-3'>
                                        <p style={{ color: '#565555' }}>{project?.project_description}</p>
                                    </div>

                                </div>
                                <div className='col-12 col-lg-5 pb-4 pb-lg-0'>
                                    <div>
                                        <div>
                                            {project && project.sdgs?.length > 0 &&
                                                <Carousel
                                                    autoPlay={true}
                                                    additionalTransfrom={0}
                                                    arrows
                                                    autoPlaySpeed={3000}
                                                    centerMode={false}
                                                    className=""
                                                    containerClass="container-with-dots"
                                                    dotListClass=""
                                                    draggable
                                                    focusOnSelect={false}
                                                    infinite
                                                    keyBoardControl
                                                    minimumTouchDrag={80}
                                                    pauseOnHover
                                                    renderArrowsWhenDisabled={false}
                                                    renderButtonGroupOutside={false}
                                                    renderDotsOutside={false}
                                                    responsive={responsive}
                                                >
                                                    {
                                                        project.sdgs.map((s, i) => <div className='sdgs-image' key={i}>
                                                            <img className='img-fluid' src={s.image} alt="sdgs image" />
                                                        </div>)

                                                    }

                                                </Carousel>
                                            }
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div></>
    )
}

export default ProjectDetail