import React from 'react'

const FormPreview = ({attribute, formData}) => {
    return (
        <div>
            <h3 className='text-center'>Review</h3>
            <p>Certicate Owner Name: <strong>{localStorage.getItem('org_name')}</strong></p>
            <p>Unit Name: <strong>{formData.unitName}</strong></p>
            {/* <p>Vintage: <strong>{formData.vintage}</strong></p> */}
            <p>Wallet Address: <strong><small>{formData.address}</small></strong></p>
        </div>
    )
}

export default FormPreview
