import React from "react";
import AdminPanel from "../components/AdminPanel/AdminPanel";

const AdminPage = () =>{
return(
    <div>
        <AdminPanel/>
    </div>
);
}

export default AdminPage;