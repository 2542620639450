import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {Card, Grid, CardHeader, Button} from '@mui/material'
import { useParams, useLocation } from 'react-router-dom';
import { API_URL, getAxiosConfig } from '../../utils';
import { useNavigate } from 'react-router-dom';

const MemberDetails = () =>{
    const { userId } = useParams();
    const location = useLocation();
    const { user } = location.state || {};
    const navigate = useNavigate();
    const tenantId = localStorage.getItem("org_name");
    const deleteUser = () => {
        const conf = getAxiosConfig({ credentials: true });
        
        axios(
        {
            method: 'DELETE',
            url: `${API_URL}/tenants/delete-org-member/${userId}/${tenantId}/`,
           ...conf
        })
        .then(response => {
          
            swal({
                title: "Delete User",
                text: "User deleted!",
                icon: "success",
                button: false,
                timer: 1200,
            });
            navigate('/admin-panel');
        })
        .catch(error => {
            console.error('There was an error deleting the user!', error);
        });
    }
    return (
        <div>
         <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
            <Card style={{ maxWidth: 600 }}>
                <CardHeader
                    title={user.name}
                    subheader={user.email}
                />
            </Card>
        </Grid>
        <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
        <Button className='info-btn' onClick={deleteUser}>Remove</Button>
        </Grid>

        </div>
        
    );
    

}

export default MemberDetails;