import React from 'react';
import { useState } from 'react';
import { Grid, Container, TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import SubscriptionPlan from '../components/SubscriptionPlan/SubscriptionPlan';
import Typography from '@mui/material/Typography';

import { useLocation } from 'react-router-dom';

const PlansPage = () => {
    const location = useLocation();
    const { org_id } = location.state || {};
    console.log("org id in plans: ", org_id);
    return (
        <div className="py-5 bg page">
        <div className='container px-3 px-lg-0 px-md-0 px-sm-0' >

        
        <Typography 
          variant="h4" 
          align="center" 
          sx={{ color: 'gray' }}
       >
        Confirm your plan
        </Typography>


             <br></br>
             <br></br>
             <br></br>
             <br></br>
        <Container>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                    <SubscriptionPlan planId="SMEs" amount="200" description="(2-10 Employees)" orgId={org_id}  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SubscriptionPlan planId="MSME" amount="1000" description="(50-100 Employees)" orgId={org_id}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SubscriptionPlan planId="Large Corporate" amount="5000" description="(+100 Employees)"  orgId={org_id}/>
                </Grid>
            </Grid>
        </Container>
        </div>
        </div>
    );
};

export default PlansPage;