import React from 'react';
import swal from 'sweetalert';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionCancel = () => {
    const navigate = useNavigate();

    useEffect(() => {
      swal({
        title: 'Payment Canceled',
        text: 'You have canceled the payment.',
        icon: 'info',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/'); // Redirect to the home page
      });
    }, [navigate]);
  
}

export default SubscriptionCancel;