const initState = {
    guideStatus: false,
    refundStatus: false
}

export const otherReducer = (state = initState, action) => {
    const { payload, type } = action;

    if (type === 'GUIDE_TRUE') {
        return {
            ...state,
            guideStatus: true
        }
    }
    if (type === 'GUIDE_FALSE') {
        return {
            ...state,
            guideStatus: false
        }
    }
    if (type === 'REFUND_TRUE') {
        return {
            ...state,
            refundStatus: true
        }
    }
    if (type === 'REFUND_FALSE') {
        return {
            ...state,
            refundStatus: false
        }
    }
    return state;
}