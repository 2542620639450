import React from 'react';
import { useState } from 'react';
import {Button,  Grid, Container, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useForm } from "react-hook-form";
import InitialSubscription from '../components/SubscriptionPlan/InitialSubscription';

import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const InitialPlansPage = () => {

    
      
    const navigate = useNavigate();
    const [selectedGrid, setSelectedGrid] = useState(2);

    const plans = [{planId: "SMEs", amount: "200",description: "(2-10 Employees)"}, {planId: "MSME", amount: "1000", description: "(50-100 Employees)"}, {planId:"Large Corporate", amount:"5000", description:"(+100 Employees)"}];

    
    const handleGridClick = (index) => {
        setSelectedGrid(index);
      };
    
    const handleButtonClick = () => {
        navigate('/register/request')
    } 
    return (
        <div className="py-5 bg page">
        <div className='container px-3 px-lg-0 px-md-0 px-sm-0' >
        <br></br>
        <Typography 
          variant="h4" 
          align="center" 
          sx={{ color: 'gray' }}
       >
        Select a plan
        </Typography>
             <br></br>
             <br></br>
             <br></br>
             <br></br>
             
        <Container>
            <Grid container spacing={4} justifyContent="center">
            {plans.map((plan, index) => (
        <Grid item xs={4} key={index} >
          <InitialSubscription planId= {plan.planId} amount={plan.amount} description={plan.description}selected={selectedGrid === index} onClick={() => handleGridClick(index)} />
        </Grid>
      ))}

            </Grid>
        </Container>
        <br></br>
        <Grid 
          container 
          justifyContent="center" 
          alignItems="center" 
          >
           <Button variant="contained"
                  className="info-btn" 
                  sx={{ 
                    width: '500px', 
                    height: '45px', 
                    fontSize: '25px', 
                    padding: '0 50px  ' 
                  }} onClick={handleButtonClick}>Next</Button>
        </Grid>
        </div>
        </div>
    );
};

export default InitialPlansPage;