import React from 'react'
import { Label, FormGroup, Input } from 'reactstrap';


const FormPartOne = ({ formData, setFormData }) => {

    const _onChange = e => {

        const file = e.target.files[0];
        if(!file) return;

        setFormData({ 
            ...formData, 
            file: file, 
            fileSrc: URL.createObjectURL(file),
            fileType: file.type
      })
      console.log("file:", file)
      console.log("fileSrc:", URL.createObjectURL(e.target.files[0]))
      console.log("fileType:", file.type)
    }


    return (
        <div>
            <FormGroup>
                <Label for="fileId">
                    File Upload
                </Label>
                <Input
                    id="fileId"
                    name="file"
                    type="file"
                    onChange={(event) => _onChange.call(this, event)}
                />
                {/* accept="image/jpeg,image/png,image/gif,application/pdf" */}
            </FormGroup>

        </div>
    )
}

export default FormPartOne
