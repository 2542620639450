import React, { useState } from 'react'
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom'
import { Button, Typography, Paper, Box, TextField } from '@mui/material'
import { useDispatch } from 'react-redux';

import './projectItem.css';
import { API_URL, AXIOS_CONFIG } from '../../utils';

const ProjectItem = ({ org, project }) => {
    const dispatch = useDispatch()
    const [total, setTotal] = useState(0)
    const [isHidden, setIsHidden] = useState(true);
    const [checkErrorMsg, setCheckErrorMessage] = useState(null)
    let navigate = useNavigate()

    const [formData, setFormData] = useState({
        project: project.id,
        name: '',
        email: '',
        tonnes: 0,
        total_amount: 0,
    })

    const onSubmit = e => {
        e.preventDefault();
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios.post(`${API_URL}/organisations/carbon-offset/`, formData, AXIOS_CONFIG)
            .then(res => {
                // console.log('res: ', res);
                navigate('/payment', {
                    state: {
                        'carbon_offset': res.data
                    }
                });
            })
            .catch((error) => {
                console.log('error: ', error.message);
            })
    }

    const checkboxChange = e => {
        if (e.target.checked === false) {
            setCheckErrorMessage("Please accept our terms of sale for carbon offsets")
        }
        else {
            setCheckErrorMessage(false)
        }
    }


    return (
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-4">
            <Paper style={{ boxShadow: 'none', border: '1px solid #d9dbdd' }} sx={{ p: 2 }} key={project.id}>
                <NavLink to={`/projects/${project.id}`} style={{ textDecoration: 'none', my: 1 }}>
                    <img src={`${API_URL}${project.image}`} alt="Thumbnail" height="200px" width='100%' />

                    <Typography gutterBottom variant="h6" component="div" sx={{ color: 'white', backgroundColor: 'black', wordWrap: 'break-word', p: 1 }}>
                        {project.project_name}
                    </Typography>
                </NavLink>
                <Box sx={{ maxWidth: '100%', mb: 2, display: 'flex', justifyContent: 'left' }} >
                    <NavLink to={`/organisations/${org.id}`} style={{ textDecoration: 'none' }}>
                        <button style={{ boxShadow: '#a8b5b5 0px 2px 2px 0px' }} type='button' className='p-2 org-name-btn'>
                            <span className='org-title'>Organisation:&nbsp;</span>
                            <span className='org-name'>{org.organisation_name}</span>
                        </button>
                    </NavLink>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <form onSubmit={onSubmit}>
                        {
                            isHidden === false ? (
                                <>
                                    <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                        <TextField
                                            fullWidth
                                            hidden
                                            name='project'
                                            defaultValue={project.id}
                                        />
                                    </Box>
                                    <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                        <TextField
                                            fullWidth
                                            required
                                            name='name'
                                            label="Your name"
                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                            helperText="Enter your full name"
                                        />
                                    </Box>

                                    <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                        <TextField
                                            fullWidth
                                            required
                                            name='email'
                                            label="Your contact email"
                                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                            helperText="Enter your contact email"
                                        />
                                    </Box>

                                    <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                        <TextField
                                            fullWidth
                                            required
                                            type={'number'}
                                            min={1}
                                            max={project.offset}
                                            name='tonnes'
                                            InputProps={{
                                                inputProps: {
                                                    min: 1
                                                }
                                            }}
                                            label={<span>Tonnes of (CO<sub>2</sub>eq)</span>}
                                            onChange={(e) => setFormData({ ...formData, tonnes: e.target.value, total_amount: e.target.value * project.price_per_offset })}
                                            helperText={<span>How many tonnes of CO<sub>2</sub> equivalent do you want to offset?</span>}
                                        />
                                    </Box>

                                    <Box sx={{ maxWidth: '100%', mb: 1 }} >
                                        <TextField
                                            fullWidth
                                            disabled
                                            type={'number'}
                                            name='total_amount'
                                            focused={total !== 0}
                                            value={formData.total_amount}
                                            label="Calculate and display the price"
                                            helperText="Net amount to pay (in EURO)"
                                        />

                                    </Box>
                                    <Box className='mb-2'>
                                        <div className='d-flex'>
                                            <input className="form-check-input" type="checkbox" name="checked" onChange={checkboxChange} />
                                            <span style={{ marginLeft: "10px", marginBottom: "15px", fontSize: '14px', color: '#00000099' }}>I have read and agree to the <span onClick={() => dispatch({ type: 'REFUND_TRUE' })} style={{ cursor: 'pointer' }} className='text-primary'>Refund Policy</span></span>
                                        </div>
                                        {
                                            checkErrorMsg && <Typography variant='p' sx={{ color: 'red', fontSize: 14, mb: 2, display: 'block' }}>{checkErrorMsg}</Typography>
                                        }
                                    </Box>
                                </>
                            ) : (' ')
                        }
                        <Box sx={{ maxWidth: '100%', mb: 2, display: 'flex', justifyContent: 'space-between' }} >
                            <Button
                                variant='contained'
                                sx={{
                                    color: 'black', backgroundColor: '#008F0052', '&:hover': {
                                        color: 'white',
                                    }
                                }}
                                onClick={() => setIsHidden(prevState => !prevState)}
                            > {isHidden === false ? ('Hide Form') : ('Offset your emissions')}
                            </Button>
                            {
                                isHidden === false ? (
                                    <Button
                                        variant="contained"
                                        type='submit'
                                        sx={{ color: 'white', backgroundColor: '#216b8e' }}
                                        disabled={Boolean(checkErrorMsg?.length || checkErrorMsg === null)}
                                    >Proceed to Pay</Button>
                                ) : ('')
                            }
                        </Box>
                    </form>
                </Box>
            </Paper>
        </div >
    )
}

export default ProjectItem
