import React, { useState } from 'react'
import axios from  'axios';
import swal from 'sweetalert'
import { NavLink, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { Grid, Typography, Box, TextField, Button } from '@mui/material'
import { API_URL, AXIOS_CONFIG, getAxiosConfig } from '../../utils';
import { useDispatch } from 'react-redux';

const Login = () => {

    const dispatch = useDispatch()
    const [errorMsg, setErrorMsg] = useState('')
    const navigate = useNavigate()

    const { register, control, errors, formState, handleSubmit } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    })

    const [loader, setLoader] = useState(false)

    const onSubmit = data => {
        const object = {
            email: data.email,
            password: data.password,
        }
        const body = JSON.stringify(object);
        setLoader(true)
        axios.post(`${API_URL}/accounts/login/`, body, {
            ...getAxiosConfig({ jsonHeader: true }),
            withCredentials: true
        })
            .then(res => {

                setLoader(false)
                if (res.status === 200) {
                    localStorage.setItem('email', res.data.email)
                    localStorage.setItem('access', res.data.token)
                    localStorage.setItem('role', typeof (res.data.role) === "object" ? res.data.role[0] : res.data.role);
                    localStorage.setItem('user_id', res.data.user_id)
                    localStorage.setItem('org_id', res.data.org_id < 0 ? "" : res.data.org_id)
                    localStorage.setItem('org_name', res.data.org_name ? res.data.org_name : "")
                    localStorage.setItem('refresh', res.data.refresh ? res.data.refresh : "")

                    swal({
                        title: "Success!",
                        text: "You are logged in!",
                        icon: "success",
                        button: false,
                        timer: 1200,
                    });
                    dispatch({
                        type: 'LOGIN',
                        payload: res.data
                    })
                    // if (localStorage.getItem('history') === 'register/form') {
                    //     console.log("history...")
                    //     navigate('/register/form')
                    // }
                    //else {
                        console.log("redirection to org...")
                        setTimeout(() => {
                            navigate('/registered-organisations')
                        }, 1000);
                  //  }

                    
                }
                else if (res.status === 203) {
                    setErrorMsg(res.data.message)
                    swal({
                        title: "Failed!",
                        text: `${res.data.message}`,
                        icon: "error",
                        button: false,
                        timer: 1300,
                    });
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error.message);
            })
    }

    const onError = (error) => console.log(error);


    return (
        <div className="py-5 bg-light page">
            <div className='container px-4 px-lg-0 px-md-0 px-sm-0 d-flex justify-content-center align-items-center'>
                <form onSubmit={handleSubmit(onSubmit, onError)}
                    className='bg-white border col-12 col-lg-6 col-md-8 col-sm-10 col-xs-12 p-4 '
                    style={{ height: '500px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} >
                            <Typography variant='h5' sx={{ mb: 3 }}>Login here</Typography>

                            <Box sx={{ maxWidth: '100%', mb: 2 }} >
                                <TextField
                                    fullWidth
                                    required
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    control={control}
                                    onFocus={() => setErrorMsg('')}
                                    inputRef={register({
                                        required: "Email is required.",
                                        minLength: {
                                            value: 6,
                                            message: "Must have at least 6 characters"
                                        },
                                        // pattern: {
                                        //     value:  /^\S+@\S+$/,
                                        //     message: "Invalid Email address"
                                        // }
                                    })}
                                    error={Boolean(errors.email)}
                                    helperText={errors.email?.message ? errors.email?.message : "Your Email Address"}
                                />
                            </Box>

                            <Box sx={{ maxWidth: '100%', mb: 0 }} >
                                <TextField
                                    required
                                    fullWidth
                                    type='password'
                                    name='password'
                                    label="Password"
                                    control={control}
                                    onFocus={() => setErrorMsg('')}
                                    inputRef={register({
                                        required: "Password is required.",
                                        minLength: {
                                            value: 6,
                                            message: "Must have at least 6 characters"
                                        }
                                    })}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password?.message ? errors.password?.message : "Your Password"}

                                />
                            </Box>

                            {
                                errorMsg && <Typography variant='p' sx={{ color: 'red', fontSize: 14 }}>{errorMsg}</Typography>
                            }

                            <Box sx={{ maxWidth: '100%', m: 1 }} >
                                <Button
                                    color='dark'
                                    variant="contained"
                                    type='submit'
                                    sx={{ color: 'white' }}
                                    disabled={Boolean(Object.keys(formState.errors).length || loader)}
                                >{loader ? 'Loading...' : 'Login'}</Button>
                            </Box>
                            <Box sx={{ maxWidth: '100%', p: 2 }} >
                                <NavLink to='/reset-password'>Forgot Password?</NavLink>
                            </Box>
                           
                            <Box sx={{ maxWidth: '100%', p: 2 }} >
                                <NavLink to='/invited-login'>Member of Organisation? Login here</NavLink>
                            </Box>


                        </Grid>

                    </Grid>
                </form>
            </div>
        </div>
    )
}

export default Login
