import * as backend from "../../utils/contracts/build/index.main.mjs";
import * as backend_v2 from "../../utils/contracts/v2/build/index.main.mjs";
import * as backend2 from "../../utils/contracts/build/index.marketplace.mjs";
import * as backend2_v2 from "../../utils/contracts/v2/build/index.marketplace.mjs";

const BuyPrimary = async ({ wallet, amount, tok, info }) => {
  console.log("buy primary:", wallet, amount, tok, info);
  try {
    const isAccepted = await wallet.tokenAccepted(tok);
    !isAccepted && (await wallet.tokenAccept(tok));

    const ctc = wallet.contract(backend_v2, info);
    // const ctc = wallet.contract(backend, info);
    await ctc.apis.PrimaryBuy(amount);
    console.log(`Successfully bought  ${amount} tokens`);
    return `Successfully bought  ${amount} tokens`;
  } catch (error) {
    console.log(`Failed to Claim,  ${await wallet.getAddress()} ${error} \n`);
    throw error;
  }
};
const SecondaryBuy = async ({ wallet, amountToBuy, info, tok }) => {
  console.log("buy secondary:", wallet, amountToBuy, tok, info);
  try {
    const isAccepted = await wallet.tokenAccepted(tok);
    !isAccepted && (await wallet.tokenAccept(tok));

    const ctc = wallet.contract(backend2_v2, info);
    await ctc.apis.SecondaryBuy(amountToBuy);
    console.log(`Successfully bought ${amountToBuy} tokens `);
    return `Successfully bought ${amountToBuy} tokens `;
  } catch (error) {
    console.log(
      ` Failed  trying to purchase ${amountToBuy} tokens: ${error} \n`
    );
    throw error;
  }
};
const halt = async ({ wallet, info }) => {
  try {
    const ctc = wallet.contract(backend_v2, info);
    await ctc.apis.halt();
    console.log(`Successfully halted  tokens from sale`);
    return `Successfully halted  tokens from sale`;
  } catch (error) {
    console.log(`Failed  : ${error} \n`);
    throw error;
  }
};
const halt_secondary = async ({ wallet, info }) => {
  try {
    const ctc = wallet.contract(backend2_v2, info);
    await ctc.apis.halt();
    console.log(`Successfully halted  tokens from sale`);
    return `Successfully halted  tokens from sale`;
  } catch (error) {
    console.log(`Failed  : ${error} \n`);
    throw error;
  }
};
const stopContract = async (wallet, info) => {
  try {
    const ctc = wallet.contract(backend_v2, info);
    const isdeleted = await ctc.apis.stopContract();
    if (isdeleted) {
      console.log(`Successfully deleted contract`);
      return `Successfully deleted contract`;
    }
    console.log(`Failed to delete`);
    return `Failed to delete`;
  } catch (error) {
    console.log(` Failed   ${error} \n`);
    throw error;
  }
};
const stopContract_secondary = async (wallet, info) => {
  try {
    const ctc = wallet.contract(backend2_v2, info);
    const isdeleted = await ctc.apis.stopContract();
    if (isdeleted) {
      console.log(`Successfully deleted contract`);
      return `Successfully deleted contract`;
    }
    console.log(`Failed to delete`);
    return `Failed to delete`;
  } catch (error) {
    console.log(` Failed   ${error} \n`);
    throw error;
  }
};
export {
  BuyPrimary,
  SecondaryBuy,
  halt,
  stopContract,
  stopContract_secondary,
  halt_secondary,
};
