import React from "react";
import AcceptInvitation from "../components/Invitation/AcceptInvitation";

const InvitationPage = () => {
    return(
        <>
        <AcceptInvitation/>
        <div>Redirecting to register page ...</div>
        </>
    )
}

export default InvitationPage;