const initState = {
    errorMessage: '',
    successMessage: '',
    user_id: localStorage.getItem('user_id') ? parseInt(localStorage.getItem('user_id')) : '',
    email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
    role: localStorage.getItem('role') ? localStorage.getItem('role') : '',
    token: localStorage.getItem('access') ? localStorage.getItem('access') : '',
    org_id: localStorage.getItem('org_id') ? localStorage.getItem('org_id') : '',
    org_name: localStorage.getItem('org_name') ? localStorage.getItem('org_name') : ''
}

export const authReducer = (state = initState, action) => {
    const { payload, type } = action;

    if (type === 'LOGIN') {
        return {
            ...state,
            user_id: payload.user_id,
            email: payload.email,
            role: typeof (payload.role) === 'object' ? payload.role[0] : payload.role,
            token: payload.token,
            org_id: payload.org_id < 0 ? "" : payload.org_id,
            org_name: payload.org_name
        }
    }
    if (type === 'UPDATE_ORG_DATA') {
        return {
            ...state,
            org_id: payload.org_id,
            org_name: payload.org_name,
            role: payload.role
        }
    }
    if (type === 'LOGOUT') {
        return {
            ...state,
            user_id: '',
            email: '',
            role: '',
            token: '',
            org_id: '',
            org_name: ''
        }
    }
    return state;
}