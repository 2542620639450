import React, { useState } from 'react'
import axios from 'axios'
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { Grid, Typography, Box, TextField, Button } from '@mui/material'
import { API_URL } from '../../utils'


const UpdatePassword = () => {

    const [errorMsg, setErrorMsg] = useState('')
    const navigate = useNavigate()

    const { register, control, errors, formState, handleSubmit } = useForm({
        defaultValues: {
            new_password: '',
            confirm_password: '',
        },
    })

    const onSubmit = data => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
            }
        }
        if (data.new_password === data.confirm_password) {
            const object = {
                email: localStorage.getItem('email'),
                password: data.new_password,
            }
            const body = JSON.stringify(object);
            axios.post(`${API_URL}/accounts/update-password/`, body, config)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    swal({
                        title: "Success!",
                        text: `${res.data.message}`,
                        icon: "success",
                        button: false,
                        timer: 700,
                    });
                    navigate(`/`)
                }
                else if (res.status === 203) {
                    setErrorMsg(res.data.message)
                    swal({
                        title: "Failed!",
                        text: `${res.data.message}`,
                        icon: "error",
                        button: false,
                        timer: 700,
                    });
                }
            })
            .catch((error) => {
                console.log(error.message);
            })
        } else {
            setErrorMsg('Passwords did not match!');
        }
    }

    const onError = (error) => console.log(error);


    return (
        <form className='container page py-3' onSubmit={ handleSubmit(onSubmit, onError) }>
            <Grid container spacing={2}>
                <Grid item xs={2} md={2}></Grid>

                <Grid item xs={8} md={8} >
                    <Typography variant='h5' sx={{ mb: 3 }}>Update password for <strong>{ localStorage.getItem('email') }</strong></Typography>

                    <Box sx={{ maxWidth: '100%', mb: 2 }} >
                        <TextField
                            fullWidth 
                            required
                            type='password'
                            name="new_password"
                            label="New Password"
                            variant="outlined"
                            control={control}
                            onFocus={ () => setErrorMsg('')}
                            inputRef={register({
                                required: "Password is required.",
                                minLength: {
                                    value: 6,
                                    message: "Must have at least 6 characters"
                                },
                            })}
                            error={Boolean(errors.email)}
                            helperText={ errors.email?.message ? errors.email?.message : "Your Password" }
                        />
                    </Box>

                    <Box sx={{ maxWidth: '100%', mb: 2 }} >
                        <TextField
                            fullWidth 
                            required
                            type='password'
                            name="confirm_password"
                            label="Confirm Password"
                            variant="outlined"
                            control={control}
                            onFocus={ () => setErrorMsg('')}
                            inputRef={register({
                                required: "Confirm Password is required.",
                                minLength: {
                                    value: 6,
                                    message: "Must have at least 6 characters"
                                },
                            })}
                            error={Boolean(errors.confirm_password)}
                            helperText={ errors.confirm_password?.message ? errors.confirm_password?.message : "Confirm Your Password" }
                        />
                    </Box>

                    {
                        errorMsg && <Typography variant='p' sx={{ color: 'red', fontSize: 14 }}>{ errorMsg }</Typography>
                    }

                    <Box sx={{ maxWidth: '100%', m: 1 }} >
                        <Button 
                            className='buy-btn'
                            variant="outlined" 
                            type='submit' 
                            disabled={ Boolean(Object.keys(formState.errors).length) }
                        >Update Password</Button>
                    </Box>
                </Grid>

                <Grid item xs={2} md={2}></Grid>
            </Grid>
        </form>
  )
}

export default UpdatePassword
