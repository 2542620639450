import React, { useState, useEffect } from 'react'
// import { useParams, useNavigate} from 'react-router-dom'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import swal from 'sweetalert';

import { API_URL } from '../utils';
import '../components/OrganisationDetail/org_detail.css';


const Profile = () => {
    const navigate = useNavigate();
    const org_id = localStorage.getItem('org_id');
    const [organisation, setOrganisation] = useState({})
    console.log("org_id: ", org_id);


    useEffect(() => {
        if(!org_id){
            navigate('/login');
            return;
        }
        axios.get(`${API_URL}/organisations/${org_id}/`)
            .then(res => {
                console.log(res.data);
                setOrganisation(res.data);
            })
            .catch((error) => {
                console.log(error.message);
            })
    }, [])


    return (
        <div className='bg p-3 rounded-lg m-2'>
            <div className="container">
                <div className="row pb-3">
                    <div className="col-12 col-md-6 col-lg-4 col-sm-6 col-xs-12">
                        <div className="img-wrapper my-1">
                            <img src={organisation.logo} className='w-100' height='160px' />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 col-sm-6 col-xs-12">
                        <div className='card h-100 w-100 d-flex justify-content-center align-items-center flex-column mt-4 mt-lg-0 mt-md-0 mt-sm-0 '>
                            <h6 className='title'>Legal Status</h6>
                            <p className='value'>{organisation.legal_status}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4 col-sm-12 col-xs-12 py-md-4 py-sm-4 py-xs-4 py-lg-0 py-4">
                        <div className='card bg-white h-100 w-100 d-flex justify-content-center align-items-center flex-column mt-4  py-4 mt-lg-0 mt-md-0 mt-sm-0 bg-light'>
                            <h5 className='title'>Entity Website</h5>
                            <p className='value'>{organisation.website}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <hr />
                </div>
                <div className="row">
                    <h4>Entity Information</h4>
                    <div className="col-md-12 title">
                        <h5> Description</h5>
                    </div>
                    <div className="value col-12 py-1">
                        {
                            organisation.organisation_description ? <p>{organisation.organisation_description}</p>
                                : ('')
                        }

                    </div>
                </div>

                <div className="row my-2">
                    <h6>Entity Detail</h6>
                </div>
                <div>
                    <hr />
                </div>
                <div className="row">
                    <p className="title">
                        Place of Registration or Incorporation: <b>{organisation.organisation_address}, {organisation.organisation_country}</b>
                    </p>
                </div>

                <div className="row">
                    <p className="title">
                        Relevant Registration or Incorporation Identifier: <b>{organisation.relevant_registration}</b>
                    </p>
                </div>


                <div className="row my-2">
                    <h3>Contact Details for the Authorized Representative of the Entity</h3>
                    <hr />
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6 col-sm-6">
                        <p className="title">
                            Name: <b>{organisation.representative_name}</b>
                        </p>
                    </div>
                    <div className="col-16 col-lg-6 col-sm-6">
                        <p className="title">
                            Position/Role: <b> {organisation.position}</b>
                        </p>
                    </div>
                    <div className="col-12 col-lg-6 col-sm-6">
                        <p className="title">
                            Email Address: <b>{organisation.email}</b>
                        </p>
                    </div>
                    <div className="col-12 col-lg-6 col-sm-6 pb-3">
                        <p className="title">
                            Profile Link: <b>{organisation.linked_in}</b>
                        </p>
                    </div>
                    <hr />
                </div>

                <div className="row my-2">
                    <h4>Additional Information</h4>
                    {/* <h6 className='pb-2'>Regulatory Information</h6> */}
                    <hr />
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6 col-sm-6">
                        <p className="title">
                            Jurisdiction: <b>{organisation.confirm_jurisdiction}</b>
                        </p>
                    </div>

                    <div className="col-12 col-lg-6 col-sm-6">
                        <p className="title">
                            Relevant Unique Regulatory Reference Number: <b>{organisation.relevant_registration}</b>
                        </p>
                    </div>

                    {/* <div className="col-12 col-lg-6 col-sm-6">
                        <p className="title">
                            Regulatory Permissions/ Activities: <b>{organisation.regulatory_permission}</b>
                        </p>
                    </div> */}
                </div>
                <div className="row">
                    <p className='text-center my-3' style={{ color: '#7B3F00'}}>For any changes in the profile please write 
                        to: <a href="mailto:dcarbonx.nashfintechx.com" style={{ fontSize: '18px' }}><strong>support@dcarbonx.nashfintechx.com</strong></a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Profile
