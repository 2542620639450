import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { Button, Typography, Grid, Paper, Box, CircularProgress } from '@mui/material'
import { API_URL, getAxiosConfig} from '../utils';
import '../components/ProjectItem/projectItem.css';


const RegisteredOrganisations = () => {
    const navigate = useNavigate();
    const [organisations, setOrganisations] = useState([])
    const [loader, setLoader] = useState(false)

    const get_org = async () => {
        axios.defaults.withCredentials = true
        const conf = getAxiosConfig({jsonHeader: false, credentials: true });


        try {

        } catch (error) {

        }
        setLoader(true)
        axios.get(`${API_URL}/invitedorganisations/invited-org/${localStorage.getItem("org_name")}/`, {...conf} )
            .then(res => {
                setLoader(false)
                setOrganisations(res.data);
            })
            .catch((error) => {
                setLoader(false)
                console.log(error.message);
            })
        setLoader(true)
    }
    useEffect(() => {
        get_org()
    }, [])


    return (
        <div className="py-5 py-md-5 bg page">
            {/* <div className='p-5 bg-black text-white cursor-pointer' onClick={testDelete}>test</div> */}
            <div className="container">
                {
                    !loader ?
                        organisations.length ? <Grid container spacing={2}>
                            {
                                organisations.map(org => {
                                    return <Grid item xs={12} sm={6} md={6} lg={4} key={org.id}>
                                        <Paper style={{ boxShadow: 'none', border: '1px solid #d9dbdd' }} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 2 }}>
                                            <Box className='org-link' onClick={() => navigate(`/organisations/${org.id}`)}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img src={org.logo} alt="Thumbnail" height="170px" width='50%' />
                                                </Box>

                                                <Typography gutterBottom variant="h6" component="div" backgroundColor="#7b3f00"
                                                    sx={{ color: 'white', p: 1, mt: 1, textAlign: 'center' }} >&nbsp;
                                                    {org.invited_organisation_name}
                                                </Typography>
                                                <p></p>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                })
                            }
                             </Grid> : <div className='w-100'>
                           {localStorage.getItem('role') === 'W-Approved'&& <h4 className='text-center'>Start inviting organisations</h4>} 
                        </div> : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Box>
                }
            </div>
        </div>
    )
}

export default RegisteredOrganisations
