import React from 'react'
import { useLocation } from 'react-router-dom'
import ProjectItem from '../ProjectItem/ProjectItem';


const SupportedProjects = () => {

    const params = useLocation();
    const projects =  params.state.projects;
    const org = params.state.org

    return (
        <div className='bg-light pt-3 page'>
            <div className="container">
                <div className="row">
                    {   
                        projects.length < 1 ? (
                            <p>No Projects available under this organisation</p>
                        ):(
                            projects.length && projects.map( project => {
                                return <ProjectItem key={project.id} org={org} project={project} />
                            })
                        )
                    }

                </div>
            </div>
        </div>
  )
}

export default SupportedProjects
