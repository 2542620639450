import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { API_URL } from '../utils';


const NFTs = () => {

    const [nfts, setNfts] = useState([]);
    // const [mintNft, setMintNft] = useState({});

    useEffect(() => {
        axios.get(`${API_URL}/nfts/all/read/`)
        .then(res => {
            // console.log(res.data);
            setNfts(res.data);
        })
        .catch((error) => {
          console.log(error.message);
        })
    }, [])


    // const transactionApprove = id => {
    //     console.log('tx id: ', id);
        
    //     const config = {
    //         headers: {
    //           'Content-Type': 'application/json'
    //         }
    //     }

    //     const body = JSON.stringify({ id:id });
    //     console.log(body);

    //     axios.patch(`http://127.0.0.1:8000/nfts/transactions/${id}/approve/`, body, config)
    //     .then(res => {
    //         console.log('response', res);
    //         swal({
    //             title: "Approve",
    //             text: "Successfully Approved Transaction!",
    //             icon: "info",
    //             button: false,
    //             timer: 700,
    //           });
    //     })
    //     .catch((error) => {
    //         console.log(error.message);
    //     }) 
    // }


    // const openMintNft = (nft) => {
        // setMintTransactionModal(!mintTransactionModal);
        // setMintNft(nft);
    // }


    return (
        <div className='container-fluid page'>
            {
                nfts && nfts.map(nft => {
                    return <div className='nft mr-3 mb-4' key={nft.id}>
                        <h2 className='text-center' style={{
                            color: "#2893A8",
                            fontWeight: 400
                        }}>{nft.title}</h2>
                        
                        <div className='mx-4 text-center'>
                            <img src={nft.file} alt="" height={600} width="90%" />
                        </div>

                        {/* <div className='text-center mt-1'>
                            <Button color="primary" outline>
                                Mint NFT
                            </Button>
                        </div> */}

                        {/* { 
                            // nft.transactions.length  === 0 && <p className='text-center text-danger'>
                            //     <small>No Transaction Yet!</small>
                            // </p>
                        {/* }  

                        
                            // nft.transactions.length >= 1 && 

                            // <Table hover>
                            //     <thead>
                            //         <tr>
                            //             <th>Asset Name</th>
                            //             <th>Asset URL</th>
                            //             <th>Unit Name</th>
                            //             <th>Amount</th>
                            //             <th>Status</th>
                            //             <th>Action</th>
                            //         </tr>    
                            //     </thead>
                            //     <tbody>
                            //         {
                            //             nft.transactions && nft.transactions.map (nt => {
                            //                 return <tr key={nt.id}>
                            //                     <th scope="row">{nt.asset_name}</th>
                            //                     <td>{nt.asset_url}</td>
                            //                     <td>{nt.unit_name}</td>
                            //                     <td>{nt.total}</td>
                            //                     <td>{nt.is_paid.toString()}</td>
                            //                     <td>
                            //                     {
                            //                         nt.is_active === false && 
                            //                         <Button color="info" size="sm" outline onClick={() => transactionApprove(nt.id)}>
                            //                             <FontAwesomeIcon icon={faCheck} />
                            //                         </Button>
                            //                     }
                            //                     {
                            //                         nt.is_active === true && 
                            //                         <small>Approved</small>
                            //                     }
                            //                     </td>
                            //                 </tr>
                            //             })
                            //         }
                            //     </tbody>
                            // </Table>
                        // */}
                    </div>
                })
            }
            
        </div>
    )
}

export default NFTs
