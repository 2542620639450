import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./store/index";

import CreateNFT from './pages/CreateNFT'
import Home from './pages/Home'
import NFTs from './pages/NFTs'
import Profile from './pages/Profile'
import Account from './pages/Account'
import Dashboard from './pages/Dashboard'
import CarbonOffsetNfts from './pages/CarbonOffsetNFTs'
import OffsetYourEmissions from './pages/OffsetYourEmissions'
import RegisteredOrganisations from './pages/RegisteredOrganisations'
import PrimaryMarket from './pages/PrimaryMarket';
import SecondaryMarket from './pages/SecondaryMarket';
import MyNFTs from './pages/MyNFTs'
import Footer from './components/Footer/Footer'
import Navbar from './components/Navbar/Navbar';
import RequestToRegister from './components/Form/RequestToRegister';
import RegistrationForm from './components/Form/RegistrationForm';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from './components/Login/Login';
import ProjectDetail from './components/ProjectDetail/ProjectDetail';
import Payment from './components/Payment/Payment';
import NotFound from './components/NotFound/NotFound';
import OrganisationDetail from './components/OrganisationDetail/OrganisationDetail';

import './App.css';
import ResetPassword from './components/ResetPassword/ResetPassword';
import UpdatePassword from './components/UpdatePassword/UpdatePassword';
import NFTDetail from './components/NFTDetail/NFTDetail';
import SupportedProjects from './components/SupportedProjects/SupportedProjects';
import Refresh from './components/Payment/Refresh';
import Return from './components/Payment/Return';

import Privacy from './pages/Privacy';
import View from './pages/View';
import MarketHistory from './components/MarketHistory/MarketHistory';
import ChangePassword from './components/ResetPassword/ChangePassword';
import ProtectRoute from './ProtectRoute';
import PlansPage from './pages/PlansPage';
import InitialPlansPage from './pages/InitialPlansPage';
import SubscriptionSuccess from './pages/SubscriptionSuccess';
import SubscriptionCancel from './pages/SubscriptionCancel';
import InvitationPage from './pages/InvitationPage';
import AdminPage from './pages/AdminPage';
import RegisterInvitedUserPage from './pages/RegisterInvitedUserPage';
import InvitedUserLogin from './components/Invitation/InvitedUserLogin';
import InvitedUserLoginPage from './pages/InvitedUserLoginPage';
import MemberProfile from './pages/MemberProfile';

const theme = createTheme({
    palette: {
        primary: {
            main: '#B6CDBC'
        },
        secondary: {
            main: '#797979'
        },
        info: {
            main: '#531B93'
        },
        success: {
            main: '#43A901'
        },
        warning: {
            main: '#C3954A'
        },
        dark: {
            main: '#7B3F00',
        }
    },
    navLink: {
        textDecoration: 'none',
    }
});


function App() {
    
    return (
        <Provider store={store}>
            <BrowserRouter basename='/'>
                <ThemeProvider theme={theme}>
                    <Navbar />
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/home' element={<Home />} />
                        <Route path='/nft' element={<NFTs />} />
                        <Route path='/create-nft' element={<CreateNFT />} />

                        <Route path='/register' element={<Home />} />
                        <Route path='/register/request' element={<RequestToRegister />} />
                        <Route path='/register/form' element={<RegistrationForm />} />
                        <Route path='/reset-password' element={<ResetPassword />} />

                        <Route path='/update-password' element={<UpdatePassword />} />

                        <Route path='/projects/:id' element={<ProjectDetail />} />
                        <Route path='/supported-projects' element={<SupportedProjects />} />
                        <Route path='/registered-organisations' element={<RegisteredOrganisations />} />
                        <Route path='/organisations/:id' element={<OrganisationDetail />} />
    
                        <Route path='/primary-market' element={<PrimaryMarket />} />
                        
                        <Route path='/secondary-market' element={<SecondaryMarket />} />
                        <Route path='/market-history/:id' element={<MarketHistory />} />

                        <Route path='/plans' element={<PlansPage/>} />
                        <Route path='/initial-plans' element={<InitialPlansPage/>} />
                        <Route path='/subscription-success' element={<SubscriptionSuccess/>} />
                        <Route path='/subscription-cancel' element={<SubscriptionCancel/>} />
                        <Route path='/accept-invitation/:token/:org_name/' element={<InvitationPage/>} />
                        <Route path='/register-invited/:org_name/' element={<RegisterInvitedUserPage/>} />
                        <Route path='/invited-login/' element={<InvitedUserLoginPage/>} />

                        <Route path='/user-member/:userId/' element={<MemberProfile/>} />

                        {/* <Route path='/nfts/:id' element={<NFTDetail />} /> */}
                        <Route path='/payment' element={<Payment />} />

                        <Route path='/stripe-refresh' element={<Refresh />} />
                        <Route path='/stripe-return' element={<Return />} />

                        <Route path='/profile' element={<Profile />} />
                        <Route path='/login' element={<Login />} />
                        {/* <Route path='/account' element={<Account />} /> */}


                        <Route path='/' element={<ProtectRoute />}>
                            <Route path='dashboard' element={<Dashboard />} />
                            <Route path='admin-panel' element={<AdminPage />} />
                            <Route path='/change-password' element={<ChangePassword />} />
                            <Route path='/create-nfts' element={<CreateNFT />} />
                            <Route path='/my-nfts' element={<MyNFTs />} />
                        </Route>

                        <Route path='/privacy-statement' element={<Privacy />} />

                        <Route path='*' element={<NotFound />} />

                    </Routes>
                    <Footer />
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
