import React from 'react'

const NotFound = () => {
    return (
        <div className="bg-light p-5 rounded-lg m-2 page">
            <div className='container-fluid page'>
                <h1 className="text-center">
                    {/* <strong>Error 404!</strong> */}
                    This page does not exist in CNet0!
                </h1>
            </div>
        </div>
    )
}

export default NotFound
