import * as backend from "../contracts/build/index.main.mjs";
import * as backend2 from "../contracts/build/index.marketplace.mjs";
import * as backend_v2 from "../contracts/v2/build/index.main.mjs";
import * as backend2_v2 from "../contracts/v2/build/index.marketplace.mjs";
// import * as proxyBackend from "../build/index.main.mjs";
import { loadStdlib } from "@reach-sh/stdlib";
const reach = loadStdlib("ALGO");
// This was deployed earlier to testnet and acts as a proxy to catcn all the events that happen in the contract
const ctc_info = { _hex: "0x0996d205", _isBigNumber: true };
export const BNtoN = reach.bigNumberToNumber;
export const FmtC = reach.formatCurrency;
export const FmtA = reach.formatAddress;
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const contract_Abstraction = async (wallet, token) => {
  const { name, symbol, decimals, supply, tokenId, price } = token;
  console.log("cA:", wallet, token);
  console.log("Starting abstraction...");
  return await reach.withDisconnect(async () => {
    const ctcAlice = wallet.contract(backend_v2);
    return await ctcAlice.p.Deployer({
      launched: (info) => {
        console.log(`Successfully deployed contract with id ${info}`);
        reach.disconnect({ info, tok: token }); // causes withDisconnect to immediately return null
      },
      token: tokenId,
      meta: {
        name,
        symbol,
        decimals,
        totalSupply: supply,
        price: BNtoN(price),
        watcherContract: reach.bigNumberToNumber(ctc_info),
      },
      // implement Alice's interact object here
    });
  });
};
export const secondary_contract_Abstraction = async (
  wallet,
  token = {
    name: "",
    symbol: "",
    decimals: "",
    supply: 0,
    tokenId: "",
    price: 0,
    Parent: "",
  }
) => {
  const { name, symbol, decimals, supply, tokenId, price, Parent } = token;
  console.log("Starting abstraction...");
  console.log("scA:", wallet, token);

  return await reach.withDisconnect(async () => {
    const ctcAlice = wallet.contract(backend2_v2);
    return await ctcAlice.p.Deployer({
      launched: (info) => {
        console.log(`Successfully deployed contract with id ${info}`);
        reach.disconnect({ info, tok: token }); // causes withDisconnect to immediately return null
      },
      // token: tokenId,
      meta: {
        name,
        symbol,
        decimals,
        totalSupply: supply,
        price: BNtoN(price),
        token: tokenId,
        Parent: BNtoN(Parent),
        watcherContract: reach.bigNumberToNumber(ctc_info),
      },
      // implement Alice's interact object here
    });
  });
};
// export const deployWatcher = async (wallet) => {
//     const ctc = wallet.contract(proxyBackend);
//     const info = await reach.withDisconnect(async () => await proxyBackend.Alice(ctc, {
//         ...reach.hasRandom,
//         contractInfo: (info) => {
//             console.log(info);
//             reach.disconnect(info); // causes withDisconnect to immediately return null
//         },
//         // implement Alice's interact object here
//     }));
//     console.log({ info });
//     return info;
// };
// export const killWatcher = async (wallet) => {
//     const ctc = wallet.contract(proxyBackend, BNtoN(ctc_info));
//     await ctc.a.end();
// };
// export const watcher = (wallet) => {
//     const contract = wallet.contract(proxyBackend, BNtoN(ctc_info));
//     const created = async () => {
//         const E = contract.e.E.created;
//         console.log("Nexting");
//         // const d = await E.next();
//         console.log("Done Nexting");
//         // console.log(d);
//         return E.monitor(({ when, what }) => {
//             console.log({ what, when });
//         });
//     };
//     const listed = async () => {
//         const E = contract.e.E.listed;
//         console.log("Nexting");
//         const d = await E.next();
//         // const e = E.seekNow();
//         console.log("Done Nexting");
//         console.log(d);
//         return E.monitor(({ when, what }) => {
//             console.log({ what, when });
//             throw { what, when };
//         });
//     };
//     return { created, listed };
// };

// export const useWatcher = ({ wallet, setState, }) => {
//     const created = async () => {
//         const ctcAlice = wallet.contract(proxyBackend, BNtoN(ctc_info));
//         const E = ctcAlice.e.E.created;
//         console.log("Nexting");
//         // const d = await E.next();
//         console.log("Done Nexting");
//         // console.log(d);
//         return E.monitor(({ when, what }) => {
//             setState((prev) => {
//                 if (!!prev.find((item) => {
//                     const f = item.when;
//                     const g = when;
//                     const isEqual = BNtoN(f) == BNtoN(g);
//                     return isEqual;
//                 })) {
//                     return prev;
//                 }
//                 return [...prev, { when, what }];
//             });
//             console.log({ what, when });
//         });
//     };
//     const listed = async () => {
//         const ctcAlice = wallet.contract(proxyBackend, BNtoN(ctc_info));
//         const E = ctcAlice.e.E.listed;
//         console.log("Nexting");
//         // const e = E.seekNow();
//         console.log("Done Nexting");
//         return E.monitor(({ when, what }) => {
//             setState((prev) => {
//                 if (!!prev.find((item) => {
//                     const f = item.when;
//                     const g = when;
//                     const isEqual = BNtoN(f) == BNtoN(g);
//                     return isEqual;
//                 })) {
//                     return prev;
//                 }
//                 return [...prev, { when, what }];
//             });
//             console.log({ what, when });
//         });
//     };
//     return { created, listed };
// // };
