import React, { useState } from 'react';
import { useEffect } from 'react';
import { Box, Button, Grid, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InviteUserPopUp from './InviteUserPopUp';
import { API_URL, getAxiosConfig } from '../../utils';
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate , useParams} from 'react-router-dom';
const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [invitedUser, setInvitedUser] = useState('');
  const [orgName, setOrgName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();
  
  const tenant_id = localStorage.getItem("org_name");
  const getOrgUsers = () =>{
    axios.get(`${API_URL}/tenants/org-members/${localStorage.getItem("org_id")}/users/${tenant_id}/`, {...getAxiosConfig({credentials: true})})
            .then(response => {
                setUsers(response.data);
               
            })
            .catch(error => {
                setErrorMsg(error);
            });
  }
  useEffect(() => {
    setOrgName(localStorage.getItem("org_name"));
    getOrgUsers();

  }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (e) => {
    setInvitedUser(invitedUser => e.target.value);
  }

  const handleClose = () => {
    setOpen(false);
    setInvitedUser('');
  };

  const handleInvite = () => {
    if (invitedUser) {
      //setUsers([...users, invitedUser]);
      const object = {
        org_name: orgName,
        org_id: localStorage.getItem('org_id'),
        email: invitedUser,
        sender: localStorage.getItem('email'),
    }

    const body = JSON.stringify(object);
    
      axios.post(`${API_URL}/invitations/invite/${orgName}/`, body, {...getAxiosConfig({jsonHeader: true, credentials: true })})
      .then(res => {
          if (res.status === 201) {
              swal({
                  title: "Invitation",
                  text: "Invitation sent!",
                  icon: "success",
                  button: false,
                  timer: 1200,
              });
             
          } 

          else {
              setErrorMsg(res.data.message)
          }
          setShowSpinner(false)
      })
      .catch((error) => {
          setShowSpinner(false)
          console.log(error.message);
      })
      
    }
    handleClose();
  };
  

  const checkUserDetails = (user) => {
    navigate(`/user-member/${user.id}`, { state: { user } });
  }
  console.log();
  return (
    <Box sx={{ width: '100%', maxWidth: '100%', margin: '0 auto', padding: '16px' }}>
             <br></br>
             <br></br>
        <Grid container justifyContent="right">
        <Button 
        variant="contained" 
        className='info-btn'
        startIcon={<PersonAddIcon />} 
        onClick={handleClickOpen}
        fullWidth
        
      >
        Invite User
      </Button>
        </Grid>
        <Grid container justifyContent="left">
        <h1>{orgName}</h1>
        </Grid>
      <br></br>
      <Box 
        sx={{ 
          maxHeight: '400px', 
          overflowY: 'auto', 
          border: '1px solid #ccc', 
          borderRadius: '8px',
          padding: '8px'
        }}
      >
        <List>
            {users.length === 0 ? (
                <Typography variant="h6">
                    Start inviting members for {localStorage.getItem('org_name')}
                </Typography>
            ) : (
                users.map((user) => (
                    <ListItem key={user.id} onClick={() => checkUserDetails(user)} divider>
                        <ListItemText primary={user.email} />
                    </ListItem>
                ))
            )}
        </List>
      </Box>
      <InviteUserPopUp handleClose={handleClose} handleInvite={handleInvite} setInvitedUser={handleChange} open={open} newUser={invitedUser}/>
    </Box>
  );
};

export default AdminPanel;