export const menu = [
    { 
        text: 'Home',
        link: 'home',
    }, 
    {
        text: 'Register',
        link: 'register',
    },
    { 
        text: 'Offset Your Emissions',
        link: 'offset-your-emissions',
    }, 
    { 
        text:'Registered Organisations',
        link: 'registered-organisations', 
    },
    {
        text: 'Analytics',
        link: 'analytics',
    }, 
    {
        text: 'Carbon Offset NFTs',
        link: 'carbon-offset-nfts',
    },

];

export const settings = [
    {
        text: 'Login',
        link: 'login',
    },
    {
        text: 'Profile',
        link: 'profile',
    },
    {
        text: 'Account',
        link: 'account',
    },
    {
        text: 'Dashboard',
        link: 'dashboard',
    },
    {
        text: 'Logout',
        link: '',
    }
]

export const currencies = [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'BTC',
      label: '฿',
    },
    {
      value: 'JPY',
      label: '¥',
    },
]

export const categories = [
    {
      value: 'RFor',
      label: 'Reforestation (RFor)',
    },
    {
      value: 'AFor',
      label: 'Afforestation (AFor)',
    },
    {
      value: 'WFar',
      label: 'Wind farm (WFar)',
    },
    {
      value: 'SFar',
      label: 'Solar farm (SFar)',
    },
    {
        value: 'SCSe',
        label: 'Soil carbon sequestration (SCSe)',
    },
    {
        value: 'BCha',
        label: 'Bio-char (BCha)',
    },
    {
        value: 'LGCa',
        label: 'Landfill gas capture (LGCa)',
    },
]

export const EUCountryList = [
    {country: 'Albania', code: 'AL', currency_code: 'ALL', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…zLS44MDctMS44OC0xLjI1eiIvPg0KPC9nPg0KPC9zdmc+DQo='},
    {country: 'Andorra',  code: 'AD', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…Ljc5MDEgMy4yMDM3LDIuMTI3NiA0LjM0NTYsMy44MTg4IDIuM'},
    {country: 'Austria', code: 'AT', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…gZD0ibTAsMjAwaDkwMHYyMDBoLTkwMHoiLz4NCjwvc3ZnPg0K'},
    {country: 'Belarus', code: 'BY', currency_code: 'BYR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAwIiB4…9Im1hdHJpeCgxIDAgMCAtMSAwIDU0OSkiLz4NCjwvc3ZnPg0K'},
    {country: 'Belgium', code: 'BE', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…9Im0zMDAsMGgxNTB2MzkwaC0xNTB6Ii8+DQo8L3N2Zz4NCg=='},
    {country: 'Bosnia and Herzegovina', code: 'BA', currency_code: 'BAM', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwMCIg…zbGF0ZSgzNy41IDM2LjM3NSkiLz4NCjwvZz4NCjwvc3ZnPg0K'},
    {country: 'Bulgaria', code: 'BG', currency_code: 'BGN', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwMCIg…2MjYxMiIgZD0ibTAsMmg1djFoLTV6Ii8+DQo8L3N2Zz4NCg=='},
    {country: 'Croatia', code: 'HR', currency_code: 'HRK', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…3NDgzLDMuOTYgMS41MTA1LDUuNzg0NnoiLz4NCjwvc3ZnPg0K'},
    {country: 'Czech Republic', code: 'CZ', currency_code: 'CZK', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAwIiB4…jMTE0NTdlIiBkPSJtMywybC0zLTJ2NHoiLz4NCjwvc3ZnPg0K'},
    {country: 'Denmark', code: 'DK', currency_code: 'DKK', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…wYXRoIGQ9Im0wLDE0MGgzNzAiLz4NCjwvZz4NCjwvc3ZnPg0K'},
    {country: 'Estonia', code: 'EE', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…9Im0wLDQyMGg5OTB2MjEwaC05OTB6Ii8+DQo8L3N2Zz4NCg=='},
    {country: 'Finland', code: 'FI', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…zZm9ybT0icm90YXRlKDkwKSIvPg0KPC9nPg0KPC9zdmc+DQo='},
    {country: 'France', code: 'FR', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…1IiBkPSJtMCwwaDMwMHY2MDBoLTMwMHoiLz4NCjwvc3ZnPg0K'},
    {country: 'Germany', code: 'DE', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwMCIg…mY2UwMCIgZD0ibTAsMmg1djFoLTV6Ii8+DQo8L3N2Zz4NCg=='},
    {country: 'Greece', code: 'GR', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODEwIiB4…tMCw0IDI3LDAgMCwyLTI3LDAgMC0yeiIvPg0KPC9zdmc+DQo='},
    {country: 'Iceland', code: 'IS', currency_code: 'ISK', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…4LDBoMnYxOGgtMnoiLz4NCjwvZz4NCjwvZz4NCjwvc3ZnPg0K'},
    {country: 'Ireland', code: 'IE', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…kPSJtODAwLDBoNDAwdjYwMGgtNDAweiIvPg0KPC9zdmc+DQo='},
    {country: 'Italy', code: 'IT', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUwMCIg…jZTJiMzciIGQ9Im0yLDBoMXYyaC0xeiIvPg0KPC9zdmc+DQo='},
    {country: 'Latvia', code: 'LV', currency_code: 'LVL', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…gZD0ibTAsMTIwaDYwMHY2MGgtNjAweiIvPg0KPC9zdmc+DQo='},
    {country: 'Liechtenstein', code: 'LI', currency_code: 'CHF', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…5OSAwLDcuMTIzMiIvPg0KPC9nPg0KPC9nPg0KPC9zdmc+DQo='},
    {country: 'Lithuania', code: 'LT', currency_code: 'LTL', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwMCIg…xMjcyZCIgZD0ibTAsMmg1djFoLTV6Ii8+DQo8L3N2Zz4NCg=='},
    {country: 'Luxembourg', code: 'LU', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…gZD0ibTAsMGg1MDB2MTAwaC01MDB6Ii8+DQo8L3N2Zz4NCg=='},
    {country: 'Malta', code: 'MT', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4w…0LWZhbWlseTpUaW1lcyBOZXcgUm9tYW4iLz4NCjwvc3ZnPg=='},
    {country: 'Moldova', code: 'MD', currency_code: 'MDL', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…uMTM5LTIuNTYtOC4xMzloLS4wMDF6Ii8+DQo8L3N2Zz4NCg=='},
    {country: 'Netherlands', code: 'NL', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAwIiB4…jYWUxYzI4IiBkPSJtMCwwaDl2MmgtOXoiLz4NCjwvc3ZnPg0K'},
    {country: 'New Zealand', code: 'NZ', currency_code: 'NZD', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEi…0ODApIHNjYWxlKDM1KSIvPg0KPC9nPg0KPC9nPg0KPC9zdmc+'},
    {country: 'Norway', code: 'NO', currency_code: 'NOK', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…waDExMDB2MTAwaC0xMTAweiIvPg0KPC9nPg0KPC9zdmc+DQo='},
    {country: 'Poland', code: 'PL', currency_code: 'PLN', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…ibTAsNDAwaDEyODB2NDAwaC0xMjgweiIvPg0KPC9zdmc+DQo='},
    {country: 'Portugal', code: 'PT', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…xMjguNTcxNiAxNDIuNzUyKSIvPg0KPC9nPg0KPC9zdmc+DQo='},
    {country: 'Romania', code: 'RO', currency_code: 'RON', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…9Im0zMDAsMGgxNTB2MzAwaC0xNTB6Ii8+DQo8L3N2Zz4NCg=='},
    {country: 'San Marino', code: 'SM', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…MS44NjQgLjE1OTIsLjU1NyIvPg0KPHBhdGggY2xhc3M9ImZpb'},
    {country: 'Slovakia', code: 'SK', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAwIiB4…uODc1LTMzLjc1aC04NS43NXoiLz4NCjwvZz4NCjwvc3ZnPg0K'},
    {country: 'Slovenia', code: 'SI', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwMCIg…4LjQyLTMuODIiLz4NCjwvc3ZnPg0KPC9nPg0KPC9zdmc+DQo='},
    {country: 'Spain', code: 'ES', currency_code: 'EUR', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…gZD0ibTAsMTI1aDc1MHYyNTBoLTc1MHoiLz4NCjwvc3ZnPg0K'},
    {country: 'Sweden', code: 'SE', currency_code: 'SEK', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…waDE2MDB2MjAwaC0xNjAweiIvPg0KPC9nPg0KPC9zdmc+DQo='},
    {country: 'Switzerland', code: 'CH', currency_code: 'CHF', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…zMCw2MGg2MHYyMDBoLTYweiIvPg0KPC9nPg0KPC9zdmc+DQo='},
    {country: 'Ukraine', code: 'UA', currency_code: 'UAH', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDov…ibTAsNDAwaDEyMDB2NDAwaC0xMjAweiIvPg0KPC9zdmc+DQo='},
    {country: 'United Kingdom', code: 'GB', currency_code: 'GBP', flag_base64: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwMCIg…2IiBkPSJtMzAsMHYzMG0tMzAtMTVoNjAiLz4NCjwvc3ZnPg0K'},
]

export const allCountryList = [ 
    'Afghanistan','Algeria','Angola','Antigua and Barbuda', 'Armenia', 'Austria', 'Bahamas', 'Bangladesh',
    'Belarus', 'Belize', 'Bermuda', 'Bolivia', 'Botswana', 'Burkina Faso', 'Burundi','Cameroon', 'Cape Verde', 'Chad',
    'China', 'Comoros', 'Cuba', 'Czech Republic', 'Djibouti', 'Dominican Republic', 'Ecuador', 'El Salvador', 'Eritrea',
    'Ethiopia', 'Finland', 'Gabon', 'Georgia', 'Ghana', 'Greenland', 'Guatemala','Guinea-Bissau','Haiti','Hong Kong','India',
    'Ireland','Italy','Japan','Kazakhstan','Kiribati','Kyrgyzstan','Latvia','Lesotho','Lithuania', 'Luxembourg', 'Malawi','Maldives','Malta',
    'Mauritania','Mexico','Morocco','Mozambique','Nauru','Netherlands','Nicaragua','Nigeria','Oman','Panama','Paraguay',
    'Philippines','Portugal','Romania','San Marino','Saudi Arabia','Sierra Leone','Slovakia','Solomon Islands','South Africa',
    'Suriname','Sweden','Tajikistan', 'Thailand','Tonga','Tunisia','Turkmenistan','Ukraine','United Kingdom',
    'United States','Uzbekistan','Venezuela','Yemen','Zimbabwe'
]

export const varificationStandards = [
    "Climate Action Reserve (CAR)",
    "Verified Carbon Standard (VCS)",
    "American Carbon Registry (ACR)",
    "Gold Standard (GS)",
    "Other",
    "None"
]

export const projectCategory = { 
    'RFor': 'Reforestation (RFor)',
    'AFor': 'Afforestation (AFor)',
    'WFar': 'Wind farm (WFar)',
    'SFar': 'Solar farm (SFar)',
    'SCSe': 'Soil carbon sequestration (SCSe)',
    'BCha': 'Bio-char (BCha)',
    'LGCa': 'Landfill gas capture (LGCa)',
    'HPow': 'Hydroelectric power (HPow)',
    'CCoo': 'Clean Cooking (CCoo)',
}