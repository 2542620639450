import { CircularProgress } from '@mui/material';
import React from 'react'
const Loading = () => {
    return (
        <div
            style={{
                minHeight: "80dvh"
            }}
            className="d-flex justify-content-center align-items-center"
        >
            <CircularProgress style={{ color: "#B6CDBC" }} />
        </div>
    );
}

export default Loading