const REACT_APP_NETWORK: "testnet" | "mainnet" = "testnet";

const mainnetConfig = {
  network: "MainNet",
  explorer: {
    base: "https://explorer.perawallet.app/",
    asset: "https://explorer.perawallet.app/asset/",
    address: "https://explorer.perawallet.app/address/",
  },
};
const testnetConfig = {
  network: "TestNet",
  explorer: {
    base: "https://testnet.explorer.perawallet.app",
    asset: "https://testnet.explorer.perawallet.app/asset/",
    address: "https://testnet.explorer.perawallet.app/address/",
  },
};

export const networkConfig =
  REACT_APP_NETWORK == "testnet" ? testnetConfig : mainnetConfig;


