import React from 'react';
import { useEffect } from 'react';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { API_URL, getAxiosConfig} from '../utils'
import axios from 'axios';

const SubscriptionSuccess = () =>{
    const navigate = useNavigate();
    
    const getPaymentStatus = async(subscriptionId) =>{
        const response = await axios.get(`${API_URL}/subscriptions/paypal-return/?subscription_id=${subscriptionId}`, {withCredentials: false});
        console.log("payment status")
        
        console.log(response.status )
        console.log(" respnse status is ===> ", response.status)
        if(response.status == 200){
            console.log("payment success");
            swal({
                title: "Success",
                text: "Subscription payment Success!",
                icon: "success",
                button: false,
                timer: 1500,
            })
                 .then(() => {
                     navigate('/login');
                 });
           
        }else{
            console.log("Error while doing payment ... try again later")
        }

    }
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const subscriptionId = query.get('subscription_id');
       setTimeout(() => {
        window.location.href = "/"; 
       }, 2000)
       if (subscriptionId) {
         getPaymentStatus (subscriptionId);
        
    }
       
    }, [navigate])
    
    return(
        <div>
        </div>
    )
}

export default SubscriptionSuccess;